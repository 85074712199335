import React, { useState, useEffect, useCallback } from "react";
import Switch from "react-switch";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import { Row, Col, Popover, PopoverBody, Button, Modal, ModalBody } from "reactstrap";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from 'react-toastify';
import moment from "moment";
import { useHistory } from "react-router-dom";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import BeatLoader from "react-spinners/BeatLoader";
import RenderSubscription from './renderSubscription'
import RenderSubTitle from './renderSubTitle'
import DragVerticalIcon from "mdi-react/DragVerticalIcon";
import {
    arrayMove,
  } from '@dnd-kit/sortable';


import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

const Subscription = ({ }) => {

    let [loading, setloading] = useState(true);
    let [loadingItem, setloadingItem] = useState(null);
    let [loadingpopular, setloadingpopular] = useState(null);
    let [loadingItemp, setloadingItemp] = useState(null);
    let [features, setfeatures] = useState([]);
    let [result, setResult] = useState([]);
    let [forceReload, setForceReload] = useState(1);
    let [periods, setperiods] = useState([]);
    let [staticFeatures, setStaticFeatures] = useState([]);
    const [showActive, setShowActive] = useState(true)
    const history = useHistory();
    const [activePlans, setActivePlans] = useState(false)
    const [mergeModal, setmergeModal] = useState(false)
    const [loadingupdate, setloadingupdate] = useState(false)

    const [items, setItems] = useState([]);

    const [featureTitles, setFeatureTitles] = useState({})

    const fetchFeaturesTitles = () => {

        client.get(api.fetch_features_titles, {}, async function (error, response) {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            setFeatureTitles(response.result)
                        } 
                    } catch (e) {
                    }
                } 
            } 
        })
    }
    useEffect(() => {
        fetchFeaturesTitles()
    }, [])

    const update_index_api = (update_array) => {

        // console.log(update_array, "update_array")

        client.post(api.update_feature_sort_index, { update_array: update_array }, async function (error, response) {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            fetchfeatures()
                        }
                    } catch (e) {
                    }
                } else {
                }
            } else {
            }
        });
        
    }

    useEffect(() => {
        setItems([
            { title: "Title of subscription", static: true, feature_id: "title" },
            { title: "Sub Title", static: true, feature_id: "sub_title" },
            { title: "Subscription Fee", static: true, feature_id: "fees" },
            { title: "Promotional Details", static: true, feature_id: "promo_details" },
            ...staticFeatures,
            { title: "Sort By", static: true, feature_id: "sort_by" },
    
        ])
    }, [staticFeatures])

    const togglemergeModal = () => {
        setmergeModal(!mergeModal)
    }
    const [featureDescArr, setFeatureDescArr] = useState([
        {
            cloud_based_desc: "",
            loyalty_card_desc: "",
            offers_vouchers_desc: "",
            online_order_desc: "",
            payment_link_desc: "",
            register_desc: "",
            table_reservation_desc: "",
            users_roles_desc: "",
            visitor_registry_desc: "",
            linky_scan_desc: "",
            price_list_desc: "",
        }
    ])
    // console.log(result, "result")
    const [popoverState, setPopoverState] = useState({
        // cloud_based: false,
        // loyalty_card: false,
        // offers_vouchers: false,
        // online_order: false,
        // payment_link: false,
        // register: false,
        // table_reservation: false,
        // users_roles: false,
        // visitor_registry: false,
        // linky_scan: false,
        // price_list: false,

        sales_per_day: false,
        number_of_registers: false,
        number_of_users: false,
        guest_visitor_registry: false,
        linky_scan_id: false,
        ecommerce_website: false,
        bookings_reservations: false,
        offers_vouchers: false,
        loyalty_cards: false,
        payment_links: false,
        price_list: false,
        remove_logo: false
    })

    useEffect(() => {
        let temp = [];

        if (featureTitles.length > 0) {

            temp = featureTitles.map(a => {

                let sub_titles = features.filter(item => item.title_id==a.title_id)
                sub_titles.sort((a, b) => a.sort_index - b.sort_index)

                return {
                    custom_title : a.custom_title,
                    title_id: a.title_id,
                    sub_titles : sub_titles
                }
            })

            setStaticFeatures(temp)
        }
        
    }, [featureTitles, features])

    useEffect(() => {
        fetchfeatures();
    }, [])
    useEffect(() => {

        fetch_sub()
        fetchperiod();
        fetchfeatures();

    }, [forceReload])

    const fetch_sub = () => {
        client.get(api.fetch_subscription, {}, async function (error, response) {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            setResult(response.result)
                        } else {
                        }
                    } catch (e) {
                    }
                } else {
                }
            } else {
            }
            setloadingpopular(null)

            setloading(false);
        });
    }

    const fetchperiod = () => {

        client.get(api.fetchperiod, {}, async function (error, response) {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            setperiods(response.result)
                        } else {
                        }
                    } catch (e) {
                    }
                } else {
                }
            } else {
            }

            // setloading(false);
        });

    }

    const toggleStatusPeriod = (item, index) => {

        setloadingItemp(item._id);

        client.post(api.toggle_status_period, { period_id: item._id }, async function (error, response) {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            let s = periods;
                            s[index].status = s[index].status == 1 ? 2 : 1;
                            // console.log(s)
                            setperiods(s)
                            // alert("here")
                        } else {
                        }
                    } catch (e) {
                        // alert(e)
                    }
                } else {
                }
            } else {
            }
            setloadingItemp(null)
        });
    }

    const saveFeaturesDesc = (obj, desc) => {

        let postData = { ...featureDescArr[0] }
        client.post(api.save_featureDesc, postData, async function (error, response) {
            if (!error) {
                if (!response.error) {
                    // console.log(response, "Res")
                    toast.success(response.message)
                    setPopoverState({
                        // cloud_based: false,
                        // loyalty_card: false,
                        // offers_vouchers: false,
                        // online_order: false,
                        // payment_link: false,
                        // register: false,
                        // table_reservation: false,
                        // users_roles: false,
                        // visitor_registry: false,
                        // linky_scan: false,
                        // price_list: false,

                    })
                    togglePopOver();
                } else {
                    toast.error("Something went wrong")
                }
            } else {
                toast.error("Something went wrong")
            }
        })
        let desc_data = {
            feature_id: obj?._id,
            title: obj?.title,
            description: desc,
            status: obj?.status
        }
        // console.log(desc_data)
        // updating to features_descriptions
        client.post(api.edit_features, desc_data, async function (error, response) {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            // toast.success("Updated Successfully");
                            // setcashbackdata(response.result.data);
                            fetchfeatures()
                        } else {
                            toast.error(response.message);
                        }
                    } catch (e) {
                        toast.error("Internal error occured. Please contact support");
                    }
                } else {
                    toast.error("Internal error occured. Please contact support");
                }
            } else {
                toast.error("Internal error occured. Please contact support");
            }
        });
    }

    const fetchfeatures = () => {

        client.get(api.fetch_features, { showall: true }, async function (error, response) {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            setfeatures(response.result)
                        } else {
                        }
                    } catch (e) {
                    }
                } else {
                }
            } else {
            }

            // setloading(false);
        });

    }

    const mergeSubscription = () => {
        setloadingupdate(true)

        client.post(api.update_merchants_subscription, {}, async function (error, response) {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            toast.success("Updated Successfully!");
                           
                        } else {
                            toast.error("Internal error occured. Please contact support 1");
                            
                        }
                    } catch (e) {
                        // alert(e)
                        toast.error("Internal error occured. Please contact support 1");
                    }
                } else {
                    toast.error("Internal error occured. Please contact support 1");
                }
            } else {
                toast.error("Internal error occured. Please contact support 1");
            }
            togglemergeModal()
            setloadingupdate(false)
        })

    }

    const toggleStatus = (item, index) => {

        setloadingItem(item._id);

        client.post(api.toggle_status, { subscription_id: item._id }, async function (error, response) {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            let s = result;
                            s[index].status = s[index].status == 1 ? 2 : 1;
                            // console.log(s)
                            setResult(s)
                            // alert("here")
                        } else {
                        }
                    } catch (e) {
                        // alert(e)
                    }
                } else {
                }
            } else {
            }
            setloadingItem(null)
        });
    }



    const updateSortBy = async (item, sortNo) => {
        const sort_id = item._id
        let data = { sort_id: sort_id, sort_number: sortNo }
        // console.log(data, 'post Data');
        await client.post(api.sort_update, data, function (error, response) {
            // console.log(error, response, "er res")
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            toast.success("Updated Successfully!");
                            // setcashbackdata(response.result.data);
                        } else {
                            toast.error(response.message);
                        }
                    } catch (e) {
                        toast.error("Internal error occured. Please contact support 1");
                    }
                } else {
                    toast.error("Internal error occured. Please contact support 2");
                }
            } else {
                toast.error("Internal error occured. Please contact support 3");
            }


        });
    }

    const updatePopular = async (item, e) => {
        const popular_id = item._id
        let data = { popular_id: popular_id, state: e.checked }
        // console.log(data, "Data")
        setloadingpopular(item._id)
        await client.post(api.popular_update, data, function (error, response) {
            // console.log(error, response, "er res")
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            fetch_sub()
                            toast.success("Updated Successfully!");
                            // setcashbackdata(response.result.data);
                        } else {
                            toast.error(response.message);
                        }
                    } catch (e) {
                        toast.error("Internal error occured. Please contact support 1");
                    }
                } else {
                    toast.error("Internal error occured. Please contact support 2");
                }
            } else {
                toast.error("Internal error occured. Please contact support 3");
            }

        });

    }

    const [enablePopOver, setEnablePopOver] = useState(false);
    const [selectedFeature, setSelectedFeature] = useState(null);
    const togglePopOver = (data) => {
        if (data) {
            setSelectedFeature(data);
        } else {
            setSelectedFeature(null);
        }
        setEnablePopOver(!enablePopOver)
    };
    

    if (result.length == 0) {
        return <></>
    }

    return (
        <>
            <div style={{ display: 'flex', margin: '0 10px 10px 0px' }}>

                <style>
                    {`
                    .handle{
                        background-color : unset;
                    }
                    #table-subscription td{
                        padding: 10px !important;
                        font-size: 13px;
                    }
                    #table-subscription td.title{
                        background-color: #f9fafe;
                        border-collapse: collapse;
                        align-items: center;
                        font-weight: 600;
                    }

                    `}
                </style>
                <div style={{ display: 'flex', flex: 1 }}>
                    <p style={{ margin: '3px', padding: '2px' }}>Show :</p>
                    <div
                        style={{
                            display: 'flex',
                            width: '160px',
                            justifyContent: 'space-between',
                            border: '2px solid #327ebc',
                            borderRadius: '5px',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap'
                        }}
                    >
                        <p style={{
                            background: `${showActive ? '#327ebc' : 'white'}`,
                            color: `${showActive ? 'white' : 'black'}`,
                            margin: '0px',
                            padding: '5px'
                        }}
                            onClick={() => { setShowActive(!showActive) }}
                        >Active Plans </p>
                        <p style={{
                            background: `${!showActive ? '#327ebc' : 'white'}`,
                            color: `${!showActive ? 'white' : 'black'}`,
                            margin: '0px',
                            padding: '5px',
                        }}
                            onClick={() => { setShowActive(!showActive) }}
                        >Show All</p>
                    </div>
                </div>

                <Button
                    className="pull-right"
                    color="primary"
                    onClick={(e) => togglemergeModal()}
                >Update Subscription Plans</Button>
                
            </div>

            {
                <div className="table-responsive" >
                    <table id="table-subscription" className="table table-border-horizontal" >
                    <DndProvider backend={HTML5Backend}>

                        <tbody>
                            {items.map((row_item) => {

                                return (
                                    <>
                                        {
                                            (row_item.sub_titles && row_item.sub_titles.length > 0) ?
                                                <>
                                                    <tr>
                                                        <td colSpan={showActive ? result.filter(a => a.status == 1).length + 1 : result.length + 1} className="title" style={{ background: '#f9fafe', minWidth: " 220px" }}>
                                                            {row_item.custom_title}
                                                        </td>
                                                    </tr>
                                                    <RenderSubTitle update_index_api={update_index_api} sub_titles={row_item.sub_titles} result={result} loadingpopular={loadingpopular} showActive={showActive} loadingItem={loadingItem} toggleStatus={toggleStatus} updatePopular={updatePopular} updateSortBy={updateSortBy} />
                                                    
                                                </>
                                                :
                                                <RenderSubscription  feature_id={row_item.feature_id || row_item._id} sort={false} index={0} row_item={row_item} result={result} loadingpopular={loadingpopular} showActive={showActive} loadingItem={loadingItem} toggleStatus={toggleStatus} updatePopular={updatePopular} updateSortBy={updateSortBy} />

                                            // renderRow(row_item)
                                        }
                                        
                                    </>
                                )
                            }
                            )}
                        </tbody>
                        </DndProvider>

                    </table>
                </div>
            }
            

            <Modal isOpen={mergeModal} toggle={togglemergeModal} className="modal-body" centered={true}>
                <ModalBody>
                    {!loadingupdate ?
                        <>
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                alignItems: "center"
                            }}>
                                <img style={{ width: 82 }} src="https://dl.dropboxusercontent.com/scl/fi/l3lio2vr9ryq76kgh8ngc/warning-icon-yellow.png?rlkey=kypnauptl3uw58tr28ossojl2&dl=0" alt="" />
                                <span style={{
                                    fontSize: "24px",
                                    fontWeight: 500
                                }}>Attention!</span>
                            </div>
                            <hr />
                            <h5 style={{
                                textAlign: "center",
                                fontWeight: "unset"
                            }}>Are you sure you want to update the merchant's subscription plan with the current subscription details? < br />The changes can't be reverted.</h5>
                            {/* <div>The changes can't be reverted.</div> */}
                            <Button
                                disabled={loadingupdate}
                                color="default" style={{ marginTop: "20px", marginRight: "20px" }} onClick={togglemergeModal}>
                                Not Sure
                            </Button>
                            <Button
                                color="danger"
                                disabled={loadingupdate}
                                style={{ marginTop: "20px", marginRight: "20px", float: "right" }}
                                name="unflag"
                                onClick={(event) => mergeSubscription()}
                            >
                                Yes, please
                            </Button>
                        </>
                        :
                        <div className="w-100 h-100 text-center">
                            <BeatLoader color="#45C7A2" loading={true} size={15} />
                            <div style={{ textAlign: 'center' }}>Updating subscription, please wait.</div>
                        </div>
                    }
					
                </ModalBody>
            </Modal>
        </>
    )
}

export default Subscription