import React, { Fragment, Component, useState } from "react";
import { Redirect, NavLink, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { Pagination, PaginationItem, PaginationLink, NavLink as NavLinkTab } from "reactstrap";
import CheckIcon from "@material-ui/icons/Check";
import {
	TabContent,
	TabPane,
	Nav,
	NavItem,
	UncontrolledTooltip,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Tooltip
} from "reactstrap";
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import moment from "moment-timezone";
import PaginationComponent from "react-reactstrap-pagination";

import Breadcrumb from "../../components/common/breadcrumb";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import { DisappearedLoading } from "react-loadingg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { MdOutlineContentCopy } from "react-icons/md";

class AllTransactions extends Component {
	state = {
		error: null,
		fk_id: this.props.fk_id ? this.props.fk_id : null,
		// activeTab: this.props.settlementPage ? "5" : "2",
		flagModal: false,
		infoModal: false,
		deleteModal: false,
		deleteSelectedModal: false,
		selectedIDs: [],
		modalId: null,
		flag_note: "",
		pageSize: 25,
		activePage: 1,
		searchValue: null,
		searchedData: null,
		statusModal: false,
		statusMessage: null,
		access_level: localStorage.getItem("accessLevel"),
		filterTransactions: null,
		authorize_settlement_id: "",
		textCopy: false,
		authorize_settlement_modal: false,
		adminDetails: null,
		successStatusModal: false,
		rejectSuccessTransaction: false,
		authorize_settlement_btn: false,
		adminDetails: null,
		adminUser: JSON.parse(localStorage.getItem("user")),
		successClickModal: false,
		selectedTransaction: null,
		refund_amount: null,
		copyTextToolTip: false,
		successClickOption: null,
		transc_rejectReason: null,
		transactions: [],
		loading: true,
		tab_count: { all: 0, success: 0, failed: 0, pending: 0 },
		getDataByID: null,
		transactions_length: 0,
		reason_authorization: "",
		merchant_id: null,
		transaction_no: null
	};


	componentDidMount() {
		// this.getsubtabCount();
		this.getAllTransactions(this.props.activeTab, this.state.activePage, this.props.defaultCountryCode);

		try {
			this.setState({ adminDetails: JSON.parse(localStorage.getItem("user")) });
		} catch { }
	}


	getsubtabCount = (filterByCountry) => {

		let { pageSize, searchValue, fk_id } = this.state;
		let { filter_type } = this.props;

		let postdata = { filter_type, searchValue, fk_id, filterByCountry, status: 0, status: 1, status: 2, };

		client.post(api.sub_tab_counts, postdata, (error, response) => {
			if (!error) {
				// console.log(response, "tab_counts");
				if (typeof response.error !== "undefined") {
					try {
						if (!response.error) {
							// console.log(response.result.data)
							this.setState({ tab_count: response.result.data });
						}
					} catch (e) { }
				}
			}
		});
	};

	getAllTransactions = (tab_no, activePage, currCountry) => {

		let filter_status;

		if (tab_no == "1") {
			filter_status = "success";
		} else if (tab_no == "2") {
			filter_status = "pending";
		} else if (tab_no == "3") {
			filter_status = "failed";
		} else {
			filter_status = "all";
		}
		this.setState({ getDataByID: this.props.queryData ? this.props.queryData.transaction_id : null });

		if (activePage === undefined) {
			this.setState({ activePage: 1 })
		} else if (activePage != this.state.activePage) {
			this.setState({ activePage: activePage })
		}

		// try {
		let { pageSize, searchValue, fk_id } = this.state;
		let { filter_type, defaultCountryCode } = this.props;
		let skip = (parseInt(activePage) - 1) * parseInt(pageSize);
		let filterByCountry = currCountry ? currCountry : defaultCountryCode;

		this.setState({ loading: true, transactions: [] });

		let postdata = null;

		if (this.props.queryData) {
			postdata = { fetchByTransId: this.props.queryData ? this.props.queryData.transaction_id : null };
		} else {
			postdata = {
				filter_type,
				filter_status,
				limit: parseInt(pageSize),
				skip,
				searchValue,
				fk_id,
				filterByCountry,
			};
		}

		if (this.props?.showOnlyUnpaid) postdata.showOnlyUnpaid = true;

		this.getsubtabCount(filterByCountry);

		if (postdata) {
			client.post(api.all_transactions, postdata, (error, response) => {
				if (!error) {
					// console.log(response, "transaction response");
					if (typeof response.error !== "undefined") {
						try {
							if (!response.error) {
								this.setState({ transactions: response.result.data });
								this.setState({ transactions_length: response.result.total_data });
							} else {
								setTimeout(() => {
									toast.error(response.message);
								}, 200);
							}
						} catch (e) {
							console.log(e);
							setTimeout(() => {
								toast.error("Internal error occured. Please contact support");
							}, 200);
						}
					} else {
						setTimeout(() => {
							toast.error("Internal error occured. Please contact support");
						}, 200);
					}
				} else {
					console.log(error);
					setTimeout(() => {
						toast.error("Internal error occured. Please contact support");
					}, 200);
				}

				this.setState({ loading: false });
			});
		}
	};

	componentDidUpdate(prevProps, prevState, snapshot) {

		if (prevProps.queryData && this.props.queryData) {
			if (prevProps.queryData.transaction_id !== this.props.queryData.transaction_id && this.props.queryData.transaction_id !== this.state.getDataByID) {
				this.getAllTransactions(this.props.activeTab, this.state.activePage, this.props.defaultCountryCode);
			}
		}
	}

	setActiveTab = (tab_no) => {
		this.props.setTransactionSubTabNo(tab_no)

		this.setState({ transactions: [], activePage: 1 });
		this.getAllTransactions(tab_no, 1, this.props.defaultCountryCode);
	};

	setPageSize = (event) => {
		this.setState(
			{
				pageSize: event.target.value,
				activePage: 1,
			},
			() => {
				this.getAllTransactions(this.props.activeTab, 1, this.props.defaultCountryCode);
			}
		);
	};

	handlePageChange = (pageNumber) => {
		this.setState({ activePage: pageNumber, transactions: [] });
		this.getAllTransactions(this.props.activeTab, pageNumber, this.props.defaultCountryCode);
	};

	toggleFlagModal = () => {
		this.setState((prevState) => {
			return {
				...prevState,
				flagModal: !prevState.flagModal,
			};
		});
	};

	toggleStatusModal = () => {
		this.setState((prevState) => {
			return {
				...prevState,
				statusModal: !prevState.statusModal,
			};
		});
	};

	toggleDeleteModal = () => {
		this.setState((prevState) => {
			return {
				...prevState,
				deleteModal: !prevState.deleteModal,
			};
		});
	};

	toggleDeleteSelectedModal = () => {
		this.setState((prevState) => {
			return {
				...prevState,
				deleteSelectedModal: !prevState.deleteSelectedModal,
			};
		});
	};

	toggleInfoModal = () => {
		this.setState((prevState) => {
			return {
				...prevState,
				infoModal: !prevState.infoModal,
			};
		});
	};

	getCardExpiry = (number) => {
		// const foundCard = this.props.cards.find(el => {
		//     return el.card_number == number
		// });
		// if (foundCard) {
		//     return `${foundCard.card_expiry_month}/${foundCard.card_expiry_year}`
		// } else {
		//     return
		// }
	};

	getName(item, type = 1) {
		// const foundCustomer = this.props.customers.find(el => {
		//     return el._id == id
		// });
		let { filter_type } = this.props;
		// console.log(type)
		// console.log(filter_type)
		// console.log(item)

		if (type == 1 || item.type == 4 || filter_type == "settlement_merchant" || filter_type == "settlement_customer") {
			if (item.from_wallet_type == 1 && typeof item.from_customers[0] != "undefined") {
				return (
					<NavLink
						to={{
							pathname: `${process.env.PUBLIC_URL}/customer/wallet/${item.from_customers[0]._id}`,
							search: "?activeTab=1",
						}}
						style={{ color: item.from_customers[0].status == 2 && "red" }}
					>
						{item.from_customers[0].name}
					</NavLink>
				);
			} else {
				// const foundMerchant = this.props.merchants.find(el => {
				//     return el._id == id
				// });
				let from_mechant;
				if (item.from_mechant && item.from_mechant.length > 0) {
					from_mechant = item.from_mechant[0]
				} else if (item.from_trash_merchants && item.from_trash_merchants.length > 0) {
					from_mechant = item.from_trash_merchants[0]
				}
				//  = item.from_mechant.length > 0 ? item.from_mechant[0] : item.from_trash_mechant.length > 0 ? item.from_trash_mechant[0] : undefined
				if (typeof from_mechant != "undefined") {
					return (
						<NavLink
							to={{
								pathname: from_mechant.old_id ? `${process.env.PUBLIC_URL}/merchants/trash_merchant_requests` : `${process.env.PUBLIC_URL}/merchants/merchantDetails/${from_mechant._id}`,
								search: "?activeTab=1",
							}}
							style={{ color: from_mechant.status == 5 && "red" }}
						>
							{from_mechant.business_name}
						</NavLink>
					);
				} else {

					console.log(item, "item")
					return "";
				}
			}
		} else {
			const to_mechant = item.to_mechant.length && item.to_mechant[0] ? item.to_mechant[0] : item.to_trash_mechant[0]
			if (item.to_wallet_type == 1 && typeof item.to_customers[0] != "undefined") {
				return (
					<NavLink
						to={{
							pathname: `${process.env.PUBLIC_URL}/customer/wallet/${item.to_customers[0]._id}`,
							search: "?activeTab=1",
						}}
						style={{ color: item.to_customers[0].status == 2 && "red" }}
					>
						{item.to_customers[0].name}
					</NavLink>
				);
			} else {
				// const foundMerchant = this.props.merchants.find(el => {
				//     return el._id == id
				// });
				if (typeof to_mechant != "undefined") {
					return (
						<NavLink
							to={{
								pathname: to_mechant.old_id ? `${process.env.PUBLIC_URL}/merchants/trash_merchant_requests` : `${process.env.PUBLIC_URL}/merchants/merchantDetails/${to_mechant._id}`,
								search: "?activeTab=1",
								state: item.to_trash_mechant.length ? {
									type: 'trashMerchant',
									merchantData: to_mechant
								} : null
							}}
							style={{ color: to_mechant.status == 5 && "red" }}
						>
							{to_mechant.business_name}
						</NavLink>
					);
				} else {
					return "";
				}
			}
		}
	}

	togglechargesInfo = targetName => {
		if (!this.state[targetName]) {
			this.setState({
				...this.state,
				[targetName]: {
					tooltipOpen: true
				}
			});
		} else {
			this.setState({
				...this.state,
				[targetName]: {
					tooltipOpen: !this.state[targetName].tooltipOpen
				}
			});
		}
	}

	isToolTipOpen = targetName => {
		return this.state[targetName] ? this.state[targetName].tooltipOpen : false;
	}

	getCurrencyCode(item) {

		let currency = "";

		let from_currency;
		let to_currency;
		try {
			// if (item.type == 1) {
			// 	if (item.to_wallet_type == 1 && typeof item.to_customers[0] != "undefined") {
			// 		return item.to_customers[0].country.currency_code;
			// 	} else {
			// 		if (typeof item.to_mechant[0] != "undefined") {
			// 			return item.to_mechant[0].country.currency_code;
			// 		} else {
			// 			return "";
			// 		}
			// 	}
			// } else {
			// 	if (item.from_wallet_type == 1 && typeof item.to_customers[0] != "undefined") {
			// 		return item.to_customers[0].country.currency_code;
			// 	} else {
			// 		if (typeof item.to_mechant[0] != "undefined") {
			// 			return item.to_mechant[0].country.currency_code;
			// 		} else {
			// 			return "";
			// 		}
			// 	}
			// }

			if (item.type == 4) {
				// console.log(item, "item")
				if (item.amount_payer) {
					to_currency = item.amount_payer.currency.code
				} else {
					if (item.from_wallet_type == 1 && typeof item.from_customers[0] != "undefined") {
						from_currency = item.from_customers[0].country.currency_code;
					} else {
						if (typeof item.from_mechant[0] != "undefined") {
							from_currency = item.from_mechant[0].country.currency_code;
						} else {
							from_currency = "";
						}
					}
					to_currency = from_currency
					// if (item.to_wallet_type == 1 && typeof item.to_customers[0] != "undefined") {
					// 	to_currency = item.to_customers[0].country.currency_code;
					// } else {
					// 	if (typeof item.to_mechant[0] != "undefined") {
					// 		to_currency = item.to_mechant[0].country.currency_code;
					// 	} else {
					// 		to_currency = "";
					// 	}
					// }
				}
			}
			else if (item.type == 1) {
				if (item.from_wallet_type == 1 && typeof item.from_customers[0] != "undefined") {
					from_currency = item.from_customers[0].country.currency_code;
				} else {
					if (typeof item.from_mechant[0] != "undefined") {
						from_currency = item.from_mechant[0].country.currency_code;
					} else {
						from_currency = "";
					}
				}
				if (item.to_wallet_type == 1 && typeof item.to_customers[0] != "undefined") {
					to_currency = item.to_customers[0].country.currency_code;
				} else {
					if (typeof item.to_mechant[0] != "undefined") {
						to_currency = item.to_mechant[0].country.currency_code;
					} else {
						to_currency = "";
					}
				}
			} else {
				if (item.from_wallet_type == 1 && typeof item.from_customers[0] != "undefined") {
					from_currency = item.from_customers[0].country.currency_code;
				} else {
					if (typeof item.from_mechant[0] != "undefined") {
						from_currency = item.from_mechant[0].country.currency_code;
					} else {
						from_currency = "";
					}
				}
				if (item.to_wallet_type == 1 && typeof item.to_customers[0] != "undefined") {
					to_currency = item.to_customers[0].country.currency_code;
				} else {
					if (typeof item.to_mechant[0] != "undefined") {
						to_currency = item.to_mechant[0].country.currency_code;
					} else {
						to_currency = "";
					}
				}
			}

		} catch {

		}
		// if(item.from_country == this.props.defaultCountryCode)
		// 	return from_currency;
		// else
		return to_currency

	}

	getIpayId(item, type) {
		if (type == 1) {
			if (item.from_wallet_type == 1 && typeof item.from_customers[0] != "undefined") {
				return item.from_customers[0].ipay_id;
			} else if (typeof item.from_mechant[0] != "undefined") {
				return item.from_mechant[0].ipay_id;
			} else if (item.card_number) {
				return "**" + item.card_number.slice(-4);
			}
			else {
				return "";
			}
		} else {
			if (item.to_wallet_type == 1 && typeof item.to_customers[0] != "undefined") {
				return item.to_customers[0].ipay_id;
			} else if (typeof item.to_mechant[0] != "undefined") {
				return item.to_mechant[0].ipay_id;
			} else if (item.card_number) {
				return "**" + item.card_number.slice(-4);
			} else {
				return "";
			}

		}
	}

	getStatus = (status) => {
		if (status == 1) {
			return "Success";
		} else if (status == 0) {
			return "Pending";
		} else {
			return "Failed";
		}
	};

	flagClick = (id) => {
		this.setState((prevState) => {
			return {
				...prevState,
				flagModal: !prevState.flagModal,
				modalId: id,
			};
		});
	};

	statusClick = (message) => {
		const failedMessage = JSON.parse(message);
		this.setState((prevState) => {
			return {
				...prevState,
				statusModal: !prevState.statusModal,
				statusMessage: failedMessage.message,
			};
		});
	};

	deleteClick = (id) => {
		this.setState((prevState) => {
			return {
				...prevState,
				deleteModal: !prevState.deleteModal,
				modalId: id,
			};
		});
	};

	deleteSelectedButtonClick = () => {
		this.setState((prevState) => {
			return {
				...prevState,
				deleteSelectedModal: !prevState.deleteSelectedModal,
			};
		});
	};

	deleteSelectedClick = (event, id) => {
		if (event.target.checked) {
			this.setState((prevState) => {
				return {
					...prevState,
					selectedIDs: [...prevState.selectedIDs, id],
				};
			});
		} else {
			if (this.state.selectedIDs.includes(id)) {
				const idsToKeep = this.state.selectedIDs.filter((el) => {
					return el != id;
				});
				this.setState((prevState) => {
					return {
						...prevState,
						selectedIDs: idsToKeep,
					};
				});
			} else {
				return;
			}
		}
	};

	infoClick = (id) => {
		this.setState((prevState) => {
			return {
				...prevState,
				infoModal: !prevState.infoModal,
				modalId: id,
			};
		});
	};

	onSearchChangeHandler = (event) => {
		this.setState({ searchValue: event.target.value }, () => {
			if (event.target.value == "" || event.target.value == null || event.target.value == undefined) {
				this.setState({ transactions: [], activePage: 1, searchValue: null });
				this.getAllTransactions(this.props.activeTab, 1, this.props.defaultCountryCode);
			}
		})
		// if (event.target.value == "" || event.target.value == null || event.target.value == undefined) {
		// 	this.setState({ transactions: [], activePage: 1, searchValue: null });
		// 	this.getAllTransactions(this.props.activeTab, 1, this.props.defaultCountryCode);
		// }
	}

	// {
	// 	let tab_no = this.state.activeTab;
	// 	let filter_status;
	// 	if (tab_no == "1") {
	// 		filter_status = "success";
	// 	} else if (tab_no == "2") {
	// 		filter_status = "pending";
	// 	} else if (tab_no == "3") {
	// 		filter_status = "failed";
	// 	} else {
	// 		filter_status = "all";
	// 	}


	// 	// try {
	// 	let { pageSize, fk_id } = this.state;
	// 	let { filter_type, defaultCountryCode } = this.props;
	// 	let skip = (parseInt(this.state.activePage) - 1) * parseInt(pageSize);
	// 	let filterByCountry = defaultCountryCode;
	// 	let searchValue = event.target.value

	// 	this.setState({ loading: true, transactions: [] });
	// 	let postdata = { filter_type, filter_status, limit: parseInt(pageSize), skip, searchValue, fk_id, filterByCountry };
	// 	console.log(postdata)

	// 	client.post(api.all_transactions, postdata, (error, response) => {
	// 		if (!error) {
	// 			console.log(response, "transaction response");
	// 			if (typeof response.error !== "undefined") {
	// 				try {
	// 					if (!response.error) {
	// 						console.log(response.result.data)
	// 						this.setState({ transactions: response.result.data, searchValue });
	// 					} else {
	// 						setTimeout(() => {
	// 							toast.error(response.message);
	// 						}, 200);
	// 					}
	// 				} catch (e) {
	// 					console.log(e);
	// 					setTimeout(() => {
	// 						toast.error("Internal error occured. Please contact support");
	// 					}, 200);
	// 				}
	// 			} else {
	// 				setTimeout(() => {
	// 					toast.error("Internal error occured. Please contact support");
	// 				}, 200);
	// 			}
	// 		} else {
	// 			console.log(error);
	// 			setTimeout(() => {
	// 				toast.error("Internal error occured. Please contact support");
	// 			}, 200);
	// 		}

	// 	});

	// 	client.post(api.sub_tab_counts, postdata, (error, response) => {
	// 		if (!error) {
	// 			// console.log(response, "tab_counts");
	// 			if (typeof response.error !== "undefined") {
	// 				try {
	// 					if (!response.error) {
	// 						console.log(response.result.data)
	// 						this.setState({ tab_count: response.result.data });
	// 					}
	// 				} catch (e) { }
	// 			}
	// 		}

	// 		this.setState({ loading: false });

	// 	});







	// };

	refreshPage() {
		window.location.reload(false);
	}

	flagSubmitHandler = (event, id) => {
		event.preventDefault();
		let postdata = {};
		const foundTransaction = this.state.transactions.find((el) => {
			return el._id == id;
		});
		//console.log(event.target);

		if (typeof foundTransaction.flag_status === "undefined") {
			foundTransaction.flag_status = false;
		}
		if (event.target.name == "unflag") {
			postdata = {
				transaction_id: id,
				flag_status: !foundTransaction.flag_status,
				flag_note: { type: "unflag", note: "No comments", user: this.state.adminUser.email },
			};
		} else if (event.target.name == "comment") {
			postdata = {
				transaction_id: id,
				flag_note: { type: "normal", note: this.state.flag_note, user: this.state.adminUser.email },
			};
		} else {
			postdata = {
				transaction_id: id,
				flag_status: !foundTransaction.flag_status,
				flag_note: { type: "flag", note: this.state.flag_note, user: this.state.adminUser.email },
			};
		}
		client.put(api.update_transaction, postdata, (error, response) => {
			if (!error) {
				if (!response.error) {
					//console.log(response);
					this.refreshPage();
				} else {
					setTimeout(() => {
						toast.error(response.message);
					}, 200);
				}
			} else {
				setTimeout(() => {
					toast.error("Internal error occured. Please contact support");
				}, 200);
			}
		});
	};

	flagNoteHandler = (event) => {
		const flagNote = event.target.value;
		this.setState((prevState) => {
			return {
				...prevState,
				flag_note: flagNote,
			};
		});
	};

	deleteTransactionHandler = (id, event) => {
		event.preventDefault();
		const postData = { transaction_id: id };

		client.post(api.delete_transaction, postData, (error, response) => {
			if (!error) {
				if (!response.error) {
					//console.log(response);
					this.refreshPage();
				} else {
					setTimeout(() => {
						toast.error(response.message);
					}, 200);
				}
			} else {
				setTimeout(() => {
					toast.error("Internal error occured. Please contact support");
				}, 200);
			}
		});
	};

	renderFlagModal = (id) => {
		const foundTransaction = this.state.transactions.find((el) => {
			return el._id == id;
		});
		const style = {
			outline: "none",
			":focus": {
				outlineColor: "black",
			},
		};
		if (!foundTransaction.flag_status) {
			return (
				<Modal isOpen={this.state.flagModal} toggle={this.toggleFlagModal} className="modal-body" centered={true}>
					<ModalBody>
						<form onSubmit={(event) => this.flagSubmitHandler(event, this.state.modalId)} className="needs-validation">
							<h5>Please enter a note to flag transaction.</h5>
							<p style={{ color: "red" }}>Note: User will no longer be able to view transaction in their application.</p>
							<label className="form-label" style={{ display: "block" }}>
								Note:
							</label>
							<input type="text" size="40" onChange={this.flagNoteHandler} style={style} required></input>
							<Button
								color="default"
								style={{ marginTop: "20px", marginRight: "20px" }}
								type="submit"
								onClick={this.toggleFlagModal}
							>
								Cancel
							</Button>
							<Button color="primary" style={{ marginTop: "20px", marginRight: "20px" }} name="flag">
								Update status
							</Button>
						</form>
					</ModalBody>
				</Modal>
			);
		} else {
			return (
				<Modal isOpen={this.state.flagModal} toggle={this.toggleFlagModal} className="modal-body" centered={true}>
					<ModalBody>
						<h5>Are you sure you want to unflag transaction ?</h5>
						<Button color="default" style={{ marginTop: "20px", marginRight: "20px" }} onClick={this.toggleFlagModal}>
							Not Sure
						</Button>
						<Button
							color="primary"
							style={{ marginTop: "20px", marginRight: "20px" }}
							name="unflag"
							onClick={(event) => this.flagSubmitHandler(event, this.state.modalId)}
						>
							Yes, please
						</Button>
					</ModalBody>
				</Modal>
			);
		}
	};

	renderDeleteModal = (id) => {
		return (
			<Modal isOpen={this.state.deleteModal} toggle={this.toggleDeleteModal} className="modal-body" centered={true}>
				<form className="theme-form" onSubmit={(event) => this.deleteTransactionHandler(id, event)}>
					<ModalBody>
						<h4>Do you really want to delete this transaction ?</h4>
					</ModalBody>
					<ModalFooter>
						<Button color="default" onClick={this.toggleDeleteModal}>
							No
						</Button>
						<Button color="primary" type="submit">
							Yes
						</Button>
					</ModalFooter>
				</form>
			</Modal>
		);
	};

	renderStatusModal = () => {
		return (
			<Modal isOpen={this.state.statusModal} toggle={this.toggleStatusModal} className="modal-body" centered={true}>
				<form className="theme-form" noValidate="">
					<ModalBody style={{ textAlign: "center" }}>
						<div style={{ fontSize: "2rem", textAlign: "center" }}>
							<i className="icofont icofont-warning" style={{ color: "#FF2046" }}></i>
						</div>
						<h4 style={{ color: "#FF2046" }}>Failure!</h4>
						<h6>{this.state.statusMessage}</h6>
						<Button
							className="btn btn-danger"
							style={{ width: "100%", textAlign: "center", fontSize: "1.1rem", marginTop: "10px" }}
							onClick={this.toggleStatusModal}
						>
							Dismiss
						</Button>
					</ModalBody>
				</form>
			</Modal>
		);
	};

	getSettlementDetails = (item) => {
		try {
			let settlement_data = item.settlement_data;
			if (settlement_data.status == 0) {
				return (
					<p>
						<span
							className="authorize"
							onClick={() => this.statusClickSettlement(item)}
							style={{ cursor: "pointer", padding: "2px 10px", fontSize: "0.68rem" }}
						>
							Unpaid
						</span>
					</p>
				);
			} else if (settlement_data.status == 1) {
				return (
					<div>
						<p>Paid</p>
					</div>
				);
			} else if (settlement_data.status == 3) {
				return <span style={{ color: "#FF2046" }}>Unauthorized <br /> {item.reason_authorization != null && item.reason_authorization} </span>;
			}
		} catch { }
	};

	getSettlementdate = (item) => {
		try {
			let settlement_data = item.settlement_data;
			if (settlement_data.status == 0) {
				return (
					<span style={{ cursor: "pointer" }} onClick={() => this.statusClickSettlement(item)}>
						-
					</span>
				);
			} else if (settlement_data.status == 1 || settlement_data.status == 3) {
				return (
					<div>
						<p style={{ cursor: "pointer" }}>{settlement_data.authorized_by}</p>
						<p style={{ cursor: "pointer" }}>
							{moment.tz(settlement_data.authorized_on, "Australia/Sydney").format("DD/MM/YYYY HH:MM:SS")}
						</p>
					</div>
				);
			}
			// else if (settlement_data.status == 3) {
			//     return <span style={{ cursor: "pointer" }}>-</span>;
			// }
		} catch { }
	};

	statusClickSettlement = (item) => {
		this.setState({
			authorize_settlement_id: item._id,
			authorize_settlement_modal: true,
			unauthorizeSettlement: false,
			merchant_id: item.from_customer_id,
			transaction_no: item.transaction_no
		});
	};

	setReasonAuthorization = (event) => {
		this.setState({ reason_authorization: event.target.value })
	}

	enableRequestVerifyToggle = (event) => {
		event.preventDefault();
		try {
			let postData = {
				document_no: "request_doc_verify",
				email: this.state.adminUser.email,
				merchant_id: this.state.merchant_id,
				req_doc_value: true,
				userId: localStorage.getItem('userId'),
			}

			client.post(api.verify_merchant_document, postData, (error, response) => {
				if (!error) {
					if (!response.error) {
						toast.success("Verification request sent successfully");
						this.setState({ authorize_settlement_modal: false })
					} else {
						// setTimeout(() => {
						toast.error(response.message);
						// }, 200);
					}
				} else {
					// setTimeout(() => {
					toast.error("Internal error occured. Please contact support");
					// }, 200);
				}
			})
		} catch (err) {
			console.log(err)
		}
	}

	onSettlementDueStatusChange = (event) => {
		event.preventDefault();
		try {

			let { authorize_settlement_id, unauthorizeSettlement, reason_authorization, transaction_no } = this.state;

			this.setState({ authorize_settlement_btn: true });

			let postData = { transaction_id: authorize_settlement_id, status: unauthorizeSettlement ? 3 : 1, reason_authorization: reason_authorization, transaction_no: transaction_no };
			// alert(JSON.stringify(postData));
			client.post(api.dueSettlementStatus, postData, (error, response) => {
				if (!error) {
					// console.log(JSON.stringify(response));
					//   return false;
					if (typeof response.error !== "undefined") {
						try {
							if (!response.error) {
								// this.refreshPage();
								toast.success(response.message);
								this.setState({ authorize_settlement_modal: false })
								this.getAllTransactions();
							} else {
								toast.error(response.message);
							}
						} catch (e) {
							toast.error("Internal error occured. Please contact support");
						}
					} else {
						toast.error("Internal error occured. Please contact support");
					}
				} else {
					toast.error("Internal error occured. Please contact support");
					// _showToast.error("Internal error occured. Please contact support.");
				}
				this.setState({ authorize_settlement_btn: false });
			});
		} catch (err) {
			console.log(err, "unauthorize-error")
		}
	};

	renderDueSettlementModal = () => {
		return (
			<Modal
				isOpen={this.state.authorize_settlement_modal}
				toggle={() => this.setState({ authorize_settlement_modal: false })}
				className="modal-body"
				centered={true}
			>
				<form className="theme-form" noValidate="" onSubmit={this.onSettlementDueStatusChange}>
					<ModalHeader toggle={() => this.setState({ authorize_settlement_modal: false })}>
						<h6>Change due settlement status</h6>
					</ModalHeader>
					<ModalBody>
						{!this.state.unauthorizeSettlement && (
							<h6>Have you settled the due amount and want to change status to paid ?</h6>
						)}
						{this.state.unauthorizeSettlement && <div>
							<h6>Are you sure you want to unauthorize settlement requests ?</h6>
							<div className="pt-3">
								<h6>Reason of authorization *</h6>
								{/* <input type="text" style={{ width: '100%'}} onChange={this.setReasonAuthorization}/> */}
								<input class="rounded form-control" type="text" style={{ borderColor: '#000', padding: '0.375rem 0.75rem' }} onChange={this.setReasonAuthorization} />
								{this.state.show_reason_error && <p>Required</p>}
							</div>
						</div>}
					</ModalBody>
					<ModalFooter className="d-flex justify-content-around">
						{this.state.unauthorizeSettlement && (
							<Button
								disabled={this.state.authorize_settlement_btn ? "disabled" : ""}
								color="default"
								onClick={() => this.setState({ authorize_settlement_modal: false })}
							>
								Not sure!
							</Button>
						)}
						{!this.state.unauthorizeSettlement &&
							<Button color="default" type="submit" disabled={this.state.authorize_settlement_btn ? "disabled" : ""} className="p-2" onClick={this.enableRequestVerifyToggle}>
								Verification Required!
							</Button>
						}
						{!this.state.unauthorizeSettlement && (
							<Button
								disabled={this.state.authorize_settlement_btn ? "disabled" : ""}
								color="default"
								onClick={() => {
									this.setState({ unauthorizeSettlement: true });
									this.setState({ reason_authorization: null })
								}}
								className="p-2"
							>
								Unauthorize
							</Button>
						)}
						<Button color="primary" type="submit" disabled={this.state.authorize_settlement_btn ? true : this.state.reason_authorization == null ? true : false}>
							Yes Please!
						</Button>
					</ModalFooter>
				</form>
			</Modal>
		);
	};

	render_date = (add_date) => {
		if (typeof add_date === "undefined") {
			return;
		}

		const convert_date = new Date(add_date);
		const added_date = moment.tz(convert_date, "Australia/Sydney").format("DD/MM/YYYY HH:mm:ss");
		return added_date;
	};

	isGreen = (time) => {
		// start time and end time
		moment.tz.setDefault("Australia/Sydney");
		var result = moment(time).fromNow("hh").toString();
		//alert(hours + ' hour and ' + minutes + ' minutes.');
		var today = moment();
		var endDate = moment(time);
		var timeLeft = endDate.diff(today, "hours");

		if (timeLeft <= 2) {
			return true;
		}
		return false;
	};

	getSearchName = (item, type) => {
		if (type == 1) {
			if (
				item.from_wallet_type == 1 &&
				item.from_customers.length > 0 &&
				typeof item.from_customers[0].name != "undefined"
			) {
				return item.from_customers[0].name;
			} else {
				if (
					item.from_wallet_type == 2 &&
					item.from_mechant.length > 0 &&
					typeof item.from_mechant[0].business_name != "undefined"
				) {
					return item.from_mechant[0].business_name;
				} else {
					return "";
				}
			}
		} else if (type == 2) {
			if (item.to_wallet_type == 1 && item.to_customers.length > 0 && typeof item.to_customers[0].name != "undefined") {
				return item.to_customers[0].name;
			} else {
				if (
					item.to_wallet_type == 2 &&
					item.to_mechant.length > 0 &&
					typeof item.to_mechant[0].business_name != "undefined"
				) {
					return item.to_mechant[0].business_name;
				} else {
					return "";
				}
			}
		} else {
			return "";
		}
	};

	handleSearchSubmit = (event) => {
		event.preventDefault();

		this.setState({ transactions: [], activePage: 1 });
		this.getAllTransactions(this.props.activeTab, 1, this.props.defaultCountryCode);

	};

	deleteSelectedHandler = () => {
		const postData = { selectedIDs: this.state.selectedIDs };
		client.post(api.delete_selected_transaction, postData, (error, response) => {
			if (!error) {
				if (!response.error) {
					this.refreshPage();
					toast.success(response.message);
				} else {
					setTimeout(() => {
						toast.error(response.message);
					}, 200);
				}
			} else {
				setTimeout(() => {
					toast.error("Internal error occured. Please contact support");
				}, 200);
			}
		});
	};

	renderInfoModal = (id) => {
		const foundTransaction = this.state.transactions.find((el) => {
			return el._id == id;
		});

		if (typeof foundTransaction.flag_note == "undefined") {
			foundTransaction.flag_note = [];
		}
		let foundTransactionNote = [...foundTransaction.flag_note].reverse();
		return (
			<Modal isOpen={this.state.infoModal} toggle={this.toggleInfoModal} className="modal-body" centered={true}>
				<h5 style={{ padding: "15px", margin: "0" }}>Comments</h5>

				<ModalBody style={{ paddingTop: "0", paddingBottom: "0", maxHeight: "calc(100vh - 370px)", overflowY: "auto" }}>
					<div className="card-body chat-box" style={{ paddingTop: "0", paddingBottom: "0" }}>
						<div className="chat-right-aside bitcoin-chat">
							<div className="chat">
								<div className="chat-history chat-msg-box" style={{ padding: "0", margin: "0" }}>
									<ul style={{ margin: "0" }}>
										{foundTransactionNote.length != 0 ? (
											foundTransactionNote.map((el) => {
												return (
													<li style={{ margin: "0" }}>
														<div
															className="message my-message "
															style={{ marginBottom: "10px", paddingTop: "10px", paddingBottom: "10px" }}
														>
															<p>
																{el.user}
																<span className="message-data-time pull-right">{this.render_date(el.date)}</span>{" "}
															</p>
															{el.type == "normal" ? null : <p style={{ fontSize: "0.9rem" }}>Type : {el.type}</p>}
															{el.note}
														</div>
													</li>
												);
											})
										) : (
											<p>No comments added!</p>
										)}
									</ul>
								</div>
							</div>
						</div>
					</div>
				</ModalBody>
				<ModalFooter style={{ paddingTop: "10px", paddingBottom: "10px" }}>
					<div className="col-lg-12">
						<form className="theme-form">
							<div className="form-group mb-0 col-12">
								<label htmlFor="exampleFormControlTextarea4">Comment:</label>
								<textarea
									className="form-control"
									id="exampleFormControlTextarea4"
									required
									rows="3"
									onChange={this.flagNoteHandler}
								></textarea>
							</div>
							<div style={{ padding: "15px 0 0 15px" }}>
								<Button color="default" onClick={this.toggleInfoModal} style={{ marginRight: "15px" }}>
									Cancel
								</Button>
								<Button
									color="primary"
									type="submit"
									name="comment"
									onClick={(event) => this.flagSubmitHandler(event, this.state.modalId)}
								>
									Submit
								</Button>
							</div>
						</form>
					</div>
				</ModalFooter>
			</Modal>
		);
	};

	renderBankDetails = (item) => {

		let from_mechant;
		if (item.from_mechant && item.from_mechant.length > 0) {
			from_mechant = item.from_mechant[0]
		} else if (item.from_trash_merchants && item.from_trash_merchants.length > 0) {
			from_mechant = item.from_trash_merchants[0]
		}
		if (item.from_wallet_type == 2 && typeof from_mechant != "undefined") {
			try {
				let payoutsettings = from_mechant.payoutsettings;
				let bank_accounts = from_mechant.bank_accounts;

				let stripeEnabled = false
				let defaultAccount = null


				if (item.settlement_bank) {
					// console.log(item.settlement_bank, "item")
					defaultAccount = { bank_details: item.settlement_bank }
				} else {

					if (payoutsettings && payoutsettings.length > 0) {
						stripeEnabled = payoutsettings[0]?.payout_enabled ? true : false;
						if (stripeEnabled) {
							defaultAccount = payoutsettings[0]
						}
					}
					if (!stripeEnabled) {
						let defaultb = bank_accounts.find(a => a.bank_details.default == true)
						// console.log(defaultb, "default")
						if (defaultb)
							defaultAccount(defaultb)
					}
				}

				if (defaultAccount) {
					return (
						<div>
							{/* <li>bank of cambridge  ****1113 / 110000  </li>
						<li>Bank of america  ****3335 / 110000</li> */}
							{/* {defaultAccount?.bank_details.bank_name} {defaultAccount.bank_details.account_number} / {defaultAccount?.bank_details.routing_number} */}
							{defaultAccount?.bank_details.is_stripe &&
								<div style={{ fontSize: 11 }}>Stripe:</div>
							}
							{defaultAccount?.bank_details.bank_name &&
								<div style={{ fontSize: 11 }}><strong>Bank Name: </strong>{defaultAccount?.bank_details.bank_name} </div>
							}
							<div style={{ fontSize: 11 }}><strong>Account No.: </strong>{defaultAccount?.bank_details.account_number} </div>
							<div style={{ fontSize: 11 }}><strong>IFSC/BSB No.: </strong>{defaultAccount?.bank_details.routing_number} </div>
							<div style={{ fontSize: 11 }}><strong>Account Name: </strong>{defaultAccount?.bank_details.account_holder_name} </div>
						</div>
					);
				} else {
					return "";
				}
			} catch {
				return "";
			}
		} else {
			return "";
		}
	};

	renderStripeDetails = (item) => {
		let from_mechant;
		if (item.from_mechant && item.from_mechant.length > 0) {
			from_mechant = item.from_mechant[0]
		} else if (item.from_trash_merchants && item.from_trash_merchants.length > 0) {
			from_mechant = item.from_trash_merchants[0]
		}
		if (item.from_wallet_type == 2 && typeof from_mechant != "undefined") {
			let stripe = from_mechant;

			if (stripe.business_name == 'The Hunger Solution') {
				return (
					<p style={{ margin: '0px' }}>
						<CheckIcon style={{ color: "green", marginRight: "2px", verticalAlign: 'middle' }} />
						Payout Enabled
					</p>
				);
			} else {
				return "";
			}
		} else {
			return "";
		}
	};

	getReason = (transaction) => {
		if (transaction.comment == "") {
			return "";
		}

		const comment = JSON.parse(transaction.comment);
		if (typeof comment.message == "undefined") {
			return "";
		} else if (comment.message.toLowerCase().includes("card details")) {
			return "Invalid card details";
		} else if (comment.message.toLowerCase().includes("i-pay pin")) {
			return "Invalid i-Pay pin";
		} else if (
			comment.message.toLowerCase().includes("otp authentication") ||
			comment.message.toLowerCase().includes("user cancel")
		) {
			return "Pressed cancel or back button";
		} else if (comment.message.toLowerCase().includes("working days")) {
			return "Server or api error";
		} else if (comment.message.toLowerCase().includes("insufficient funds")) {
			return "Insufficient funds";
		} else if (comment.message.toLowerCase().includes("card has been declined")) {
			return "Card declined";
		} else if (comment.message.toLowerCase().includes("card type you entered isn't supported")) {
			return "Card type not supported";
		} else if (comment.message.toLowerCase().includes("payment amount is invalid")) {
			return "Invalid amount or exceeds max amount";
		} else if (
			comment.message.toLowerCase().includes("customer has exceeded the balance or credit limit available on their card")
		) {
			return "Card limit exceeded";
		} else if (comment.message.toLowerCase().includes("card does not support this type of purchase")) {
			return "Card not supported";
		} else if (comment.message.toLowerCase().includes("card has expired")) {
			return "Card expired";
		} else if (
			comment.message.toLowerCase().includes("card issuer could not be reached, so the payment could not be authorized")
		) {
			return "Card declined";
		} else {
			return "Error";
		}
	};

	onSuccessClick = (transaction) => {
		if (transaction.type == 1) {
			toast.error("Refund or Reject not available");
		} else if (typeof transaction.refund_transc_link != "undefined" || transaction.refund_transc_link == "") {
			toast.error("Refund or Reject not available");
			//this.setState({ successClickModal: true, selectedTransaction: transaction, successClickOption: "reject" })
		} else {
			this.setState({ successClickModal: true, selectedTransaction: transaction, successClickOption: "refund" });
		}
	};

	toggleSuccessClickModal = () => {
		this.setState((prevState) => {
			return {
				...prevState,
				successClickModal: !prevState.successClickModal,
				successClickOption: " refund",
			};
		});
	};

	onSubmitRefundRequest = (event) => {
		event.preventDefault();
		let { refund_amount, selectedTransaction } = this.state;
		let postData = { transaction_no: selectedTransaction.transaction_no, amount: +refund_amount };
		if (this.state.successClickOption == "reject") {
			postData = {
				transaction_no: selectedTransaction.transaction_no,
				amount: selectedTransaction.amount.toFixed(2),
				type: "reject",
				rejectReason: this.state.transc_rejectReason,
			};
			refund_amount = selectedTransaction.amount;
		}

		//setLoading(true)
		if (+refund_amount <= selectedTransaction.amount) {
			client.post(api.refund_request, postData, (error, response) => {
				if (!error) {
					// console.log(response);
					try {
						if (!response.error) {
							//console.log(response);
							this.setState({ successClickModal: false });
							toast.success("Amount refunded");
							this.props.fetchTransaction();
						} else {
							toast.error("Internal error occured.Please contact support.");
						}
					} catch (error) {
						toast.error("Internal error occured.Plelase contact support.");
					}
				} else {
					toast.error("Internal error occured.");
				}
			});
		} else {
			toast.error("Please enter correct amount");
		}
	};

	renderSuccessClickModal = () => {
		const { selectedTransaction, successClickModal, successClickOption, transc_rejectReason } = this.state;
		let spliteText = transc_rejectReason?.indexOf(":") ? transc_rejectReason?.split(" : ") : ["", ""];

		return (
			<Modal
				isOpen={this.state.successClickModal}
				toggle={this.toggleSuccessClickModal}
				className="modal-body"
				centered={true}
			>
				<form className="theme-form" onSubmit={this.onSubmitRefundRequest}>
					<ModalHeader toggle={this.toggleSuccessClickModal} style={{ fontSize: "1rem" }}>
						<select
							className="form-control"
							defaultValue="refund"
							onChange={(event) =>
								event.target.value !== "Select Reason Type"
									? this.setState({ successClickOption: event.target.value })
									: null
							}
							style={{ width: "47%", display: "inline-block" }}
						>
							<option value="Select Reason Type">Select Reason Type</option>
							<option value="refund">Refund</option>
							<option value="reject">Reject</option>
						</select>
						Transaction
					</ModalHeader>

					<ModalBody>
						<p>{transc_rejectReason}</p>
						{successClickOption == "refund" ? (
							<div>
								<span>Enter amount for refund</span>
								<input
									name="refund_amount"
									style={{
										border: "none",
										borderBottom: "1px solid rgb(197, 189, 189)",
										borderRadius: "0",
										fontSize: "0.75rem",
									}}
									required
									onChange={(event) => this.setState({ refund_amount: event.target.value })}
									type="text"
								></input>
								<p style={{ color: "red" }}>Note:You can refund upto ${selectedTransaction.amount.toFixed(2)}</p>
							</div>
						) : null}

						{successClickOption == "reject" ? (
							<div>
								<select
									className="form-control"
									value={
										spliteText[0] == ""
											? "Select Reasone"
											: spliteText[0] !== "" || spliteText[0] !== "Fraudulent" || spliteText[0] !== "Stolen"
												? "Others"
												: spliteText[0]
									}
									onChange={(event) =>
										this.setState({
											transc_rejectReason: event.target.value !== "Select Reasone" ? `${event.target.value} : ` : "",
										})
									}
									style={{ width: "47%", display: "inline-block" }}
								>
									<option value="Select Reasone">Select Reasone</option>
									<option value="Fraudulent">Fraudulent</option>
									<option value="Stolen">Stolen</option>
									<option value="Others ">Others </option>
								</select>
								<br />
							</div>
						) : null}
						{successClickOption == "reject" ? (
							<div>
								<input
									placeholder={"Please enter note for your action"}
									style={{
										border: "none",
										borderBottom: "1px solid rgb(197, 189, 189)",
										borderRadius: "0",
										margin: "1rem 0",
										fontSize: "normal",
										width: "80%",
									}}
									type="text"
									onChange={(event) =>
										this.setState({
											transc_rejectReason: event.target.value.length == 0 ? "" : `${spliteText[0]} : ${event.target.value}`,
										})
									}
								/>
							</div>
						) : null}
					</ModalBody>
					<ModalFooter>
						{
							<Button color="default" onClick={this.toggleSuccessClickModal}>
								Not sure!
							</Button>
						}
						<Button color="success" type="submit">
							Yes, Please!
						</Button>
					</ModalFooter>
				</form>
			</Modal>
		);
	};

	render_ip_location = (device_data) => {
		try {
			if (device_data && device_data.ip != "") {
				let location = "";
				try {
					let device_details = JSON.parse(device_data.device_details);
					location = device_details.suburb;
				} catch { }
				return (
					<>
						<div>ip:{device_data.ip}</div>
						{location != "" && <div>{location}</div>}
					</>
				);
			} else {
				return null;
			}
		} catch {
			return null;
		}
	};

	renderAllTable = (type, data_lengh) => {
		let {
			hideTo,
			hideAction,
			hideReceipt,
			hideMessage,
			hideBankDetails,
			hidetransferFee,
			hidedue,
			settlementPage,
			hideReason,
			hidedescription
		} = this.props;
		let { transactions } = this.state;
		hidedescription = hidedescription || false
		hideTo = hideTo == true ? true : false;
		hideAction = hideAction == true ? true : false;
		hideReceipt = hideReceipt == true ? true : false;
		hideMessage = hideMessage == true ? true : false;
		hideBankDetails = hideBankDetails == false ? false : true;

		hidetransferFee = hidetransferFee == false ? false : true;
		hidedue = hidedue == false ? false : true;
		hideReason = hideReason == false ? false : true;
		let { filter_type } = this.props;

		const { searchedData, access_level } = this.state;

		return (
			<Fragment>
				<div className="table-responsive">
					<table className="table table-border-horizontal">
						<thead>
							<tr style={{ background: '#f9fafe' }}>
								<th scope="col">
									<p className="  ">#</p>
								</th>
								<th scope="col" style={{ width: "10%" }}>
									<p className=" ">Date/Time</p>
									{/* <p className=" ">Time</p> */}
								</th>
								<th scope="col">
									<p className=" ">Trans ID</p>
									{/* <p className=" ">IP / location</p> */}
								</th>
								<th scope="col">
									{filter_type == "settlement_customer" ?
										<p className=" ">Customer Name</p>
										:
										<p className=" ">Store Name</p>
									}

								</th>
								{!hidedescription &&
									<th scope="col" style={{ width: "13%" }}>
										<p className=" ">Description</p>
										{/* <p className=" ">Card details</p> */}
									</th>
								}
								{!hideTo && <th scope="col">
									<p className="  ">Reference</p>
								</th>}
								<th scope="col">
									<p className="  ">Amount</p>
								</th>
								<th scope="col" style={{ minWidth: 90 }}>
									<p className="  ">i-Pay</p>
								</th>
								<th scope="col">
									<p className="  ">Net Amount</p>
								</th>

								{!hidetransferFee && <th scope="col">
									<p className="  ">Tansfer Fee</p>
								</th>}
								{!hidedue && <th scope="col"><p className="  ">Due</p></th>}
								{!hideReceipt && (
									<th scope="col" style={{ width: "9%" }}>
										<p className="  ">Receipt</p>
									</th>
								)}
								{/* {!hideMessage && (
									<th scope="col" style={{ width: "12%" }}>
										<p className="  ">Message</p>
									</th>
								)} */}

								{/* <th scope="col" style={{ width: "12%" }}>
									<p className="  ">Stripe Linked</p>
								</th> */}

								{!hideBankDetails && (
									<th scope="col" style={{ width: "12%" }}>
										<p className="  ">Payout Account</p>
									</th>
								)}
								<th scope="col">
									<p className="  ">Authorized</p>
									{/* <p className=" ">Authorized on</p> */}
								</th>
								<th scope="col"><p className="  ">Status</p></th>
								{!hideReason && <th scope="col"><p className="  ">Reason</p></th>}
								{!hideAction && access_level != 1 && <th scope="col"><p className="  ">Action</p></th>}
							</tr>
						</thead>
						<tbody>
							{transactions &&
								transactions.map((item, index) => {
									// console.log(item, "item");
									let currency_code = this.getCurrencyCode(item);
									try {

									} catch { }

									let title = "";
									let subtitle = "";
									if (item.type == 1) {
										title = "Wallet top-up";
										subtitle = item.payment_method == 4 ? "Receipt Payment" : item.payment_method == 5 ? "Receipt Payment" : `from **${item.card_number[14]}${item.card_number[15]}`;
									}
									else if (item.type == 2) {
										title = "Cashback from";
										subtitle = "i-Pay Cashback"
									}
									else if (item.type == 3) {

										if (item.to_mechant._id == item.from_customer_id) {

											if (item.request_token) {

												title = "Requested By";
											} else {
												title = "Paid To";
											}
											if (item.to_wallet_type == 2 && item.to_mechant && item.to_mechant.length > 0) {
												subtitle = item.to_mechant[0].displayName;
											} else if (item.to_customers && item.to_customers.length > 0) {
												subtitle = item.to_customers[0].displayName;
											}
											else if (item.card_number) {
												subtitle = "**" + item.card_number.slice(-4);
											}
										} else {
											title = "Received From";
											// console.log(item, "item")
											if (item.from_wallet_type == 2) {

												// } && item.from_mechant && item.from_mechant.length > 0) {
												let from_mechant;
												if (item.from_mechant && item.from_mechant.length > 0) {
													from_mechant = item.from_mechant[0]
												} else if (item.from_trash_merchants && item.from_trash_merchants.length > 0) {
													from_mechant = item.from_trash_merchants[0]
												}
												subtitle = from_mechant.business_name;
											} else if (item.from_customers && item.from_customers.length > 0) {
												subtitle = item.from_customers[0].name;
											}
											else if (item.card_number) {
												subtitle = "**" + item.card_number.slice(-4);
											}
										}

									}
									else if (item.type == 4) {
										title = "Settlement";
										subtitle = "Settlement Request"
									}

									return (
										<tr key={item._id}>
											<th scope=" row">{(this.state.activePage - 1) * this.state.pageSize + index + 1}</th>
											<td style={{ width: "7%" }} onClick={() => this.isGreen(item.transaction_date)}>
												{this.render_date(item.transaction_date)}
											</td>
											<td>
												<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
													{" "}
													<span style={this.isGreen(item.transaction_date) ? { color: "green" } : null}>
														{" "}
														{item.transaction_no}{" "}
													</span>{" "}
													{`${item.type == 4 ? " Settle" : ""}`}
													<MdOutlineContentCopy
														id="link_copy"
														onClick={() => {
															navigator.clipboard.writeText(item.transaction_no)
															toast.success("Copied!");
															// this.setState((prevState) => {
															// 	return {
															// 		...prevState,
															// 		copyTextToolTip: !prevState.copyTextToolTip,
															// 	};
															// });
														}}
													/>
													{
														this.state.copyTextToolTip && (
															<Tooltip placement="bottom" toggle={() => {
																this.setState((prevState) => {
																	return {
																		...prevState,
																		copyTextToolTip: !prevState.copyTextToolTip,
																	};
																});
															}} target="link_copy" isOpen={this.state.copyTextToolTip}>
																Copied
															</Tooltip>
														)
													}
													{/* <CopyToClipboard
														text={item.transaction_no}
													// onCopy={() => this.setState({ textCopy: true })}
													>
														<i
															id="link-copy"
															className="icofont icofont-copy-alt"
															style={{ fontSize: "1rem", marginLeft: "0.3rem", cursor: "pointer" }}
														></i>
													</CopyToClipboard> */}
													{/* <Tooltip toggle={() => this.setState((prev) => { return { ...prev, textCopy: !prev.textCopy } })} placement="bottom" target="link-copy" isOpen={this.state.textCopy}>
														Copied!
													</Tooltip> */}
												</div>
												{this.render_ip_location(item.device_data)}
											</td>
											<td style={{ width: "10%" }}>
												<p style={{ margin: "0", fontSize: "0.75rem" }}>{this.getName(item, 2)}</p>
											</td>
											{/* {item.payment_method == 5 ? (
												<td>PAYPAL</td>
											) : item.from_customer_id !== "" && typeof item.topup_type != "undefined" && item.topup_type != 1 ? (
												<td style={{ width: "13%" }}>
													<p style={{ margin: "0", fontSize: "0.75rem" }}>{this.getName(item, 1)}</p>
													<p style={{ fontSize: "0.75rem" }}>{this.getIpayId(item, 1)}</p>
													<p>{item.topup_type == 2 ? "Direct deposit" : "Pay ID"}</p>
												</td>
											) : item.from_customer_id !== "" ? (

												<td style={{ width: "13%" }}>
													<p style={{ margin: "0", fontSize: "0.75rem" }}>{this.getName(item, 1)}</p>
													<p style={{ fontSize: "0.75rem" }}>{this.getIpayId(item, 1)}</p>
													<p></p>
												</td>
											) : (
												<td style={{ width: "13%" }}>
													<p style={{ margin: "0", fontSize: "0.75rem" }}>
														{"**" + item.card_number.slice(-4)}
													</p> */}
											{/* <p style={{ margin: "0", fontSize: "0.75rem" }}>{this.getCardExpiry(item.card_number)}</p> */}
											{/* </td> 
											)} */}
											{
												!hidedescription &&
												<td><strong>{title}</strong><br />{subtitle}</td>
											}
											{
												!hideTo && (
													// <td>
													// 	<p style={{ margin: "0", fontSize: "0.75rem" }}>{this.getName(item, 2)}</p>
													// 	<p style={{ fontSize: "0.75rem" }}>{this.getIpayId(item, 2)}</p>
													// </td>
													<td>
														{item.reference == 6 ? JSON.parse(item.comment).message : item.description ? item.description : "N.A"}
													</td>
												)
											}
											{/* {item.type == 3 ? */}
											<td>
												{currency_code}{(item.pay_amount + item.charges).toFixed(2)
												}
												{/* {this.props.defaultCountryCode == item.from_country && item.amount_payer ?
													currency_code +""+ item.amount_payer.amount.toFixed(2)
													:
													(item.pay_amount + item.charges).toFixed(2)
												} */}
											</td>
											{/* : */}
											{/* <td >${item.type == 4 && !settlementPage ? item.pay_amount.toFixed(2) : item.type == 4 && settlementPage ? item.amount.toFixed(2) : item.amount.toFixed(2)}</td> */}
											{/* } */}
											{
												<td style={{}}>
													<div style={{
														clear: 'both',
														display: "inline-block",
														overflow: "hidden",
														whiteSpace: "nowrap"
													}}>
														<span>
															{currency_code}{item.charges.toFixed(2)}
														</span>
														{item.charges > 0 &&
															<>
																<span id={`Popover${item._id}${type}`} style={{ marginLeft: "5px" }}
																	onMouseEnter={() => this.togglechargesInfo(`Popover${item._id}${type}`)}
																	onMouseLeave={() => this.togglechargesInfo(`Popover${item._id}${type}`)}
																>
																	<i style={{ color: "grey", }} className="fa fa-info-circle"></i>
																</span>
																<Popover placement={"right"} isOpen={this.isToolTipOpen(`Popover${item._id}${type}`)} target={`Popover${item._id}${type}`} toggle={() => this.togglechargesInfo(`Popover${item._id}${type}`)}>
																	{/* <PopoverHeader>Popover Title</PopoverHeader> */}
																	<PopoverBody>
																		{item.charges_info && item.charges_info.length ?
																			<>
																				{item.charges_info.map((item) => <p style={{ marginBottom: 0 }}>{item.title} : {currency_code}{item.amount.toFixed(2)}</p>)}
																			</>
																			:
																			<p style={{ marginBottom: 0 }}>Service charges : {currency_code}{item.charges.toFixed(2)}</p>
																		}
																	</PopoverBody>
																</Popover>
															</>
														}
													</div>
												</td>
											}
											<td>
												{/* // JSON.stringify(item.amount_payer) + 
												// JSON.stringify(item.amount_receiver) */}

												{/* {item.amount_payer ? ((item.type != 4 && this.props.defaultCountryCode != item.from_country) ?
													item.amount_payer.currency.code + "" + item.amount_payer.amount.toFixed(2)
													:
													item.amount_receiver.currency.code + "" + item.amount_receiver.amount.toFixed(2)

													// (item.pay_amount + item.charges).toFixed(2)
												) : */}
												{currency_code + item.pay_amount.toFixed(2)}
												{/* } */}

												{/* // item.amount_payer?.amount?.toFixed(2)
												// item.pay_amount.toFixed(2) */}
											</td>

											{!hidetransferFee && <td>{currency_code}{item.charges.toFixed(2)}</td>}
											{!hidedue && <td>{currency_code}{item.pay_amount.toFixed(2)}</td>}

											{
												!hideReceipt && (
													<td style={{ width: "9%" }}>
														{item.receipt != null ? (
															item.receipt.includes("png") || item.receipt.includes("jpeg") || item.receipt.includes("jpg") ? (
																<img
																	src={`${client.url(`images/backend-topup/${item.receipt}`)}`}
																	onClick={() =>
																		this.props.imageClicked(`${client.url(`images/backend-topup/${item.receipt}`)}`)
																	}
																	style={{ width: "35px", height: "35px", borderRadius: "50%" }}
																></img>
															) : item.receipt.includes("pdf") ? (
																<a href={`${client.url(`images/backend-topup/${item.receipt}`)}`}>{item.receipt}</a>
															) : (
																<p
																	style={{ cursor: "pointer", textDecoration: "underline" }}
																	onClick={() => this.props.receiptClick(item.receipt)}
																>
																	Receipt details
																</p>
															)
														) : (
															<p>N.A</p>
														)}
													</td>
												)
											}
											{/* {!hideMessage && (
												<td style={{ width: "12%" }}>
													{item.reference == 6 ? JSON.parse(item.comment).message : item.description}
												</td>
											)} */}
											{/* <td>{this.renderStripeDetails(item)}</td> */}

											{!hideBankDetails && <td style={{ width: "12%" }}>{this.renderBankDetails(item)}</td>}

											<td>
												{item.type == 4 ? (
													this.getSettlementdate(item)
												) : item.authorized_on == null ? (
													""
												) : (
													<Fragment>
														<p style={{ fontSize: "0.85rem", margin: "0" }}>
															{item.authorized_on == null
																? ""
																: new Date(item.authorized_on) >= new Date("10/11/2020")
																	? item.authorized_by
																	: "David M"}
														</p>
														<p style={{ fontSize: "0.85rem" }}>
															{moment.tz(item.authorized_on, "Australia/Sydney").format("DD/MM/YYYY HH:MM:SS")}
														</p>
													</Fragment>
												)}
											</td>
											<td>
												{item.type == 4 ? (
													// this.getStatus(item.status) == "Failed" ?
													// <span onClick={() => this.statusClick(item.comment)}
													//     style={{ color: "Red" }}>
													//     {this.getStatus(item.status)}
													// </span>
													// : item.status == 0 ? <p className="authorize" onClick={() => this.props.authorizeTopupClick(item._id)}>{this.getStatus(item.status)}</p>
													//     : <span >
													//         {this.getStatus(item.status)}
													//         </span>
													this.getSettlementDetails(item)
												) : item.reference == 6 && item.status == 2 ? (
													<p>Rejected</p>
												) : this.getStatus(item.status) == "Failed" ? (
													<span onClick={() => this.statusClick(item.comment)} style={{ color: "Red" }}>
														{this.getStatus(item.status)}
													</span>
												) : item.status == 0 ? (
													<p className="authorize" onClick={() => this.props.authorizeTopupClick(item._id)}>
														{this.getStatus(item.status)}
													</p>
												) : item.status == 1 ? (
													<p className="authorize" onClick={() => this.onSuccessClick(item)}>
														{this.getStatus(item.status)}
													</p>
												) : (
													<span>{this.getStatus(item.status)}</span>
												)}
											</td>
											{
												!hideReason && (
													<td>
														{item.status == 2
															? this.getReason(item)
															: item.refund_status == 1
																? `$${item.refund_data.amount} was refunded.`
																: null}
													</td>
												)
											}
											{/* <td style={{ width: "10%" }}>
                                            {this.getStatus(item.status) == "Failed" ?
                                                <span >
                                                    {JSON.parse(item.comment).message}
                                                </span> :
                                                null
                                            }
                                        </td> */}

											{
												!hideAction && access_level != 1 && (
													<td>
														{/* {access_level == 3 && <span style={{ marginRight: "10px", fontSize: "1.2em" }}
                                                onClick={() => this.deleteClick(item._id)}>
                                                <i style={{ color: "red" }} id="delete" title="delete" className="icofont icofont-ui-delete"></i>
                                            </span>} */}
														<span style={{ marginRight: "10px", fontSize: "1.2em" }} onClick={() => this.flagClick(item._id)}>
															<i style={{ color: item.flag_status ? "red" : "black" }} id="block" className="fa fa-flag-o"></i>
														</span>
														<span style={{ marginRight: "10px", fontSize: "1.2em" }} onClick={() => this.infoClick(item._id)}>
															<i className="fa fa-list-alt" style={{ color: "black" }}></i>
														</span>
														{/* {access_level == 3 && (
														<span>
															<input type="checkbox" onClick={(event) => this.props.deleteSelectedClick(event, item._id)} />
														</span>
													)} */}
													</td>
												)
											}
										</tr>
									);
								})}
						</tbody>
					</table>
					<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "20px" }}>

						{this.state.activePage * this.state.pageSize > data_lengh ? (
							<p style={{ textAlign: "right", fontSize: "0.63rem", margin: "0" }}>
								Showing {(this.state.activePage - 1) * this.state.pageSize + 1} to {data_lengh} of {data_lengh} entries
							</p>
						) : (
							<p style={{ textAlign: "right", fontSize: "0.63rem", margin: "0" }}>
								Showing {(this.state.activePage - 1) * this.state.pageSize + 1} to{" "}
								{this.state.activePage * this.state.pageSize} of {data_lengh} entries
							</p>
						)}
						<PaginationComponent
							totalItems={data_lengh}
							defaultActivePage={this.state.activePage}
							pageSize={this.state.pageSize}
							onSelect={this.handlePageChange}
						/>
					</div>
				</div>
			</Fragment >
		);
	};

	renderDeleteSelectedModal = () => {
		return (
			<Modal
				isOpen={this.state.deleteSelectedModal}
				toggle={this.toggleDeleteSelectedModal}
				className="modal-body"
				centered={true}
			>
				<form className="theme-form" noValidate="" onSubmit={this.deleteSelectedHandler}>
					<ModalBody>
						<h4>Do you really want to delete these transactions ?</h4>
					</ModalBody>
					<ModalFooter>
						<Button color="default" onClick={this.toggleDeleteSelectedModal}>
							Not sure
						</Button>
						<Button color="primary" type="submit">
							Yes, please!
						</Button>
					</ModalFooter>
				</form>
			</Modal>
		);
	};

	render() {
		let { merchants, customers, cards, hideFilter, activeTab } = this.props;
		hideFilter = hideFilter == true ? true : false;
		const { searchedData, access_level, transactions, loading, tab_count, transactions_length } = this.state;
		let data_lengh = 0;

		if (activeTab == 1) data_lengh = tab_count.success;
		else if (activeTab == 2) data_lengh = tab_count.pending;
		else if (activeTab == 3) data_lengh = tab_count.failed;
		else if (activeTab == 4) data_lengh = 0;
		else if (activeTab == 5) data_lengh = transactions_length

		// console.log(this.state.getDataByID, "state trnas_id");
		// console.log(this.props.queryData?.transaction_id, "new props trans id");



		return (
			<Fragment>
				{this.state.modalId ? this.renderFlagModal(this.state.modalId) : null}
				{this.state.modalId ? this.renderDeleteModal(this.state.modalId) : null}
				{this.state.modalId && transactions ? this.renderInfoModal(this.state.modalId) : null}
				{this.renderDeleteSelectedModal()}
				{this.renderStatusModal()}
				{this.state.authorize_settlement_id && this.renderDueSettlementModal()}
				{this.state.selectedTransaction && this.renderSuccessClickModal()}

				{/* {access_level != 1 && <Button className="mb-3" outline color="primary" onClick={toggle2}><i className="fa fa-plus" style={{ margin: "0 7px 0 0" }}></i>Add New Template</Button>} */}


				<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: '5px' }}>
					<Nav tabs className="nav-pills nav-light mb-1">

						{!hideFilter && (
							<NavItem className="nav nav-tabs" id="myTab" role="tablist">
								<NavLinkTab
									className={activeTab == "1" ? "active nav-link transTabs-active" : "transTabs"}
									onClick={() => this.setActiveTab("1")}
									value="1"
								>
									Succeeded({tab_count.success})
								</NavLinkTab>
							</NavItem>
						)}
						{!hideFilter && (
							<NavItem className="nav nav-tabs" id="myTab" role="tablist">
								<NavLinkTab
									className={activeTab == "2" ? "active nav-link transTabs-active" : "transTabs"}
									onClick={() => this.setActiveTab("2")}
									value="2"
								>
									Pending({tab_count.pending})
								</NavLinkTab>
							</NavItem>
						)}
						{!hideFilter && (
							<NavItem className="nav nav-tabs" id="myTab" role="tablist">
								<NavLinkTab
									className={activeTab == "3" ? "active nav-link transTabs-active" : "transTabs"}
									onClick={() => this.setActiveTab("3")}
									value="3"
								>
									Failed({tab_count.failed})
								</NavLinkTab>
							</NavItem>
						)}
						{!hideFilter && (
							<NavItem className="nav nav-tabs" id="myTab" role="tablist">
								<NavLinkTab
									className={activeTab == "4" ? "active nav-link transTabs-active" : "transTabs"}
									onClick={() => this.setActiveTab('4')}
									value="4"
								>
									Blocked(0)
								</NavLinkTab>
							</NavItem>
						)}

						{/* <NavItem className="nav nav-tabs" id="myTab" role="tablist">
							<NavLinkTab
								className={activeTab == "5" ? "active nav-link" : ""}
								onClick={() => this.setActiveTab("5")}
								value="5"
							>
								All({tab_count.all})
							</NavLinkTab>
						</NavItem> */}
					</Nav>



				</div>

				<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: '5px' }}>
					<Nav tabs className="nav-pills nav-light mb-1">

						<form onSubmit={(event) => this.handleSearchSubmit(event)} style={{ display: "inline-block" }}>
							<div>
								<input
									style={{
										border: "#dfe8f1 solid 1px",
										borderRadius: "3px",
										padding: "10px",
										fontSize: "0.75rem",
										outlineColor: '#dfe8f1'
									}}
									type="text"
									placeholder="Search"
									onChange={this.onSearchChangeHandler}
									value={this.state.searchValue}
								/>

								{/* {this.state.searchValue && */}

								<Button color="warning" style={{ marginLeft: "10px", marginRight: "10px" }} name="search">
									Search
								</Button>

								{/* } */}
							</div>
						</form>
					</Nav>
					<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
						<select
							className="custom-select"
							style={{ width: "32%", marginRight: "7px" }}
							onChange={this.setPageSize}
							value={this.state.pageSize}
						>
							<option value="15">15</option>
							<option value="25">25</option>
							<option value="50">50</option>
							<option value="75">75</option>
							<option value="100">100</option>
						</select>
						<span>Records per Page</span>
					</div>
				</div>
				{/* {template_data ? */}

				{!loading ? (
					<TabContent activeTab={this.props.activeTab.toString()}>
						{!hideFilter && <TabPane tabId="1">{this.renderAllTable("success", data_lengh)}</TabPane>}

						{!hideFilter && <TabPane tabId="2">{this.renderAllTable("pending", data_lengh)}</TabPane>}

						{!hideFilter && <TabPane tabId="3">{this.renderAllTable("failed", data_lengh)}</TabPane>}

						{!hideFilter && <TabPane tabId="4">{/* {this.renderAllTable("")} */}</TabPane>}

						<TabPane tabId="5">{this.renderAllTable("all", data_lengh)}</TabPane>
					</TabContent>
				) : (
					<div className="loader-box">
						<div className="loader" style={{ display: "flex", alignItems: "center" }}>
							<DisappearedLoading size="medium" color="#514F4E" />
						</div>
					</div>
				)}
			</Fragment>
		);
	}
}

export default AllTransactions;
