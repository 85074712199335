import React, { Fragment, Component, createRef } from "react";
import { connect } from "react-redux";
import logo from "../assets/images/ipay-logo-1.png";
import client from "../Api/HTTPClient";
import api from "../constant/apilist";
import { toast, ToastContainer } from "react-toastify";
import { Redirect, withRouter } from "react-router-dom";
import { NavLink } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import * as devices from "react-device-detect";
import ipLocation from "iplocation";
import publicIp from "public-ip";
import moment from "moment";
import Swal from "sweetalert2";
import * as actionCreators from "../store/actions/index";
import OtpInput from "react-otp-input";
import '../assets/scss/verifyOtp.scss';
import Logo from "../assets/images/blue_linky_logo.svg";
import VerifyOtpComp from "./verifyOtpRef";

class VerifyOtp extends Component {
    state = {
        otp: null,
        prevOtp: null,
        userId: null,
        userDetails: null,
        location: null,
        browserId: this.props.browserId,
        ipAddress: null,
        timeLeft: 30,
        isResendDisabled: false
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        //console.log(nextProps, prevState)
        if (nextProps.browserId != prevState.browserId) {
            return {
                browserId: nextProps.browserId,
            };
        } else {
            return null;
        }
    }

    componentDidMount() {
        //console.log(this.props);
        /* for (let x in this.refs) {
            this.refs[x].onkeypress = (e) => {
                this.handleKeyPress(e, this.refs[x])
            }
            // console.log(x);
            //console.log(this.refs[x]);
        } */
        //this.refs["inputRef1"].focus();
        this.getLocation()

        let details = {};
        const query = new URLSearchParams(this.props.location.search);
        for (let params of query.entries()) {
            details[params[0]] = params[1]
        }
        this.setState({ userDetails: details });

        if (this.state.timeLeft > 0) {
            this.timer = setInterval(this.decreaseTime, 1000);
        }

    }

    componentWillUnmount() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    }

    decreaseTime = () => {
        this.setState(prevState => {
            if (prevState.timeLeft > 1) {
                return { timeLeft: prevState.timeLeft - 1 };
            } else {
                clearInterval(this.timer);
                return { timeLeft: 0, isResendDisabled: false };
            }
        });
    };


    // componentDidUpdate() {
    //     for (let x in this.refs) {
    //         this.refs[x].onkeypress = (e) => {
    //             this.handleKeyPress(e, this.refs[x])
    //         }
    //         // console.log(x);
    //         //console.log(this.refs[x]);
    //     }

    //     document.querySelector(".opt-text").addEventListener("paste", (e) => {
    //         e.preventDefault();
    //         const text = e.clipboardData.getData("text/plain");
    //         //console.log(text)
    //         //document.execCommand("insertHTML", false, text);
    //         this.pasteValues(text)
    //     });


    // }

    getLocation = async () => {
        const ipAddress = await publicIp.v4();
        // console.log(ipAddress, "ipAddress");
        this.setState({ ipAddress: ipAddress })
        const locationFetched = await ipLocation(ipAddress);
        const locationObject = {
            city: locationFetched.city,
            state: locationFetched.region.name,
            country: locationFetched.country.name
        }
        const location = Object.values(locationObject).join(",")
        this.setState({ location: location })
        /* console.log(location); */

    }

    handleKeyPress = (e, field) => {
        if (e.keyCode == 32) {
            this.refs[field.name].focus()
        } else {
            let next = this.refs[field.name].nextSibling;
            if (next && next.tagName === "INPUT") {
                this.refs[field.name].nextSibling.focus();
            };
        }

    };

    // pasteValues = (element) => {
    //     var values = element.split("");
    //     for (let i = 0; i < values.length; i++) {
    //         let inputBox = document.querySelectorAll(`.opt-text`);
    //         inputBox[i].value = values[i];
    //         const name = inputBox[i].name;
    //         const value = inputBox[i].value
    //         console.log(name, value)
    //         this.setState(prevState => {
    //             return {
    //                 ...prevState,
    //                 otp: {
    //                     ...prevState.otp,
    //                     [name]: value
    //                 }
    //             }
    //         });
    //         //console.log(inputBox);
    //     }

    // };

    onOtpSubmit = (e) => {
        e.preventDefault();
        const otpcode = +Object.values(this.state.otp).join("");

        // console.log('otpcode ==>',otpcode);

        let postData = {}

        const device_name = devices.browserName;
        const device_version = devices.browserVersion;
        const device_os = devices.osName;
        const device_os_version = devices.osVersion;
        const device = device_name + " " + device_version + ", " + device_os + " " + device_os_version;
        const currentTime = moment().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        const device_loggedIn = {
            browserId: this.state.browserId,
            device: device,
            time: currentTime,
            location: this.state.location,
            ipAddress: this.state.ipAddress
        }
        if (this.state.userDetails.time) {
            postData = { userId: this.state.userDetails.id, time: this.state.userDetails.time, browserId: this.state.browserId, verification_code: this.state.otp }

        } else {
            postData = { userId: this.state.userDetails.id, device: device_loggedIn, verification_code: this.state.otp }

        }

        console.log(postData);

        this.props.onOtpVerify(postData, this.state.browserId)

    }

    // onOtpChange = (event) => {
    //     const { name, value } = event.target;

    //     if (value == " ") {
    //         this.setState(prevState => {
    //             return {
    //                 ...prevState,
    //                 otp: {
    //                     ...prevState.otp,
    //                     [name]: ""
    //                 }
    //             }
    //         });
    //     } else {
    //         this.setState(prevState => {
    //             return {
    //                 ...prevState,
    //                 otp: {
    //                     ...prevState.otp,
    //                     [name]: value
    //                 }
    //             }
    //         });
    //     }

    //     this.props.onRevertToInitialState();

    // }

    onResendOtpSubmit = () => {
        this.setState({ timeLeft: 30, isResendDisabled: true }, () => {
            if (this.timer) {
                clearInterval(this.timer);
            }
            this.props.onResendOtp(this.state.userDetails.id);
            this.timer = setInterval(this.decreaseTime, 1000);
        });
    }

    render() {
        const { userDetails, otp, prevOtp } = this.state;
        let authRedirect = null;
        if (this.props.error) {
            // console.log(this.props.errorMsg);

            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: this.props.errorMsg,
            })
            this.props.onRevertToInitialState()

        }
        if (!this.props.error && this.props.resendotp) {

            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: "Otp sent",
            })

            this.props.onRevertToInitialState()
        }

        if (this.props.isAuthenticated) {
            toast.success(this.props.successMsg);
            authRedirect = <Redirect to={`${process.env.PUBLIC_URL}/dashboard/default`}></Redirect>
        }
        console.log(otp);

        return (
            <Fragment>
                {authRedirect}

                <div className="page-wrapper">
                    <div className="container-fluid">
                        <div className="authentication-main">
                            <div className="row">
                                <div className="col-sm-12 p-0">
                                    <div className="auth-innerright" style={{ display: "flex", flexDirection: "column" }}>
                                        <div className="">
                                            <img
                                                src={Logo}
                                                style={{ height: "90px", width: "120px" }}
                                                alt="logo"
                                            />
                                        </div>
                                        <div className="reset-password-box" style={{ width: "auto" }}>

                                            {/* <div className="text-center" ><img src={logo} alt="" style={{ width: "80px", height: "80px" }} /></div> */}
                                            <div className="card mt-1 mb-0">

                                                <div>
                                                    <h4>Email send to support@ipaypro.co</h4>
                                                    {/* {userDetails && userDetails.time ? <h4>You've just requested to access i-Pay admin account.</h4>
                                                        : <h4>Email send to support@ipaypro.co</h4>
                                                    } */}
                                                </div>

                                                <div style={{ margin: "15px 0", }}>
                                                    <p style={{ fontSize: "13px" }}>{`Just to make sure it's you, we emailed the verification code to ${userDetails && userDetails.time ? "your email." : "support@ipaypro.co"}. `}</p>
                                                </div>
                                                <form className="theme-form" onSubmit={this.onOtpSubmit}>
                                                    <div className="form-group rounded p-4 opt-box otp_Box">
                                                        <label className="col-form-label pt-0 font-weight-bold" >Enter OTP</label>
                                                        <div className="input_otp_container">
                                                            <VerifyOtpComp
                                                                onChange={(val) => {
                                                                    this.setState({ prevOtp: otp, otp: val });
                                                                }}
                                                                value={otp}
                                                                prevOtp={prevOtp}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="text-center mt-4 mb-4">
                                                        <span className="reset-password-link">
                                                            Didn't receive OTP?
                                                            {
                                                                this.state.isResendDisabled ? <p>
                                                                    Resend OTP in: <span>{this.state.timeLeft} seconds</span>
                                                                </p> : <p className="btn-link text-danger" style={{ cursor: "pointer" }} onClick={this.onResendOtpSubmit}>
                                                                    Resend
                                                                </p>
                                                            }
                                                        </span>
                                                    </div>
                                                    <div className="form-group form-row mb-2">
                                                        <div className="col-md-2">

                                                            <button className="btn btn-primary" type="submit">Done</button>
                                                        </div>
                                                    </div>

                                                </form>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <ToastContainer />
                </div>

            </Fragment>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.token !== null,
        error: state.auth.error,
        successMsg: state.auth.successMsg,
        errorMsg: state.auth.errorMsg,
        resendotp: state.auth.resendotp,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onOtpVerify: (data, browserId) => dispatch(actionCreators.verifyOtp(data, browserId)),
        onRevertToInitialState: () => dispatch(actionCreators.reverToInitialState()),
        onResendOtp: (admin_id) => dispatch(actionCreators.resendotp(admin_id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(VerifyOtp));
