import React, { Component, Fragment } from "react";
import { Redirect, NavLink, Link } from "react-router-dom";
import { TabContent, TabPane, Nav, NavItem, UncontrolledTooltip, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import moment from "moment-timezone";
import { Pagination, PaginationItem, PaginationLink, NavLink as NavLinkTab } from 'reactstrap';
import { ToastContainer, toast } from "react-toastify";
import PaginationComponent from "react-reactstrap-pagination";
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
//import "bootstrap/dist/css/bootstrap.min.css";

import Breadcrumb from "../../components/common/breadcrumb";
import displayMobile from "../../components/ui-elements/display_mobile";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import AddPotentialMerchantModal from "./AddPotentialMerchantModal";
import AddCsvModal from "./AddCsvModal";
import "../../table.css";
import {DisappearedLoading} from "react-loadingg";
import CustomDropDown from "../merchants/customDrowpdown";
import { Info } from "react-feather";
import { BiMinus } from "react-icons/bi";
import { BsPlus } from "react-icons/bs";
import NewWindow from "react-new-window";
import { count } from "../merchants/smsCounter";
import SendSMSModal from "../merchants/sendSMSModal";

class PotentialMerchants extends Component {

    state = {
        potentialMerchants: [],
        potentialMerchantItem: null,
        tabIndex: 1,
        //activeTab: '1',
        activePage: 1,
        pageSize: 15,
        modal: false,
        csvmodal: false,
        send_templ_mail_id: 'select',
        send_to_mer_id: '',
        totalChrages: 0,
        mail_templates: null,
        send_mail_modal: false,
        send_merchant_modal: false,
        send_sms_modal: false,
        deleteModal: false,
        deleteSelectedModal: false,
        selectedIDs: [],
        selectedId: null,
        searchValue: null,
        searchedData: null,
        infoModal: false,
        comment: null,
        access_level: localStorage.getItem("accessLevel"),
        adminUser: JSON.parse(localStorage.getItem("user")),
        bulkMail: null,
        bulkSms: null,
        sms_templates: null,
        selectedCommunicateOption:'email',
        addedSMSForPromotion:'',
        userDataForPromotion: null,
        sms_message: "",
        sms_message_error: "",
        mobOptions:[],
        screenWidth:0,
        selectedRow:false,
        previewEmail: false,
        selectedMerchant:'',
        countries:[],
        countryState:[],
        selected_country:"+61",
        smsModalClick: false,
        selectedTab: "All",
    }

    toggleSMSModal = () => {
		this.setState((prevState) => {
			return {
				smsModalClick: !prevState.smsModalClick,
			};
		});
	};

    toggleTableCollapse = (selectedStoreId) => {

		this.setState({
			selectedRow: !this.state.selectedRow,
			selectedMerchant: selectedStoreId
		});
	}

    fetchCharges = () => {
		let postData = {
			all: true
		}
		client.post(api.fetchSMSCharges, postData, (error, response) => {
			if (!error) {
				if (!response.error) {
					let _ = response.result.data.map(val => {
						return { value: val.country, label: `${val.country} (+${val.country_code})`, country_code: `+${val.country_code}` }
					})
					this.setState({
						mobOptions: _,
						smsCharges: response.result.data
					});
				} else {
				}
			} else {
			}
		});
	}

    fetch_country_details = async (country_code) => {


        console.log(country_code)

        let postdata = {
            "country_code": country_code
        }
        let current = this;
        client.post(api.countries_list, postdata, async function (
            error,
            response
        ) {

            console.log(error,response)

            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {

                        if (!response.error) {
                            let country_data = response.result["data"];
                            console.log(country_data)
                            if (country_data) {
                                current.setState({ countryState: country_data.states })
                            }
                            // setcountrydata(response.result["data"]);
                        }
                    } catch (e) {
                        // alert(e)

                    }
                }
            }
        });
    }

    previewEmailComp = () => {
		let mailLayout = this.state.mail_templates?.filter(e => e._id == this.state.send_templ_mail_id)
		let fMail = mailLayout?.[0]?.templ_layout
		fMail = fMail?.replace(/\n/g, "")
		fMail = fMail?.replace(/\t/g, "")
		let replace_items_arr = {
			'[minisite_url]': window.location.hostname == "localhost" ? `http://localhost:3000/${this.state?.userDataForPromotion?.unique_id}` : window.location.hostname == "dm.admin.ipaypro.co" ? `https://dm.linky.biz/${this.state?.userDataForPromotion?.unique_id}` : window.location.hostname == "admin.ipaypro.co" ? `https://linky.biz/${this.state?.userDataForPromotion?.unique_id}` : ``,
			'[customer_name]': this.state?.userDataForPromotion?.userData?.name,
			'[mobile_no]': this.state?.userDataForPromotion?.mobile,
			'[email_id]': this.state?.userDataForPromotion?.userData?.email,
			'[business_name]': this.state?.userDataForPromotion?.business_name,
			'[first_name]': this.state?.userDataForPromotion?.name?.indexOf(" ")>0?this.state?.userDataForPromotion?.name?.slice(0,this.state?.userDataForPromotion?.name?.indexOf(" ")):this.state?.userDataForPromotion?.name,
		}
		// console.log(replace_items_arr, "replace_items_arr")
		// var templ_subject = mail_template_data.templ_subject;
		//console.log('mail str = '+mail_str);
		for (var key in replace_items_arr) {
			if (!replace_items_arr.hasOwnProperty(key)) {
				continue;
			}
			//mail_str = mail_str.replace(new RegExp(key, "g"), replace_items_arr[key]);
			// mail_str =  mail_str.replace(key, replace_items_arr[key]);
			fMail = fMail.split(key).join(replace_items_arr[key]);
			//   templ_subject = templ_subject.split(key).join(replace_items_arr[key]);
		}
		return (
			<NewWindow title="Email Preview" center="screen" onUnload={() => this.setState({ previewEmail: false })}>
				<div dangerouslySetInnerHTML={{ __html: fMail }} />
			</NewWindow>
		)
	}

    setStoreRefStatus = (data) => {


		let postdata = {
			merchant_id: data.storeData._id,
			storeRefData: data.storeRefStatus,
			user: this.state.adminUser.email
		};

		client.put(api.updateMerchantDetails, postdata, (error, response) => {
			if (!error) {
				if (!response.error) {
					// console.log(response);
					this.refreshPage();
				} else {
					setTimeout(() => {
						toast.error(response.message);
					}, 200);
				}
			} else {
				setTimeout(() => {
					toast.error("Internal error occured. Please contact support");
				}, 200);
			}
		});
	}

    componentDidMount() {
        this.getAllPotentialMerchants();
        this.getMailTemplates();
        this.getSmsTemplates();
        this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
        this.fetchCountries()
        this.fetch_country_details('AU')
        this.fetchCharges();
        this.getCurrencyData();

    }

    componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
	}

    fetchCountries = () => {
		let current = this;

        client.post(api.fetch_country, { show_all: true }, async function (error, response) {
          // console.log(response)
          if (!error) {
            if (typeof response.error !== "undefined") {
              try {
                if (!response.error) {
                    current.setState({  countries: response.result.data })
                }
              } catch {
    
              }
            }
          }
        });
      }

    handlePageChange = (pageNumber) => {
        this.setState({ activePage: pageNumber });
    }

    

    toggleDeleteSelectedModal = () => {
        this.setState(prevState => {
            return {
                ...prevState,
                deleteSelectedModal: !prevState.deleteSelectedModal
            }
        })
    }

    toggleModal = (item) => {
        this.setState(prevState => {
            return {
                ...prevState,
                modal: !prevState.modal,
                potentialMerchantItem:item?item:null
            }
        })
    }

    toggleCSVModal = () => {
        this.setState(prevState => {
            return {
                ...prevState,
                csvmodal: !prevState.csvmodal
            }
        })
    }

    getCurrencyData = async () => {



        try {
            client.get("api/exchangerate/USD", {}, (error, response) => {
                if (response) {
                    // setGetCurrData(response.conversion_rates)
                    this.setState({
                        exchangeData: response.conversion_rates
                    })
                }
            })
        } catch {

        }
    };

    calculateSMSCharges = () => {
		const { smsCharges, exchangeData, userDataForPromotion, sms_message, selected_country } = this.state;
		
        if (smsCharges.length && Object.keys(exchangeData).length && userDataForPromotion) {
			// console.log(smsCharges, userDataForPromotion, "smsCharges")
			let findCountryTotal = smsCharges.find(val => val.country_code == userDataForPromotion.country.phone_prefix.replace("+", "")).total
			let total_sms = count(sms_message)?.messages;
			findCountryTotal = findCountryTotal * total_sms;
			// let mobileRegex = countryData?.find(val => val.country_name == formData.mobprefix.value)?.phone_regex;
			// setGetMobileRegex(mobileRegex)
			let toCurrency = userDataForPromotion.country.currency_code;
			// console.log(userDataForPromotion.country, "userDataForPromotion.country")
			let findV = exchangeData[toCurrency];
			// console.log(findV, exchangeData, "QWERTG")
            findCountryTotal = findCountryTotal * findV
            // console.log(findCountryTotal, "findCountryTotal")
			this.setState({
				total_sms: total_sms,
				totalChrages: parseFloat(findCountryTotal)?.toFixed(2)
			})
		}
	}

    updateWindowDimensions = () => {
		this.setState({ screenWidth: window.innerWidth });
	}


    handle_country_select = async (e) => {
		this.setState({
			selected_country: e.target.value,
		});
	};

    setPageSize = (event) => {
        this.setState({ pageSize: event.target.value })
    }

    infoClick = (id) => {
        this.setState(prevState => {
            return {
                ...prevState,
                infoModal: !prevState.infoModal,
                selectedId: id
            }
        });
    }

    commentHandler = (event) => {
        const comment = event.target.value;
        this.setState(prevState => {
            return {
                ...prevState,
                comment: comment
            }
        })
    }

    toggleInfoModal = () => {
        this.setState(prevState => {
            return {
                ...prevState,
                infoModal: !prevState.infoModal
            }
        })
    }

    commentSubmitHandler = (event) => {
        event.preventDefault();
        const foundPotentialMerchant = this.state.potentialMerchants.find(el => {
            return el._id == this.state.selectedId
        });

        const postdata = { _id: this.state.selectedId, comment: { type: "normal", note: this.state.comment, user: this.state.adminUser.email } }

        client.post(api.update_potential_merchant_details, postdata, (error, response) => {
            if (!error) {
                if (!response.error) {
                    toast.success(response.message)
                    this.refreshPage();
                } else {
                    setTimeout(() => {
                        toast.error(response.message);
                    }, 200);
                }
            } else {
                setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                }, 200);
            }
        })
    }

    renderInfoModal = () => {
        const foundPotentialMerchant = this.state.potentialMerchants.find(el => {
            return el._id == this.state.selectedId
        })

        if (typeof foundPotentialMerchant.comment == "undefined") {
            foundPotentialMerchant.comment = [];
        }
        let foundPotentialMerchantComment = [...foundPotentialMerchant.comment].reverse();
        return (
            <Modal isOpen={this.state.infoModal} toggle={this.toggleInfoModal} className="modal-body" centered={true}>
                <h5 style={{ padding: "15px", margin: "0" }}>Comments</h5>

                <ModalBody style={{ paddingTop: "0", paddingBottom: "0", maxHeight: 'calc(100vh - 370px)', overflowY: 'auto' }}>
                    <div className="card-body chat-box" style={{ paddingTop: "0", paddingBottom: "0" }}>
                        <div className="chat-right-aside bitcoin-chat">
                            <div className="chat">
                                <div className="chat-history chat-msg-box custom-scrollbar" style={{ padding: "0", margin: "0" }}>
                                    <ul style={{ margin: "0" }}>
                                        {foundPotentialMerchantComment.length != 0 ? foundPotentialMerchantComment.map(el => {
                                            return (<li style={{ margin: "0" }}>
                                                <div className="message my-message " style={{ marginBottom: "10px", paddingTop: "10px", paddingBottom: "10px" }}>
                                                    <p>{el.user}<span className="message-data-time pull-right">{this.render_date(el.date)}</span> </p>
                                                    {el.type == "normal" ? null : <p style={{ fontSize: "0.9rem" }}>Type : {el.type}</p>}
                                                    {el.note}
                                                </div>
                                            </li>)
                                        }) : <p>No comment added!</p>}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                </ModalBody>
                <ModalFooter style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                    <div className="col-lg-12">
                        <form className="theme-form" >
                            <div className="form-group mb-0 col-12">
                                <label>Comment:</label>
                                <textarea className="form-control" required rows="3"
                                    onChange={(event) => this.commentHandler(event)}></textarea>
                            </div>
                            <div style={{ padding: "15px 0 0 15px" }}>
                                <Button color="default" onClick={this.toggleInfoModal} style={{ marginRight: "15px" }}>Cancel</Button>
                                <Button color="primary" type="submit" name="comment"
                                    onClick={(event) => this.commentSubmitHandler(event)}>Submit</Button>

                            </div>
                        </form>
                    </div>
                </ModalFooter>

            </Modal>
        );
    }



    render_date = (add_date) => {

        if (typeof (add_date) === "undefined") {
            return "";
        }

        const convert_date = new Date(add_date);
        const added_date = moment.tz(convert_date, "Australia/Sydney").format("DD/MM/YYYY HH:mm");
        return added_date;
    }
    handle_template_select = async (e) => {
        this.setState({
            send_templ_mail_id: e.target.value
        });
        //console.log(this.state.send_templ_mail_id);
    }

    set_user_mail_id(merchant_id) {
        this.setState({ send_to_mer_id: merchant_id, bulkMail: false });
    }

    set_user_mail_id2(merchant) {
        this.setState({ send_to_mer_id: merchant?._id, bulkMail: false,userDataForPromotion:merchant });
    }

    send_mail_toggle() {
        this.setState({ send_mail_modal: !(this.state.send_mail_modal) });
    }
    send_merchant_mail_toggle() {
        this.setState({ send_merchant_modal: !(this.state.send_merchant_modal) });
    }

    send_sms_toggle = () => {
        this.setState({ send_sms_modal: !this.state.send_sms_modal })
    }

    bulkMailClick = () => {
        const { tabIndex, selectedIDs, potentialMerchants } = this.state;
        let selectedMerchants = null;
        if (tabIndex == 0) {
            selectedMerchants = potentialMerchants.filter(el => {
                return el.type == 1
            }).map(item => {
                return item._id
            })
        } else if (tabIndex == 1) {
            selectedMerchants = potentialMerchants.filter(el => {
                return el.type == 2
            }).map(item => {
                return item._id
            })
        } else if (tabIndex == 2) {
            selectedMerchants = potentialMerchants.filter(el => {
                return el.type == 3
            }).map(item => {
                return item._id
            })
        } else if (tabIndex == 3) {
            selectedMerchants = potentialMerchants.filter(el => {
                return el.type == 0
            }).map(item => {
                return item._id
            })
        } else if (tabIndex == 4) {
            selectedMerchants = potentialMerchants.map(el => {
                return el._id
            })
        }
        this.setState({ bulkMail: true, selectedIDs: selectedMerchants });
    }

    bulkSmsClick = () => {
        const { tabIndex, selectedIDs, potentialMerchants } = this.state;
        let selectedMerchants = null;
        if (tabIndex == 0) {
            selectedMerchants = potentialMerchants.filter(el => {
                return el.type == 1
            }).map(item => {
                return item._id
            })
        } else if (tabIndex == 1) {
            selectedMerchants = potentialMerchants.filter(el => {
                return el.type == 2
            }).map(item => {
                return item._id
            })
        } else if (tabIndex == 2) {
            selectedMerchants = potentialMerchants.filter(el => {
                return el.type == 3
            }).map(item => {
                return item._id
            })
        } else if (tabIndex == 3) {
            selectedMerchants = potentialMerchants.filter(el => {
                return el.type == 0
            }).map(item => {
                return item._id
            })
        } else if (tabIndex == 4) {
            selectedMerchants = potentialMerchants.map(el => {
                return el._id
            })
        }
        this.setState({ bulkSms: true, selectedIDs: selectedMerchants });
    }

    getMailTemplates = async () => {
        let postdata = { templ_user_type: 'merchant', auto_reminder: false };
        let current = this;
        current.setState({ loading: true });

        client.post(api.get_all_mail_templates, postdata, async function (error, response) {
            if (!error) {
                //alert(JSON.stringify(response));
                //   return false;
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            current.setState({ mail_templates: response.result.data });

                        } else {
                            toast.error(response.message);
                        }
                    } catch (e) {
                        toast.error("Internal error occured. Please contact support");
                    }
                } else {
                    toast.error("Internal error occured. Please contact support");
                }
            } else {
                toast.error("Internal error occured. Please contact support");
                // _showToast.error("Internal error occured. Please contact support.");
            }
            current.setState({ loading: false });
        });
    };

    getSmsTemplates = async () => {
        let postdata = { templ_user_type: 'merchant', auto_reminder: false };
        let current = this;
        current.setState({ loading: true });

        client.post(api.get_all_sms_templates, postdata, async function (error, response) {
            if (!error) {
                //alert(JSON.stringify(response));
                //   return false;
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            current.setState({ sms_templates: response.result.data });

                        } else {
                            toast.error(response.message);
                        }
                    } catch (e) {
                        toast.error("Internal error occured. Please contact support");
                    }
                } else {
                    toast.error("Internal error occured. Please contact support");
                }
            } else {
                toast.error("Internal error occured. Please contact support");
                // _showToast.error("Internal error occured. Please contact support.");
            }
            current.setState({ loading: false });
        });
    };

    getAllPotentialMerchants() {
        let postdata = {};
        // current.setState({ loading: true });
        client.post(api.all_potential_merchants, postdata, (error, response) => {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            this.setState({ potentialMerchants: response.result.data });
                            //(response);

                        } else {
                            setTimeout(() => {
                                toast.error(response.message);
                            }, 200);
                        }
                    } catch (e) {
                        setTimeout(() => {
                            toast.error("Internal error occured. Please contact support");
                        }, 200);
                    }
                } else {
                    setTimeout(() => {
                        toast.error("Internal error occured. Please contact support");
                    }, 200);

                }
            } else {
                setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                }, 200);
            }
            //   current.setState({ loading: false });
        });
    };

    handle_send_mail_submit = async (e) => {
        e.preventDefault();
        let current = this;
        current.setState({ loading: true });
        let data = null;
        let apiPath = null

        if (this.state.bulkMail == false) {
            data = { templ_id: this.state.send_templ_mail_id, user_id: this.state.send_to_mer_id };
            apiPath = api.send_mail_to_user
        } else if (this.state.bulkMail == true) {
            data = { templ_id: this.state.send_templ_mail_id, users_id: this.state.selectedIDs }
            apiPath = api.send_bulk_mail
        }

        if (this.state.send_templ_mail_id == 'select' || this.state.send_templ_mail_id == '') {
            toast.error("Please Select  a Template");
            return false;
        }

        else if (data !== '') {
            console.log(apiPath)
            client.post(apiPath, data, async function (error, response) {
                if (!error) {
                    if (typeof response.error !== "undefined") {
                        try {
                            if (!response.error) {
                                current.refreshPage()
                                toast.success("Mail Sent Successfully");

                            } else {
                                toast.error(response.message);
                            }
                        } catch (err) {
                            toast.error(err.message);
                        }
                    } else {
                        toast.error("Internal error occured. Please contact support 2");
                    }
                } else {
                    toast.error("Internal error occured. Please contact support 3");
                }
                current.setState({ loading: false });
            });
        }
    }

    onSendMailToMerchant = async (e) => {
		e.preventDefault();

		this.setState({ loading: true });
		let data = null;
		let apiPath = null;

		if (this.state.bulkMail == false && this.state.selectedCommunicateOption == "email") {
			data = {
				templ_id: this.state.send_templ_mail_id,
				user_id: this.state.send_to_mer_id,
				addedEmailForPromotion: this.state.addedEmailForPromotion,
				sendToCustomEmail: this.state.sendToCustomEmail,
				sendToStoreEmail: this.state.sendToStoreEmail,
				sendToUserEmail: this.state.sendToUserEmail,
				storeEmail: this.state?.userDataForPromotion?.email,
				userEmail: this.state?.userDataForPromotion?.userData?.email,
				adminEmail: this.state?.adminUser?.email,
				replace_items_arr: {
					'[minisite_url]': window.location.hostname == "localhost" ? `http://localhost:3000/${this.state?.userDataForPromotion?.unique_id}` : window.location.hostname == "dm.admin.ipaypro.co" ? `https://dm.linky.biz/${this.state?.userDataForPromotion?.unique_id}` : window.location.hostname == "admin.ipaypro.co" ? `https://linky.biz/${this.state?.userDataForPromotion?.unique_id}` : ``,
					'[customer_name]': this.state?.userDataForPromotion?.userData?.name,
					'[mobile_no]': this.state?.userDataForPromotion?.mobile,
					'[email_id]': this.state?.userDataForPromotion?.userData?.email,
					'[business_name]': this.state?.userDataForPromotion?.business_name,
					'[first_name]': this.state?.userDataForPromotion?.name?.indexOf(" ")>0?this.state?.userDataForPromotion?.name?.slice(0,this.state?.userDataForPromotion?.name?.indexOf(" ")):this.state?.userDataForPromotion?.name,
				}
			};
			apiPath = api.send_mail_to_user;
		} else if (this.state.bulkMail == false && this.state.selectedCommunicateOption == "sms") {
			data = {
				user_id: this.state.send_to_mer_id,
				addedSMSForPromotion: this.state.addedSMSForPromotion,
				sendToCustomSMS: this.state.sendToCustomSMS,
				sendToStoreSMS: this.state.sendToStoreSMS,
				storeSMS: {
					mobile: this.state?.userDataForPromotion?.mobile,
					phone_prefix: this.state?.userDataForPromotion?.country?.phone_prefix
				},
				selected_country: this.state.selected_country,
				sms_message: this.state.sms_message,
				totalCharges: this.state.totalChrages,
				adminEmail: this.state?.adminUser?.email,
            };
            
			apiPath = api.send_sms_to_user;
		} else if (this.state.bulkMail == true) {
			data = { templ_id: this.state.send_templ_mail_id, users_id: this.state.selectedIDs };
			apiPath = api.send_bulk_mail;
		}

		if (this.state.selectedCommunicateOption == "email" && this.state.send_templ_mail_id == "select" || this.state.send_templ_mail_id == "") {
			toast.error("Please Select  a Template");
			return false;
		} else if (this.state.selectedCommunicateOption == "email" && this.state.sendToStoreEmail == false && this.state.sendToUserEmail == false && this.state.sendToCustomEmail == false) {
			toast.error("Please Select atleast one email option");
			return false;
		} else if (this.state.selectedCommunicateOption == "email" && this.state.sendToCustomEmail == true && this.state.addedEmailForPromotion == "") {
			this.setState({ email_message_error: "Enter email id" });
			return false;
		} else if (this.state.selectedCommunicateOption == "email" && this.state.sendToCustomEmail == true && this.state.addedEmailForPromotion != "" && !this.state.addedEmailForPromotion.match(/^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/)) {
			this.setState({ email_message_error: "Enter valid email id" });
			return false;
		} else if (this.state.selectedCommunicateOption == "sms" && this.state.sendToStoreSMS == false && this.state.sendToCustomSMS == false) {
			toast.error("Please Select atleast one sms option");
			return false;
		} else if (this.state.selectedCommunicateOption == "sms" && this.state.sendToCustomSMS == true && this.state.addedSMSForPromotion == "") {
			this.setState({ sms_message_error: "Enter phone number" });
			return false;
		} else if (this.state.selectedCommunicateOption == "sms" && this.state.sms_message == "") {
			toast.error("SMS message is empty");
			return false;
		} else if (data !== "") {
			// console.log(apiPath);
			client.post(apiPath, data, async (error, response) => {
				if (!error) {
					if (typeof response.error !== "undefined") {
						try {
							if (!response.error) {
								this.refreshPage();
								if (this.state.selectedCommunicateOption == "sms") {
									toast.success("SMS Sent Successfully");
								} else {
									toast.success("Mail Sent Successfully");
								}
								this.setState({ send_mail_modal: false });
							} else {
								toast.error(response.message);
							}
						} catch (err) {
							toast.error(err.message);
						}
					} else {
						toast.error("Internal error occured. Please contact support 2");
					}
				} else {
					toast.error("Internal error occured. Please contact support 3");
				}
				this.setState({ loading: false });
			});
		}
	};

    refreshPage() {
        window.location.reload(false);
    }

    deleteClick = (id) => {
        this.setState(prevState => {
            return {
                ...prevState,
                deleteModal: !prevState.deleteModal,
                selectedId: id
            }
        });
    }

    deleteSelectedButtonClick = () => {
        this.setState(prevState => {
            return {
                ...prevState,
                deleteSelectedModal: !prevState.deleteSelectedModal,
            }
        });
    }

    deleteSelectedClick = (event, id) => {
        console.log(event.target.checked, id);

        if (event.target.checked) {
            this.setState(prevState => {
                return {
                    ...prevState,
                    selectedIDs: [...prevState.selectedIDs, id]
                }
            });

        } else {
            if (this.state.selectedIDs.includes(id)) {
                const idsToKeep = this.state.selectedIDs.filter(el => {
                    return el != id
                })
                this.setState(prevState => {
                    return {
                        ...prevState,
                        selectedIDs: idsToKeep
                    }
                })
            } else {
                return;
            }
        }
    }

    toggleDeleteModal = () => {
        this.setState(prevState => {
            return {
                ...prevState,
                deleteModal: !prevState.deleteModal
            }
        })
    }

    deletePotentialMerchantHandler = (event, id) => {
        event.preventDefault()
        const postData = { id: id };

        client.post(api.delete_potential_merchant, postData, (error, response) => {
            if (!error) {
                if (!response.error) {
                    console.log(response);
                    this.refreshPage();
                } else {
                    setTimeout(() => {
                        toast.error(response.message);
                    }, 200);
                }
            } else {
                setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                }, 200);
            }
        })
    }

    filterWay(data, label) {
        if (label === "All") {
            return data;
        } else if (label === "Lead") {
            return data?.filter(e => e?.storeRefStatus?.value == 1);
        } else if (label === "Contacted") {
            return data?.filter(e => e?.storeRefStatus?.value == 2);
        } else if (label === "Follow-up") {
            return data?.filter(e => e?.storeRefStatus?.value == 3);
        } else if (label === "Lost") {
            return data?.filter(e => e?.storeRefStatus?.value == 4);
        } else if (label === "Won") {
            return data?.filter(e => e?.storeRefStatus?.value == 5);
        } else if (label === "Archived") {
            return data?.filter(e => e?.storeRefStatus?.value == 6);
        }
    }

    renderDeleteModal = (id) => {
        return (
            <Modal isOpen={this.state.deleteModal} toggle={this.toggleDeleteModal} className="modal-body" centered={true}>
                <form className="theme-form" noValidate="" onSubmit={(event) => this.deletePotentialMerchantHandler(event, id)}>
                    <ModalBody>
                        <h4>Do you really want to delete this Potential Merchant ?</h4>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit"  >Yes, Please!</Button>
                        <Button color="default" onClick={this.toggleDeleteModal}>No</Button>
                    </ModalFooter>
                </form>
            </Modal>

        );
    }

    renderAllTable = () => {
        const { potentialMerchants, searchedData, access_level } = this.state
        let filteredPotentialMerchants = [];
        if (searchedData == null) {
            filteredPotentialMerchants = potentialMerchants
        } else {
            filteredPotentialMerchants = [...searchedData]

        }

        console.log(filteredPotentialMerchants)

        return (
            <Fragment>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: '5px', marginTop: '12px' }}>
                    <div >
                        <form onSubmit={(event) => this.handleSearchSubmit(event)}>
                            <div >
                                <input style={{
                                    border: "#dfe8f1 solid 1px",
                                    borderRadius: "3px",
                                    padding: "10px",
                                    fontSize: "0.75rem",
                                    outlineColor: '#dfe8f1'
                                }}
                                    type="text"
                                    placeholder="Search"
                                    onChange={this.onSearchChangeHandler} />
                            </div>
                        </form>
                    </div>
                    <div>
                        <ul className="tabs tab-title" style={{ borderBottom: "none" }}>
                            {
                                ["All", "Lead", "Contacted", "Follow-up", "Lost", "Won", "Archived"].map(ee => {
                                    return (
                                        <li
                                            className={`react-tabs__tab ${ee === this.state.selectedTab ? "react-tabs__tab--selected" : ""}`}
                                            style={{ marginTop: "0.5rem", display: "flex", alignItems: "center" }}
                                            onClick={() => this.setState({
                                                selectedTab: ee,
                                                activePage: 1,
                                                pageSize: 15,
                                            })}
                                        >
                                            {ee}
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <select className="custom-select" style={{ width: "35%", marginRight: "7px", fontSize: "0.63rem" }} onChange={this.setPageSize} value={this.state.pageSize}>
                            <option value="15">15</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="75">75</option>
                            <option value="100">100</option>
                        </select>
                        <span style={{ fontSize: "0.63rem" }}>Records per Page</span>
                    </div>
                </div>

                {this.filterWay(filteredPotentialMerchants, this.state.selectedTab).length > 0 ? <div className="table-responsive">
                    <table className="table table-border-horizontal" >
                        <thead>
                            <tr style={{background: '#f9fafe'}}>
                                <th scope="col" style={{ width: "4%" }}>#</th>
                                <th scope="col" style={{ width: "9%" }}>
                                    <p>Date</p>
                                    <p>Time</p>
                                </th>
                                {/* <th scope="col" style={{ width: "14%" }}>
                                    <p>Landline</p>
                                    <p>Mobile</p>
                                </th> */}
                                <th scope="col" style={{ minWidth: "16vw" }}>Merchant Details</th>
                                <th scope="col" style={{ width: "17%" }}>Status</th>
                                <th scope="col" style={{ maxWidth: "8vw" }}>Updated by</th>
                                <th scope="col" style={{ maxWidth: "8vw" }}>Notes</th>
                                {access_level != 1 && <th scope="col" style={{ width: "11%" }}>Action</th>
                                }
                                { this.state.screenWidth > 1440 && <th scope="col">Website</th>}
                                { this.state.screenWidth > 1440 && <th scope="col">Source</th>}
																	{ this.state.screenWidth > 1440 && <th scope="col">Address</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {this.filterWay(filteredPotentialMerchants, this.state.selectedTab).slice((this.state.activePage - 1) * this.state.pageSize, (this.state.activePage) * this.state.pageSize).map((item, index) => {
                                return (
                                    <>
                                    <tr key={item._id}>
                                        <td scope=" row" style={{width:'4%'}} key={item._id}><div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
								<p className="mb-0">{(this.state.activePage - 1) * this.state.pageSize + index + 1}</p>
								{
									this.state.screenWidth <= 1440 && <>
										<div
											className="d-flex align-items-center justify-content-center"
											style={{
												border: "1px solid #ccc",
												borderRadius: "50%",
												height: "21px",
												width: "21px",
											}}
											onClick={() => { this.toggleTableCollapse(item._id) }}
										>
											<div className="d-flex align-items-center justify-content-center"
												style={{
													borderRadius: "50%",
													height: "15px",
													width: "15px",
													backgroundColor: (this.state.selectedRow && this.state.selectedMerchant == item._id) ? "red" : "green"
												}}
											>
												{(this.state.selectedRow && this.state.selectedMerchant == item._id) ? <BiMinus style={{ color: "#fff", width: '15px', height: '15px' }} /> : <BsPlus style={{ color: "#fff", width: '15px', height: '15px' }} />}

											</div>
										</div>
									</>
								}
							</div></td>
                                        <td style={{ minWidth:'5vw' }}>{this.render_date(item.added_date)}</td>
                                        
                                        
                                        {/* <td style={{ width: "14%" }}>
                                            {<span>{item.landline ? <span>{displayMobile(item.landline)}</span> : <span>N.A.</span>}<br /></span>}
                                            {item.mobile ? <span>{displayMobile(item.mobile)}</span> : (<span >N.A</span>)}

                                        </td> */}
                                        <td style={{ minWidth: "16vw" }}>
                                            Full Name: {
                                                item.name ?
                                                // <NavLink style={{ marginRight: "10px", fontSize: "1rem" }}
                                                //     to={`/potential-merchants/details/${item._id}`}>
                                                //     {item.name}
                                                // </NavLink>
                                               <span> {item.name}</span>
                                                   
                                                
                                                : <span >N.A</span>
                                            
                                            }
                                            <br/>   
                                            
                                            Username / Storename: {item.business_name?item.business_name:'N.A'}
                                            <br/>
                                            Mobile: {item?.country?.phone_prefix}{item.mobile}
                                            <br/>
                                            Email: {item.email ? item.email : (<span >N.A</span>)}<br/>
                                            Landline: {item.landline ? item.landline : (<span >N.A</span>)}
                                            </td>
                                            
                                        <td style={{ width: "17%" }}><div className="form__form-group my-3">
								<div className="form__form-group-field">
									<CustomDropDown
										name={`${item._id}.status`}
										defaultValue={item?.storeRefStatus ? item.storeRefStatus : { value: 1, label: 'LEAD' }}
										onChange={(event) => {

											let postData = { storeData: item, storeRefStatus: event };
											this.setStoreRefStatus(postData);

										}}
										options={[
											{ value: 1, label: 'LEAD' },
											{ value: 2, label: 'CONTACTED' },
											{ value: 3, label: 'FOLLOW-UP' },
											{ value: 4, label: 'LOST' },
											{ value: 5, label: 'WON' },
											{ value: 6, label: 'ARCHIVE' },
										]}
										user={item}
									/>
								</div>
								<div 
									className="mt-2"
									onClick={() => {
										this.send_merchant_mail_toggle();
										this.set_user_mail_id2(item);
									}}
									style={{color: '#327ebc'}}
								>
									Send email/sms
								</div>
							</div></td>
                                       
                                        <td style={{ maxWidth: "8vw" }}> 
							{typeof item.comment != "undefined" && item.comment.length > 0 ? (
								<>
									<p style={{ fontSize: "12px" }}>{item.comment[item.comment.length - 1].user}</p>
									<p style={{ fontSize: "12px" }}>{this.render_date(item.comment[item.comment.length - 1].date)}</p>
								</>
							) : (
								"N.A"
							)}
						</td>
                                       
                                        { <td style={{ maxWidth: "8vw" }}>
                                            {typeof item?.comment != "undefined" && item?.comment?.length > 0 ? (
                                                <p style={{ height: "70px", minWidth: "", overflow: "auto", fontSize: "12px" }}>{item?.comment[item?.comment?.length - 1].note?.replace(',',", ")}</p>
                                            ) : (
                                                "N.A"
                                            )}
                                        </td>}
                                        {access_level != 1 && <td style={{ width: "11%" }}>
                                            {/* <span style={{ marginRight: "10px", fontSize: "14px" }}>
                                                <i className="fa fa-paper-plane" style={{ color: '#327ebc', title: 'View Details' }} onClick={() => {
                                                    this.send_mail_toggle();
                                                    this.set_user_mail_id(item._id)
                                                }} ></i>
                                            </span> */}
                                            {/* {access_level == 3 && <span style={{ marginRight: "10px", fontSize: "0.75rem" }}
                                                onClick={() => this.deleteClick(item._id)}>
                                                <i style={{ color: "red" }} id="delete" title="delete" className="fa fa-trash-o"></i>
                                            </span>} */}
                                            <span style={{ marginRight: "10px", fontSize: "14px" }}
                                                onClick={() => this.infoClick(item._id)}>
                                                <i className="fa fa-list-alt"
                                                    style={{ color: "black" }}></i>
                                            </span>
                                            <span style={{ marginRight: "10px", fontSize: "14px" }}
                                                onClick={() => this.toggleModal(item)}>
                                                <i className="fa fa-edit"
                                                    style={{ color: '#327ebc'}}></i>
                                            </span>
                                            {access_level == 3 && <span>
                                                <input type="checkbox"
                                                    onClick={(event) => this.deleteSelectedClick(event, item._id)}
                                                />
                                            </span>}
                                        </td>
                                        
                                        }

                                        {this.state.screenWidth > 1440 &&  <td style={{ minWidth: "8vw",wordBreak:'break-word' }}>{item.website ? item.website : (<span >N.A</span>)}</td>}
                                        {this.state.screenWidth > 1440 &&  <td style={{ width: "15%" }}>{item.source ? item.source : (<span >N.A</span>)}</td>}
                                        {this.state.screenWidth > 1440 &&  <td style={{ minWidth: "10vw",padding:'12px' }}>
                                            {item.street_name && item.street_name}
                                            {item.address && ', '+item.address}
                                            {item.postcode && ', '+item.postcode}
                                            {item.suburb && ', '+item.suburb}
                                            {item.state && ', '+item.state}
                                            {this.state.countries?.filter(elem=>elem.country_code==item.country?.code)?.length>0 && ', '+this.state.countries?.filter(elem=>elem.country_code==item.country?.code)[0]?.country_name}
                                            </td>}
                                    </tr>

                                    {(this.state.selectedRow && this.state.selectedMerchant == item._id) && <>
					<tr>
						<td colSpan={12} >
							<table className="table table-border-horizontal" style={{ fontSize: "0.75rem", margin: this.state.screenWidth <= 480 ? "0.5rem" : "0.5rem 2.5rem", width: this.state.screenWidth <= 480 ? "100%" : "30%" }}>
								<thead>
                                <tr style={{ background: '#f9fafe' }}>

                                { this.state.screenWidth <= 1440 && <th scope="col">Website</th>}
                                { this.state.screenWidth <= 1440 && <th scope="col">Source</th>}
																	{ this.state.screenWidth <= 1440 && <th scope="col">Address</th>}

                                    </tr>




								</thead>

								<tbody>
                                    {this.state.screenWidth <= 1440 &&

                                    
                                <tr key={item._id}>

<td style={{ width: "15%",padding:'12px' }}>{item.website ? item.website : (<span >N.A</span>)}</td>
<td style={{ width: "15%",padding:'12px' }}>{item.source ? item.source : (<span >N.A</span>)}</td>

                                
                                        <td style={{ width: "10vw",padding:'12px' }}>
                                            {item.street_name && item.street_name}
                                            {item.address && ', '+item.address}
                                            {item.postcode && ', '+item.postcode}
                                            {item.suburb && ', '+item.suburb}
                                            {item.state && ', '+item.state}
                                            {this.state.countries?.filter(elem=>elem.country_code==item.country?.code)?.length>0 && ', '+this.state.countries?.filter(elem=>elem.country_code==item.country?.code)[0]?.country_name}
                                            </td>

                                    </tr>
                            }
								</tbody>
							</table>
						</td>
					</tr>

				</>}
                                    
                                    </>)
                            })}
                        </tbody>
                    </table>
                    <div class="mt-3" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        {(this.state.activePage) * this.state.pageSize > this.filterWay(filteredPotentialMerchants, this.state.selectedTab).length ?
                            <p style={{ textAlign: "right", fontSize: "0.63rem" }}>Showing {((this.state.activePage - 1) * this.state.pageSize) + 1} to {this.filterWay(filteredPotentialMerchants, this.state.selectedTab).length} of {this.filterWay(filteredPotentialMerchants, this.state.selectedTab).length} entries</p> :
                            <p style={{ textAlign: "right", fontSize: "0.63rem" }}>Showing {((this.state.activePage - 1) * this.state.pageSize) + 1} to {(this.state.activePage) * this.state.pageSize} of {this.filterWay(filteredPotentialMerchants, this.state.selectedTab).length} entries</p>
                        }
                        
                        <PaginationComponent
                            totalItems={this.filterWay(filteredPotentialMerchants, this.state.selectedTab).length}
                            pageSize={this.state.pageSize}
                            onSelect={this.handlePageChange} />
                        
                        <SendSMSModal smsModalClick={this.state.smsModalClick} toggleSMSModal={this.toggleSMSModal}/>

                    </div>
                </div> : <div className="mt-4 text-center">No data!</div>}
            </Fragment>
        );
    }

    renderTable = (type) => {
        const { potentialMerchants, searchedData, access_level } = this.state;
        let filteredPotentialMerchants = [];

        if (searchedData != null) {
            filteredPotentialMerchants = searchedData
        } else if (type == 0) {
            filteredPotentialMerchants = potentialMerchants.filter(el => {
                return el.type == 0
            });
        } else if (type == 1) {
            filteredPotentialMerchants = potentialMerchants.filter(el => {
                return el.type == 1
            });
        } else if (type == 2) {
            filteredPotentialMerchants = potentialMerchants.filter(el => {
                return el.type == 2
            });
        } else if (type == 3) {
            filteredPotentialMerchants = potentialMerchants.filter(el => {
                return el.type == 3
            });
        }


        return (
            <Fragment>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: '5px', marginTop: '12px' }}>
                    <div >
                        <form onSubmit={(event) => this.handleSearchSubmit(event)}>
                            <div >
                                <input style={{
                                    border: "#dfe8f1 solid 1px",
                                    borderRadius: "3px",
                                    padding: "10px",
                                    fontSize: "0.75rem",
                                    outlineColor: '#dfe8f1'
                                }}
                                    type="text"
                                    placeholder="Search"
                                    onChange={this.onSearchChangeHandler} />
                            </div>
                        </form>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <select className="custom-select" style={{ width: "35%", fontSize: "0.63rem", marginRight: "7px" }} onChange={this.setPageSize} value={this.state.pageSize}>
                            <option value="15">15</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="75">75</option>
                            <option value="100">100</option>
                        </select>
                        <span style={{ fontSize: "0.63rem" }}>Records per Page</span>
                    </div> 
                </div>

                <div className="table-responsive">
                    <table className="table table-border-horizontal" >
                        <thead>
                            <tr style={{background: '#f9fafe'}}>
                                <th scope="col" style={{ width: "4%" }}>#</th>
                                <th scope="col" style={{ width: "9%" }}>
                                    <p>Date</p>
                                    <p>Time</p></th>
                                <th scope="col" style={{ width: "14%" }}>Business Name</th>
                                <th scope="col" style={{ width: "14%" }}>Manager / Owner Name</th>
                                <th scope="col" style={{ width: "14%" }}>
                                    <p>Landline</p>
                                    <p>Mobile</p></th>
                                <th scope="col" style={{ width: "17%" }}>Email</th>
                                <th scope="col" style={{ width: "17%" }}>Status</th>
                                <th scope="col" style={{ width: "15%" }}>Address</th>
                                {access_level != 1 && <th scope="col" style={{ width: "11%" }}>Action</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {filteredPotentialMerchants.slice((this.state.activePage - 1) * this.state.pageSize, (this.state.activePage) * this.state.pageSize).map((item, index) => {
                                return (
                                    <tr key={item._id}>
                                        <th scope=" row" style={{ width: "6%" }}>{((this.state.activePage - 1) * this.state.pageSize) + index + 1}</th>
                                        <td style={{ width: "9%" }}>{this.render_date(item.added_date)}</td>
                                        {/* <td style={{ width: "12%" }}>{item.business_name ? item.business_name : (<span >N.A</span>)}</td> */}

                                        <td style={{ width: "14%" }}>{item.business_name ?
                                            // <NavLink
                                            //     to={`/potential-merchants/details/${item._id}`}>
                                            //     {item.business_name}
                                            // </NavLink>
                                            <NavLink style={{ marginRight: "10px", fontSize: "14px" }}
                                                to={`/merchants/merchantDetails/${item._id}`}>
                                                {item.business_name}
                                            </NavLink>
                                            : (<span >N.A</span>)}</td>

                                        <td style={{ width: "14%" }}>{item.name ?
                                            // <NavLink
                                            //     to={`/potential-merchants/details/${item._id}`}>
                                            //     {item.name}
                                            // </NavLink>
                                            <NavLink style={{ marginRight: "10px", fontSize: "14px" }}
                                                to={`/merchants/merchantDetails/${item._id}`}>
                                                {item.name}
                                            </NavLink>
                                            : (<span >N.A</span>)}</td>
                                        <td style={{ width: "14%" }}>
                                            {<span>{item.landline ? <span>{displayMobile(item.landline)}</span> : <span>N.A.</span>}<br /></span>}
                                            {item.mobile ? <span>{displayMobile(item.mobile)}</span> : (<span >N.A</span>)}
                                        </td>
                                        <td style={{ width: "17%" }}>{item.email ? item.email : (<span >N.A</span>)}</td>
                                        <td style={{ width: "15%" }}>{item.address ? item.address : (<span >N.A</span>)}</td>
                                        {access_level != 1 && <td style={{ width: "11%" }}>
                                            <span style={{ marginRight: "10px", fontSize: "14px" }}>
                                                <i className="fa fa-paper-plane" style={{ color: '#327ebc', title: 'View Details' }} onClick={() => {
                                                    this.send_mail_toggle();
                                                    this.set_user_mail_id(item._id)
                                                }} ></i>
                                            </span>
                                            {/* {access_level == 3 && <span style={{ marginRight: "10px", fontSize: "0.75rem" }}
                                                onClick={() => this.deleteClick(item._id)}>
                                                <i style={{ color: "red" }} id="delete" title="delete" className="fa fa-trash-o"></i>
                                            </span>} */}
                                            <span style={{ marginRight: "10px", fontSize: "0.75rem" }}
                                                onClick={() => this.infoClick(item._id)}>
                                                <i className="fa fa-list-alt"
                                                    style={{ color: "black" }}></i>
                                            </span>
                                            {access_level == 3 && <span >
                                                <input type="checkbox"
                                                    onClick={(event) => this.deleteSelectedClick(event, item._id)}
                                                />
                                            </span>}
                                        </td>}
                                    </tr>)
                            })}
                            
                        </tbody>
                    </table>
                    <div class="mt-3" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        {(this.state.activePage) * this.state.pageSize > filteredPotentialMerchants.length ?
                            <p style={{ textAlign: "right", fontSize: "0.63rem" }}>Showing {((this.state.activePage - 1) * this.state.pageSize) + 1} to {filteredPotentialMerchants.length} of {filteredPotentialMerchants.length} entries</p> :
                            <p style={{ textAlign: "right", fontSize: "0.63rem" }}>Showing {((this.state.activePage - 1) * this.state.pageSize) + 1} to {(this.state.activePage) * this.state.pageSize} of {filteredPotentialMerchants.length} entries</p>
                        }
                        
                        <PaginationComponent
                            totalItems={filteredPotentialMerchants.length}
                            pageSize={this.state.pageSize}
                            onSelect={this.handlePageChange} />
                    </div>
                </div>
            </Fragment>
        );

    }

    renderDeleteSelectedModal = () => {
        return (
            <Modal isOpen={this.state.deleteSelectedModal} toggle={this.toggleDeleteSelectedModal} className="modal-body" centered={true}>
                <form className="theme-form" noValidate="" onSubmit={this.deleteSelectedHandler}>
                    <ModalBody>
                        <h4>Do you really want to delete these potential Merchants ?</h4>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit"  >Yes</Button>
                        <Button color="default" onClick={this.toggleDeleteSelectedModal}>No</Button>
                    </ModalFooter>
                </form>
            </Modal>
        );
    }

    deleteSelectedHandler = (event) => {
        event.preventDefault();
        const postData = { selectedIDs: this.state.selectedIDs }
        client.post(api.delete_selected_potential_merchant, postData, (error, response) => {
            if (!error) {
                if (!response.error) {
                    toast.success(response.message)
                    this.refreshPage();


                } else {
                    setTimeout(() => {
                        toast.error(response.message);
                    }, 200);
                }
            } else {
                setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                }, 200);
            }
        })

    }

    onSearchChangeHandler = (event) => {
        this.setState({ searchValue: event.target.value })
    }

    handleSearchSubmit = (event) => {
        event.preventDefault();
        const { searchValue, tabIndex, potentialMerchants } = this.state;
        let merchants = potentialMerchants;
        

        if (searchValue == null || searchValue == "") {
            this.setState({ searchedData: null });
        } else {
            const filteredPotentialMerchants = [];
            

            merchants.filter(item => {
                if (typeof item.createdAt !== "undefined" && typeof item.name !== "undefined" && typeof item.business_name !== "undefined" && typeof item.mobile !== "undefined" && typeof item.email !== "undefined" && typeof item.landline !== "undefined" || typeof item.address !== "undefined" ) {
                    if (item.business_name.toLowerCase().includes(searchValue.toLowerCase()) || item.name.toLowerCase().includes(searchValue.toLowerCase()) ||
                        item.mobile.toLowerCase().includes(searchValue.toLowerCase()) || item.landline.toLowerCase().includes(searchValue.toLowerCase())
                        || this.render_date(item.createdAt).toLowerCase().includes(searchValue.toLowerCase()) || item.email.toLowerCase().includes(searchValue.toLowerCase()) || item.address.toLowerCase().includes(searchValue.toLowerCase()) || item?.source?.toLowerCase()?.includes(searchValue.toLowerCase())
                    ) {
                        filteredPotentialMerchants.push(item)
                    }
                }
            })
            console.log(filteredPotentialMerchants);
            this.setState({ searchedData: filteredPotentialMerchants });
        }
    }

    renderBulkSmsModal = () => {
        const { sms_templates } = this.state;
        return (
            <Modal isOpen={this.state.send_sms_modal} toggle={this.send_sms_toggle} className="modal-body" centered={true}>
                <form className="theme-form" noValidate="" >
                    <ModalHeader toggle={this.send_sms_toggle} style={{ textAlign: 'center' }}>i-Pay </ModalHeader>
                    <ModalBody>
                        <div style={{ paddingLeft: "20px", paddingRight: "20px", borderRadius: "10px" }}>
                            <div className="form-group row" style={{ backgroundColor: "#D3D3D3", paddingTop: "50px", paddingBottom: "20px" }}>
                                <label className="col-sm-4 col-form-label" htmlFor="exampleFormControlSelect9">Sms Template :</label>
                                <div className="col-sm-8">
                                    <select className="col-sm-12 form-control digits" id="exampleFormControlSelect9" name="selected_template" value={this.state.send_templ_mail_id} onChange={(e) => this.handle_template_select(e)} required>
                                        <option value="">select template</option>
                                        {

                                            sms_templates ?
                                                sms_templates.map((temp_item, index) => (

                                                    <option value={temp_item._id} key={temp_item._id}
                                                    >{temp_item.templ_name}</option>
                                                )) :
                                                null
                                        }

                                    </select>
                                </div>
                                <br />
                            </div>

                        </div>
                        <br />
                        <h6>Hey, Are you sure you want to continue sending the sms ?</h6>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit" 
                        //onClick={(e) => { this.handle_send_mail_submit(e) }} 
                        >Yes Please</Button>
                        <Button color="default" onClick={this.send_sms_toggle}>No Thanks</Button>

                    </ModalFooter>
                </form>
            </Modal>
        );
    }

    render() {
        let { mail_templates, potentialMerchants, filteredPotentialMerchants, searchValue, access_level, sms_templates } = this.state;
        // console.log(potentialMerchants);
        console.log(this.state)
        console.log(sms_templates);

        return (
            <Fragment>
                <div className="row">
                    {this.renderBulkSmsModal()}
                    <div className="col-lg-6 col-sm-12" style={{fontSize:"12px"}}>
                        <Breadcrumb title={"Potential merchants"} parent={"Home"} />
                    </div>
                    <div className="col-lg-6 col-sm-12 ">
                        <div className="" style={{ paddingTop: "10px",marginLeft:"0rem",marginRight:"1rem",marginBottom:"0rem" ,width:"auto",display:"flex",alignItems:"center"}} role="group" aria-label="Basic example">
                            <button className="btn custBtnAdmin"
                                onClick={()=>{this.toggleModal()}}><i className="fa fa-plus-square-o" style={{ color: "#327ebc", margin: "0 7px 0 0" }}></i>Add merchant
                            </button>

                            <button className="btn custBtnAdmin"
                                onClick={this.toggleCSVModal}><i className="fa fa-plus-square-o" style={{ color: "#327ebc", margin: "0 7px 0 0" }}></i>CSV upload
                            </button>
                            
                            <a className="btn custBtnAdmin" style={{lineHeight: '32px'}} href="https://api.ipaypro.co/uploads/sample_csv/sample_record.csv" download>
                                <i className="fa fa-file-text-o" style={{ color: "#327ebc", margin: "0 7px 0 0", fontSize: "12px" }}></i>CSV sample
                            </a>
                            
                            <button className="btn custBtnAdmin" 
                                onClick={this.deleteSelectedButtonClick} disabled={this.state.selectedIDs.length == 0 ? "disabled" : null}>{this.state.selectedIDs.length == 0 ? <i style={{ color: "#327ebc", fontSize: "15px", margin: "0 7px 0 0" }} id="delete" title="delete" className="fa fa-trash-o"></i> : <i style={{ color: "#327ebc", fontSize: "15px", margin: "0 7px 0 0" }} id="delete" title="delete" className="fa fa-trash-o"></i>}Delete selected
                            </button>
                        </div>
                    </div>
                    {/* {access_level != 1 && <div className="col-lg-5 mt-2 mb-2 pull-right">
                        <button className="btn btn-light mt-2 mr-2" style={{ padding: "6px 10px 6px 14px" }} onClick={this.toggleModal}><i className="fa fa-plus" style={{ margin: "0 7px 0 0" }}></i>Add</button>
                        <div className="mt-2" style={{ paddingTop: "10px", display: "inline-block" }} >
                            <button style={{ margin: "10px 7px 0 0", paddingLeft: '1rem' }} className="btn btn-light" onClick={this.toggleCSVModal}><i className="fa fa-plus" style={{ margin: "0 7px 0 0" }}></i>CSV  </button>
                            <a style={{ paddingTop: '.5rem', marginRight: "10px", marginTop: "20px" }} href="https://api.ipaypro.co/uploads/sample_csv/sample.csv" download>Sample CSV</a>
                        </div>
                        <button style={{ padding: "5px 8px" }} className="btn btn-light mr-2 mt-2"
                            onClick={this.deleteSelectedButtonClick} disabled={this.state.selectedIDs.length == 0 ? "disabled" : null}>Delete Selected</button>
                    </div>} */}
                </div>

                {/* <AddPotentialMerchantModal modal={this.state.modal} toggleModal={this.toggleModal}></AddPotentialMerchantModal> */}
                <AddCsvModal modal={this.state.csvmodal} toggleModal={this.toggleCSVModal}></AddCsvModal>
                <AddPotentialMerchantModal modal={this.state.modal} toggleModal={this.toggleModal} activeTab={this.state.tabIndex} countries={this.state.countries} fetch_country_details={this.fetch_country_details}
                potentialMerchantItem={this.state.potentialMerchantItem}
                 countryState={this.state.countryState}></AddPotentialMerchantModal>
                {this.renderDeleteModal(this.state.selectedId)}
                {this.renderDeleteSelectedModal()}
                {this.state.selectedId ? this.renderInfoModal() : null}
				{this.state.previewEmail && this.state.send_templ_mail_id && this.state.send_templ_mail_id !== "select" && this.previewEmailComp()}


                <div className="container-fluid">
                    <div className="row theme-tab">
                        <Tabs style={{ padding: "0",backgroundColor:"white",borderRadius:"3px",border:"1px solid rgb(223, 232, 241) ",marginBottom:"0px" }} className="col-sm-12" selectedIndex={4} onSelect={index => this.setState({ tabIndex: index })}>
                            <TabList className="tabs tab-title">
                                <Tab style={{ display:"none",alignItems:"center"}} tabIndex="1">
                                    <i className="fa fa-scribd" style={{ fontSize: "0.75rem", marginRight: "8px" }}></i>
                                                     Speed food
                                                ({potentialMerchants ? potentialMerchants.filter(el => {
                                        return el.type == 1
                                    }).length : 0})
                            </Tab>

                                <Tab style={{ display:"none",alignItems:"center"}} tabIndex="2">
                                    <i className="fa fa-database" style={{ fontSize: "0.75rem", marginRight: "8px" }}></i>
                                                    Hidden secret
                                                ({potentialMerchants ? potentialMerchants.filter(el => {
                                        return el.type == 2
                                    }).length : 0})

                            </Tab>

                                <Tab style={{ display:"none",alignItems:"center"}} tabIndex="3" >
                                    <i className="fa fa-list-ul" style={{ fontSize: "0.75rem", marginRight: "8px" }}></i>Other hospitality
                                                ({potentialMerchants ? potentialMerchants.filter(el => {
                                        return el.type == 3
                                    }).length : 0})
                </Tab>

                                <Tab style={{ display:"none",alignItems:"center"}} tabIndex="0" >
                                    <i className="fa fa-list-ul" style={{ fontSize: "0.75rem", marginRight: "8px" }}></i>Others
                                                ({potentialMerchants ? potentialMerchants.filter(el => {
                                        return el.type == 0
                                    }).length : 0})
                            </Tab>

                                <Tab style={{ display:"flex",alignItems:"center", }} tabIndex="4" >
                                    <i className="fa fa-check-square" style={{ fontSize: "0.75rem", marginRight: "8px" }}></i> All
                                                ({potentialMerchants ? potentialMerchants.filter(el => {
                                        return el
                                    }).length : 0})
                            </Tab>
                                {/* <div style={{ margin: "5px 5px 0 5px" }}>
                                    <form onSubmit={(event) => this.handleSearchSubmit(event)}>
                                        <div >
                                            <input style={{
                                                border: "none",
                                                borderRadius: "4px",
                                                padding: "3px 10px",
                                                fontSize: "0.75rem",
                                                marginTop:"0.6rem"
                                            }}
                                                type="text"
                                                placeholder="Search"
                                                onChange={this.onSearchChangeHandler} />
                                        </div>
                                    </form>
                                </div> */}
                                {access_level != 1 && <div className="pull-right" style={{ marginTop: "14px" }}>
                                    <div className="btn-group" role="group" aria-label="Basic example">
                                        <button className="btn custBtnAdmin"
                                            onClick={() => {
                                                this.send_mail_toggle();
                                                this.bulkMailClick();
                                            }} ><i className="icofont icofont-email" style={{ fontSize: "0.75rem", marginRight: "8px" }}></i>Bulk email</button>

                                        <button className="btn custBtnAdmin" onClick={() => {
                                            this.send_sms_toggle();
                                            this.bulkSmsClick();
                                        }}
                                        ><i className="icofont icofont-envelope" style={{ fontSize: "0.75rem", marginRight: "8px" }}></i>Bulk SMS</button>
                                    </div>
                                </div>}

                                <button
								className="btnexport custBtnAdmin"
								onClick={this.toggleSMSModal}
                                style={{marginTop: '15px'}}
							>
								Send SMS
							</button>
                            </TabList>

                            {this.state.potentialMerchants.length != 0 ?
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-sm-12  xl-100">
                                            <div className="card">
                                                <div className="card-body" style={{ paddingTop:"0rem",paddingLeft: "1rem",paddingRight:"1rem",paddingBottom:"1rem" }}>
                                                    {/* <div style={{ padding: "15px", margin: "10px 0", border: "1px solid #ebecf1" }}>
                                                        <div style={{ display: "inline-block", }}>
                                                            <form onSubmit={(event) => this.handleSearchSubmit(event)}>
                                                                <div >
                                                                    <input style={{
                                                                        outline: "none",
                                                                        border: "1px solid #ebecf1",
                                                                        borderRadius: "4px",
                                                                        ':focus': {
                                                                            outlineColor: "#7C4DFF"
                                                                        }
                                                                        , padding: "3px 10px",
                                                                        fontSize: "1rem",

                                                                    }}
                                                                        type="text"
                                                                        placeholder="Search"
                                                                        onChange={this.onSearchChangeHandler} />
                                                                </div>
                                                            </form>
                                                        </div>
                                                        {access_level != 1 && <div className="pull-right">
                                                            <div className="btn-group" role="group" aria-label="Basic example">
                                                                <button className="btn btn-light"
                                                                    onClick={() => {
                                                                        this.send_mail_toggle();
                                                                        this.bulkMailClick();
                                                                    }} >Bulk Email</button>

                                                                <button className="btn btn-light"
                                                                >Bulk SMS</button>
                                                            </div>
                                                        </div>} */}
                                                    {/* {access_level != 1 && <div className="pull-right ">
                                                            <button style={{ padding: "5px 8px" }} className="btn btn-outline-primary mr-2"
                                                                onClick={() => {
                                                                    this.send_mail_toggle();
                                                                    this.bulkMailClick();
                                                                }} >Bulk Email</button>

                                                            <button className="btn btn-outline-primary"
                                                            >Bulk SMS</button> */}

                                                    {/* <button style={{ padding: "5px 8px" }} className="btn btn-outline-primary mr-2"
                                                                onClick={this.deleteSelectedButtonClick} disabled={this.state.selectedIDs.length == 0 ? "disabled" : null}>Delete Selected</button> */}


                                                    {/* </div>} */}
                                                    {/* </div> */}


                                                    <div className="tab-content-cls">
                                                        <TabPanel>
                                                            {this.renderTable(1)}
                                                        </TabPanel>

                                                        <TabPanel>
                                                            {this.renderTable(2)}
                                                        </TabPanel>

                                                        <TabPanel >
                                                            {this.renderTable(3)}
                                                        </TabPanel>

                                                        <TabPanel >
                                                            {this.renderTable(0)}
                                                        </TabPanel>

                                                        <TabPanel >
                                                            {this.renderAllTable()}
                                                        </TabPanel>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> : <div className="loader-box" style={{height:"100vh",width:"100wh"}}>
                              <div className="loader" style={{ display:"flex",alignItems:"center" }}>
                              <DisappearedLoading size="medium" color="#514F4E" />
                               </div> 
                               </div>}
                        </Tabs>
                    </div>
                </div>

                {/* <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body" style={{ padding: "0.5rem 0.8rem" }}>
                                    <div className="row">
                                        <Nav tabs className="nav-pills nav-primary mt-3">
                                            <NavItem className="nav nav-tabs ml-3" id="myTab" role="tablist" >
                                                <NavLinkTab className={this.state.activeTab == '1' ? 'active nav-link' : ''} onClick={() => { this.toggle('1') }} value='1'>
                                                    <i className="fa fa-scribd" style={{ fontSize: "1.2rem", marginRight: "0" }}></i>
                                                     Speed Food
                                                ({potentialMerchants ? potentialMerchants.filter(el => {
                                                        return el.type == 1
                                                    }).length : 0})
                                                </NavLinkTab>
                                            </NavItem>
                                            <NavItem className="nav nav-tabs" id="myTab" role="tablist">
                                                <NavLinkTab className={this.state.activeTab == '2' ? 'active nav-link' : ''} onClick={() => { this.toggle('2') }} value='2'>
                                                    <i className="fa fa-database" style={{ fontSize: "1.2rem" }}></i>
                                                    Hidden Secret
                                                ({potentialMerchants ? potentialMerchants.filter(el => {
                                                        return el.type == 2
                                                    }).length : 0})
                                                </NavLinkTab>
                                            </NavItem>
                                            <NavItem className="nav nav-tabs" id="myTab" role="tablist">
                                                <NavLinkTab className={this.state.activeTab == '3' ? 'active nav-link' : ''} onClick={() => { this.toggle('3') }} value='3'>
                                                    <i className="fa fa-list-ul" style={{ fontSize: "1.2rem" }}></i>Other Hospitality
                                                ({potentialMerchants ? potentialMerchants.filter(el => {
                                                        return el.type == 3
                                                    }).length : 0})
                                                </NavLinkTab>
                                            </NavItem>
                                            <NavItem className="nav nav-tabs" id="myTab" role="tablist">
                                                <NavLinkTab className={this.state.activeTab == '0' ? 'active nav-link' : ''} onClick={() => { this.toggle('0') }} value='0'>
                                                    <i className="fa fa-list-ul" style={{ fontSize: "1.2rem" }}></i>Others
                                                ({potentialMerchants ? potentialMerchants.filter(el => {
                                                        return el.type == 0
                                                    }).length : 0})
                                                </NavLinkTab>
                                            </NavItem>
                                            <NavItem className="nav nav-tabs" id="myTab" role="tablist" >
                                                <NavLinkTab className={this.state.activeTab == '4' ? 'active nav-link' : ''} onClick={() => { this.toggle('4') }} value='4'>
                                                    <i className="fa fa-check-square" style={{ fontSize: "1.2rem" }}></i> All
                                                ({potentialMerchants ? potentialMerchants.filter(el => {
                                                        return el
                                                    }).length : 0})
                                                </NavLinkTab>
                                            </NavItem>
                                        </Nav>

                                    </div>


                                    <div style={{ padding: "15px", margin: "10px 0", border: "1px solid #ebecf1" }}>
                                        <div style={{ display: "inline-block", }}>
                                            <form onSubmit={(event) => this.handleSearchSubmit(event)}>
                                                <div >
                                                    <input style={{
                                                        outline: "none",
                                                        border: "1px solid #ebecf1",
                                                        borderRadius: "4px",
                                                        ':focus': {
                                                            outlineColor: "#7C4DFF"
                                                        }, padding: "3px 10px",
                                                        fontSize: "1rem",
                                                    }}
                                                        type="text"
                                                        placeholder="Search"
                                                        onChange={this.onSearchChangeHandler} />
                                                </div>
                                            </form>
                                        </div>
                                        {access_level == 3 && <div className="pull-right ">
                                            <button className="btn btn-outline-primary" style={{ padding: "5px 8px" }}
                                                onClick={this.deleteSelectedButtonClick} disabled={this.state.selectedIDs.length == 0 ? "disabled" : null}>Delete Selected</button>
                                        </div>}
                                    </div>


                                    {this.state.potentialMerchants.length != 0 ?

                                        <TabContent activeTab={this.state.activeTab} >


                                            <TabPane tabId="1">
                                                {this.renderTable("1")}
                                            </TabPane>

                                            <TabPane tabId="2">
                                                {this.renderTable("2")}
                                            </TabPane>

                                            <TabPane tabId="3">
                                                {this.renderTable("3")}
                                            </TabPane>

                                            <TabPane tabId="0">
                                                {this.renderTable("0")}
                                            </TabPane>
                                            <TabPane tabId="4">
                                                {this.renderAllTable()}
                                            </TabPane>

                                        </TabContent>
                                        : <div className="loader-box" >
                                            <div className="loader" style={{ margin: "auto auto" }}>
                                                <div className="line bg-primary"></div>
                                                <div className="line bg-primary"></div>
                                                <div className="line bg-primary"></div>
                                                <div className="line bg-primary"></div>
                                            </div>
                                        </div>}
                                </div>
                            </div>

                        </div>
                    </div>
                </div> */}


                <Modal isOpen={this.state.send_mail_modal} toggle={this.state.send_mail_toggle} className="modal-body" centered={true}>
                    <form className="theme-form" noValidate="" >
                        <ModalHeader toggle={this.state.send_mail_toggle} style={{ textAlign: 'center' }}>i-Pay </ModalHeader>
                        <ModalBody>
                            <div style={{ paddingLeft: "20px", paddingRight: "20px", borderRadius: "10px" }}>
                                <div className="form-group row" style={{ backgroundColor: "#D3D3D3", paddingTop: "50px", paddingBottom: "20px" }}>
                                    <label className="col-sm-4 col-form-label" htmlFor="exampleFormControlSelect9">Mail Template :</label>
                                    <div className="col-sm-8">
                                        <select className="col-sm-12 form-control digits" id="exampleFormControlSelect9" name="selected_template" value={this.state.send_templ_mail_id} onChange={(e) => this.handle_template_select(e)} required>
                                            <option value="">select template</option>
                                            {

                                                mail_templates ?
                                                    mail_templates.map((temp_item, index) => (

                                                        <option value={temp_item._id} key={temp_item._id}
                                                        >{temp_item.templ_name}</option>
                                                    )) :
                                                    null
                                            }

                                        </select>
                                    </div>
                                    <br />

                                    {/* <span style={{color:"red",fontSize:"14px",paddingTop:"50px",paddingTop:"30px",textAlign:"center"}}>Please make sure to check the template on speed mails >> merchant tab</span>   */}
                                </div>

                            </div>
                            <br />
                            <h6>Hey, Are you sure you want to continue sending the email ?</h6>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" type="submit" onClick={(e) => { this.handle_send_mail_submit(e) }} >Yes Please</Button>
                            <Button color="default" onClick={() => { this.send_mail_toggle(); }}>No Thanks</Button>

                        </ModalFooter>
                    </form>
                </Modal>


                <Modal
				isOpen={this.state.send_merchant_modal}
				toggle={() => {
					this.send_merchant_mail_toggle();
				}}
				className="modal-body"
				centered={true}
			>
				<form className="theme-form">
					<ModalHeader
						toggle={() => {
							this.send_merchant_mail_toggle();
						}}
						style={{ textAlign: "center" }}
					>
						Communicate with merchant
					</ModalHeader>
					<ModalBody>
						<div style={{ paddingLeft: "20px", paddingRight: "20px", borderRadius: "10px" }}>
							<div
								className=""
								style={{ backgroundColor: "#D3D3D3", paddingTop: "50px", paddingBottom: "20px" }}
							>
								<div className="d-flex flex-column align-items-center justify-content-center">
									<div>
										Please select your option
									</div>
									<div className="d-flex" style={{ gap: "50px" }}>
										<div className="d-flex" style={{ gap: "10px" }}>
											<input
												type="radio"
												id="selectedCommunicateOption-email"
												name="selectedCommunicateOption"
												value="email"
												checked={this.state.selectedCommunicateOption === "email"}
												onChange={e => this.setState({
													selectedCommunicateOption: e.target.value
												})}
											/>
											<label className="m-0" htmlFor="selectedCommunicateOption-email">Email</label>
										</div>
										<div className="d-flex" style={{ gap: "10px" }}>
											<input
												type="radio"
												name="selectedCommunicateOption"
												id="selectedCommunicateOption-sms"
												value="sms"
												checked={this.state.selectedCommunicateOption === "sms"}
												onChange={e => this.setState({
													selectedCommunicateOption: e.target.value
												})}
											// className="form-check-input"
											/>
											<label className="m-0" htmlFor="selectedCommunicateOption-sms">
												SMS
											</label>
										</div>
									</div>
								</div>
								{this.state.selectedCommunicateOption == "email" ? <div className="email-div">
									<div className="d-flex mt-3">
										<label className="col-sm-4 col-form-label" htmlFor="exampleFormControlSelect9">
											Mail Template :
										</label>
										<div className="col-sm-8">
											<select
												className="col-sm-12 form-control digits"
												id="exampleFormControlSelect9"
												name="selected_template"
												value={this.state.send_templ_mail_id}
												onChange={(e) => this.handle_template_select(e)}
												required
											>
												<option value="">select template</option>
												{mail_templates
													? mail_templates.filter(e => e?.showInPromotionList).map((temp_item, index) => (
														<option value={temp_item._id} key={temp_item._id}>
															{temp_item.templ_name}
														</option>
													))
													: null}
											</select>
											{
												this.state.send_templ_mail_id == "select" || this.state.send_templ_mail_id == "" ? null : <p style={{ cursor: "pointer", color: "#327ebc" }} onClick={() => this.setState({ previewEmail: true })}>Preview</p>
											}
										</div>
									</div>

									<div className="mt-3">
										<div className="d-flex align-items-center justify-content-center" style={{ gap: "10px" }}>
											<input type="checkbox" checked={this.state.sendToStoreEmail} onChange={e => this.setState({
												sendToStoreEmail: e.target.checked
											})} />
											Store Email: <input type="text" disabled value={this.state.userDataForPromotion?.email} />
										</div>
									</div>

									
									<div className="mt-3">
										<div className="d-flex align-items-center justify-content-center" style={{ gap: "10px" }}>
											<input type="checkbox" checked={this.state.sendToCustomEmail} onChange={e => this.setState({
												sendToCustomEmail: e.target.checked
											})} />
											Email: <input type="text" value={this.state.addedEmailForPromotion} onChange={e => {
												if (this.state.email_message_error != "") {
													this.setState({ email_message_error: "" })
												}
												this.setState({
													addedEmailForPromotion: e.target.value
												})
											}} />
										</div>
										{this.state.email_message_error && <p style={{ color: "red", textAlign: "center" }}>{this.state.email_message_error}</p>}
									</div>
								</div> : <div className="sms-div">
									<div className="mb-3 mt-3 d-flex align-items-start justify-content-center" style={{ gap: "10px" }}>
										<label style={{ fontWeight: "500", marginBottom: "0.2rem" }}>
											From (Sender ID)
										</label>
										<div className="form__form-group-input-wrap" style={{ position: "relative" }}>
											<input
												type="text"
												disabled={true}
												value={"Linky"}
												className="sms_modal_input text-capitalize"
											/>
										</div>
									</div>

									<div className="mt-3">
										<div className="d-flex align-items-center justify-content-center" style={{ gap: "10px" }}>
											<input type="checkbox" checked={this.state.sendToStoreSMS} onChange={e => this.setState({
												sendToStoreSMS: e.target.checked
											})} />
											Store Number: <input type="text" disabled value={`${this.state.userDataForPromotion?.country?.phone_prefix} ${this.state.userDataForPromotion?.mobile}`} />
										</div>
									</div>

									<div className="mt-3">
										<div className="d-flex align-items-center justify-content-center" style={{ gap: "10px" }}>
											<input type="checkbox" checked={this.state.sendToCustomSMS} onChange={e => this.setState({
												sendToCustomSMS: e.target.checked
											})} />
											Number:
											<div style={{ width: "min-content" }}>
												<select
													// className="col-sm-12 form-control digits"
													// id="exampleFormControlSelect9"
													name="phone_select"
													style={{
														height: "27px",
														width: "178px"
													}}
													value={this.state.selected_country}
													onChange={(e) => this.handle_country_select(e)}
													required
												>
													<option value="">Select Country</option>
													{this.state.mobOptions?.length
														? this.state.mobOptions.map((temp_item) => (
															<option value={temp_item.country_code}>
																{temp_item.label}
															</option>
														))
														: null}
												</select>
												<input type="text" className="input-wihtout-focus-change" value={this.state.addedSMSForPromotion} onChange={e => {
													if (this.state.sms_message_error != "") {
														this.setState({ sms_message_error: "" })
													}
													this.setState({
														addedSMSForPromotion: e.target.value
													})
												}} />
											</div>
										</div>
										{this.state.sms_message_error && <p style={{ color: "red", textAlign: "center" }}>{this.state.sms_message_error}</p>}
									</div>

									<div
										style={{
											padding: "0.5rem 2.5rem"
										}}
									>

										<div className="mb-3 d-flex flex-column align-items-start form__form-group-input-wrap form__form-group-input-wrap--error-above" style={{ marginBottom: 0 }}>
											<div className="w-100 d-flex align-items-center justify-content-between">
												<label style={{ fontWeight: "500", marginBottom: "0.2rem" }}>Message <span style={{ color: '#ff0000' }}>*</span></label>
												<label style={{ fontWeight: "500", marginBottom: "0.2rem" }}> {this.state.sms_message.length} chars / {this.state.total_sms} SMS </label>
											</div>
											<textarea
												className="w-100 mini_site_biz_input textarea-custom-h border p-2 textarea-focus-visible-none my-1"
												defaultValue={this.state?.sms_message}
												value={this.state?.sms_message}
												onBlur={(e) => this.calculateSMSCharges()}
												onChange={(e) => {
													if (e.target.value.length <= 306) {
														this.setState({ sms_message: e.target.value })
													}
												}} maxLength={306} style={{ borderRadius: '5px', padding: '1rem', border: '1px solid #c3c3c3', color: 'rgb(49, 49, 49)' }}
											></textarea>
											<span>{this.state.sms_message.length}/306 character limit</span>
										</div>
									</div>

									<div className="d-flex" style={{
										padding: "0.5rem 2.5rem"
									}}>

										<div className="mb-3 d-flex flex-column align-items-start form__form-group-input-wrap form__form-group-input-wrap--error-above" style={{ marginBottom: 0 }}>
											<div className="w-100 d-flex align-items-center justify-content-between">
												<label style={{ fontWeight: "500", marginBottom: "0.2rem" }}>SMS Template <span style={{ color: '#ff0000' }}>*</span></label>
											</div>
											<select
												className="col-sm-12 form-control digits"
												id="exampleFormControlSelect99"
												name="selected_template_sms"
												style={{ width: "316px" }}
												value={this.state.send_templ_sms_id}
												onChange={(e) => this.handle_template_select_sms(e)}
												required
											>
												<option value="">select template</option>
												{sms_templates
													? sms_templates.map((temp_item, index) => (
														<option value={temp_item._id} key={temp_item._id}>
															{temp_item.templ_name}
														</option>
													))
													: null}
											</select>
										</div>
									</div>

									<div className="mb-3 w-100 d-flex align-items-center justify-content-between" style={{ padding: "0.5rem 2.5rem" }}>
										<span style={{ fontWeight: 500, fontSize: '15px' }}>Total</span>
										<span style={{ fontWeight: 500, fontSize: '15px' }}> {this.state.totalChrages} </span>
										{/* <span style={{ fontWeight: 500, fontSize: '15px' }}> {this.ShowCurrencyNum(totalCharge, true)} </span> */}
									</div>

								</div>}
								<br />
							</div>
						</div>
						<br />
						{
							this.state.selectedCommunicateOption == "email" ? <h6>Hey, Are you sure you want to continue sending the email?</h6> : <h6>Hey, Are you sure you want to continue sending the sms?</h6>
						}
					</ModalBody>
					<ModalFooter>
						<Button
							color="default"
							onClick={() => {
								this.send_merchant_mail_toggle();
							}}
						>
							No Thanks
						</Button>
						<Button
							color="primary"
							type="submit"
							onClick={(e) => {
								this.onSendMailToMerchant(e);
							}}
						>
							Yes Please
						</Button>
					</ModalFooter>
				</form>
			</Modal>


            </Fragment>
        );
    }
}

export default PotentialMerchants;