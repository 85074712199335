// http://visionmedia.github.io/superagent
import superagent from "superagent";

// import {User} from "../components/utils/User/User";

// import Network from "../Api/Network";
// const user = new User();
// console.log(jwttoken)
var HTTPClient = {
	wrapper: function (inner, type = 0) {
		return function (error, response) {
			// Network.completed();

			if (!inner) return;
			// chance to wrap and call original

			var parsed = null;
			if (response && response.text && response.text.length > 0) {
				try {
					parsed = JSON.parse(response.text);
				} catch (e) {
					if (type == 3) {
						parsed = response.text;
					} else {
						parsed = null;
					}
					// TODO: some other error?
					// console.log("HTTPClient could not parse:\n\n" + response.text);
				}
			}

			var errorObj = null;
			var valueObj = null;

			if (error) {
				// error.status => 422
				errorObj = {};
				if (error.status) {
					errorObj.status = error.status; // 422
				} else {
					errorObj.status = 520; // Unknown error
				}

				errorObj.errors = [];
				if (parsed && parsed.error) {
					errorObj.message = parsed.error;
				}
				if (!errorObj.message) {
					errorObj.message = "Server Error: " + errorObj.status;
				}
				console.log("http error (" + errorObj.status + "): " + errorObj.message);
			} else {
				valueObj = parsed;
			}
			inner(errorObj, valueObj);
		};
	},

	addHeaders: function (req, type = 0) {
		// TODO: load version from somewhere
		var appVersion = "1.0";
		var apikey = "ipay-access-token";

		// var jwttoken = user.getToken();
		//var userAgent = "Sample iPhone v" + appVersion;
		//var locale = 'en-US';

		req = req.accept("application/json");
		req = req.type("application/json");
		req = req.set("X-CLIENT-VERSION", appVersion);

		// if (type == 0){
		req = req.set("x-access-token", apikey);
		req = req.set("api-level", 2);
		req = req.set("sessionCountry", localStorage.getItem("sessionCountryCode"));
		req = req.set("authorization", localStorage.getItem("token"));

		//   if(jwttoken)
		//     req = req.set("authorization", `Bearer ${jwttoken}`);
		// }
		// else {
		//   req = req.set("x-api-key", 'SpeedFood_Android_2017');
		//   req = req.set("authorization", "Basic ZG06NTU1NQ==");
		// }

		//req = req.set('X-Sample-User-Agent', userAgent);
		//req = req.set('X-LOCALE', locale);

		// if (currentUser && currentUser.data.guid) {
		//   req = req.set('X-GUID', currentUser.data.guid);
		// }
		// if (currentUser && currentUser.data.ab_decision_group_id) {
		//   req = req.set('X-AB-DECISION-GROUP-ID', currentUser.data.ab_decision_group_id.toString());
		// }
		// if (currentUser && currentUser.data.ab_decision) {
		//   req = req.set('X-AB-DECISION', currentUser.data.ab_decision);
		// }

		return req;
	},

	fetch: function (req, callback, type = 0) {
		req = this.addHeaders(req, type);

		// Network.started();
		req = req.withCredentials();
		req.end(this.wrapper(callback, type));
	},

	url: function (path) {
		   var host = "https://api.ipaypro.co";
		// var host = "https://dmapi.ipaypro.co";
		// var host = "http://localhost:5222";
		// var host ="https://adminf.i-pay.com.au"	
		// var host = "http://localhost:3000";

		// var host = "https://speedfood.com.au";
		return host + "/" + path;
	},

	urlphp: function (path) {
		var host = "https://speedfood.com.au";
		// var host = "https://speedfood.com.au";
		return host + "/" + path;
	},

	postGetText: function (path, values, callback) {
		var req = superagent.post(this.url(path));
		if (values) {
			req = req.send(values);
		}
		this.fetch(req, callback, 3);
	},

	post: function (path, values, callback) {
		var req = superagent.post(this.url(path));
		if (values) {
			req = req.send(values);
		}
		this.fetch(req, callback);
	},

	postphp: function (path, values, callback) {
		var req = superagent.post(this.urlphp(path));
		if (values) {
			req = req.send(values);
		}
		this.fetch(req, callback, 1);
	},

	put: function (path, values, callback) {
		var req = superagent.put(this.url(path));
		if (values) {
			req = req.send(values);
		}
		this.fetch(req, callback);
	},

	get: function (path, params, callback) {
		var req = superagent.get(this.url(path));
		if (params) {
			req = req.query(params);
		}
		this.fetch(req, callback);
	},
};

export default HTTPClient;
