import React, { useEffect, useState} from "react";
import { toast } from "react-toastify";
import api from "../../constant/apilist";
import client from "../../Api/HTTPClient";
import {DisappearedLoading} from "react-loadingg";

import DocPendingVerification from "../../components/DocPendingVerification/DocPendingVerification"

const MerchantDocPending = (props) => {

    const [customersData, setCustomersData] = useState(null)

    // console.log(props.defaultCountryCode)

    const getAllData = () => {
        let postdata = {pendingCustomer : true, type : 2 };
        client.post(api.getCustMerchData, postdata, (error, response) => {
            if (!error) {
                try {
                    if (!response.error) {
                        // console.log(response.result.merchants, "merchants")
                        // console.log(response.result.merchants.filter(el => el.country.code.toLowerCase() == props.defaultCountryCode.toLowerCase()), "props.defaultCountryCode")
                        if(props.defaultCountryCode=='ALL')
                        {
                            setCustomersData(response.result.merchants)
                        }
                        else{
                            setCustomersData(response.result.merchants.filter(el => el.country.code.toLowerCase() == props.defaultCountryCode.toLowerCase()));
                        }
                        

                       
                    } else {
                        setTimeout(() => {
                            toast.error(response.message);
                        }, 200);
                    }
                } catch (e) {
                    setTimeout(() => {
                        toast.error("Internal error occured. Please contact support" + e.message);
                    }, 200);
                }

            } else {
                setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                }, 200);
            }
            //   current.setState({ loading: false });
        });
    };

    useEffect(() => {
        // alert("hrer");
        getAllData()
        console.log('hello')
    }, []);


    // console.log(customersData,props.underreview)

    // let filter_merchants=props.underreview.map((elem)=>{return elem._id})

    // console.log(filter_merchants)

    
    //console.log(customersData);


    return(
        <div>
            {customersData ? 
           <DocPendingVerification country={props.defaultCountryCode} customersData={customersData} type="merchants"/> : <div className="loader-box" style={{height:"100vh",width:"100wh"}}>
                              <div className="loader" style={{ display:"flex",alignItems:"center" }}>
                              <DisappearedLoading size="medium" color="#514F4E" />
                               </div> 
                               </div>}
        </div>
    );
}

export default MerchantDocPending;