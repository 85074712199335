import React, { Fragment, useEffect, useState } from "react";
import PaginationComponent from "react-reactstrap-pagination";
import * as moment from 'moment-timezone';
import { NavLink } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";

// const BorderTable = () => {
function DocPendingVerification(props) {

    const [activePage, setActivePage] = useState(1);
    const [pageSize, setPageSize] = useState(15);
    const { customersData } = props;
    // console.log(customersData, "customersData")
    const [country, setCountry] = useState(null);
    const [data, setData] = useState(null);
    const [showData, setShowData] = useState(null);
    const [cusReverse, setCusReverse] = useState(null);

    // useEffect(() => {
        
        
    // }, [customersData])

    // console.log(country,"country")

    useEffect(() => {
        if(props.country != 'ALL'){
            let country_filter = customersData.reverse()?.filter(ele => ele.country.code == props.country);
            setShowData(country_filter);
        } else {
            let temp = customersData.reverse();
        setCusReverse(temp);
        setShowData(temp);
        }
    }, [props.country, customersData])

    function moveElement(array) {

        let arrangeCountry = [...array];

        let australia = arrangeCountry.map((e) => e.country_code).indexOf('AU');
        if (australia != -1) {
            arrangeCountry.splice(0, 0, arrangeCountry.splice(australia, 1)[0]);
        }

        let japanIdx = arrangeCountry.map((e) => e.country_code).indexOf('JP');
        if (japanIdx != -1) {
            arrangeCountry.splice(1, 0, arrangeCountry.splice(japanIdx, 1)[0]);
        }

        let thailand = arrangeCountry.map((e) => e.country_code).indexOf('TH');
        if (thailand != -1) {
            arrangeCountry.splice(2, 0, arrangeCountry.splice(thailand, 1)[0]);
        }

        let unitedState = arrangeCountry.map((e) => e.country_code).indexOf('US');
        if (unitedState != -1) {
            arrangeCountry.splice(3, 0, arrangeCountry.splice(unitedState, 1)[0]);
        }

        let india = arrangeCountry.map((e) => e.country_code).indexOf('IN');
        if (india != -1) {
            arrangeCountry.splice(4, 0, arrangeCountry.splice(india, 1)[0]);
        }

        return arrangeCountry;
    }


    const fetchCountries = () => {

        client.post(api.fetch_country, { show_all: true }, async function (error, response) {

            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {

                            let getCountryCode = [];
                            let arrangeCountry = [...response.result.data];
                            arrangeCountry = await moveElement(arrangeCountry);
                            setCountry(arrangeCountry);

                            arrangeCountry.length > 0 && arrangeCountry.map((countryData) => {
                                getCountryCode.push(countryData.country_code)
                                return countryData;
                            });


                            // current.getCustomersCountByCountry(getCountryCode);
                        }
                    } catch (err) {
                        // console.log(err, "fetch error")
                        toast.error("Internal error occured. Please contact support");
                    }
                } else {
                    toast.error("Internal error occured. Please contact support");
                }
            } else {
                toast.error("Internal error occured. Please contact support");
            }
        });
    }

    useEffect(() => {
        fetchCountries();
    }, [])

    return (

        <Fragment>
            <div className="container-fluid">
                <div className="row theme-tab">

                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12 col-xl-12 xl-100">
                                <div className="card">
                                    <div className="card-body" style={{ padding: "1rem" }}>
                                        <div className="tab-content-cls">
                                            {showData?.length > 0 ?
                                                <div className="table-responsive" style={{ marginTop: 20, marginBottom: 20 }}>
                                                    <table className="table table-border-horizontal table-striped table-light table-striped table-light">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">#</th>
                                                                <th scope="col">{props.type == "customers" ? "Customer Name" : "Store Name"}</th>
                                                                <th scope="col">Country</th>
                                                                <th scope="col">Date of Submission</th>
                                                                <th scope="col">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {showData?.slice((activePage - 1) * pageSize, (activePage) * pageSize).map((item, index) => {
                                                                let country_item = country?.filter( ele => ele.country_code == item.country.code)
                                                                return (
                                                                    <tr key={index + 1}>
                                                                        <th>{((activePage - 1) * pageSize) + index + 1}</th>
                                                                        <td>{item.name ? item.name : item.business_name}</td>
                                                                        <td>{country_item?.[0]?.country_name}</td>
                                                                        <td>{moment.tz(item.documents[0].updated_date, "Australia/Sydney").format('DD/MM/YYYY HH:MM:SS')}</td>
                                                                        <td> {props.type == "customers" ?
                                                                            <NavLink to={{
                                                                                pathname: `${process.env.PUBLIC_URL}/customer/wallet/${item._id}`,
                                                                                search: "?activeTab=5"
                                                                            }}>
                                                                                <i className="fa fa-folder-open-o"></i>
                                                                            </NavLink> : <NavLink to={{
                                                                                pathname: `${process.env.PUBLIC_URL}/merchants/merchantDetails/${item._id}`,
                                                                                search: "?activeTab=3"
                                                                            }}>
                                                                                <i className="fa fa-folder-open-o"></i>
                                                                            </NavLink>}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                    <div className="mt-3" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                            <select className="custom-select" style={{ width: "32%", marginRight: "7px" }} onChange={(event) => setPageSize(event.target.value)} value={pageSize}>
                                                                <option value="15">15</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                                <option value="75">75</option>
                                                                <option value="100">100</option>
                                                            </select>
                                                            <span>Records per Page</span>
                                                        </div>
                                                        <PaginationComponent
                                                            totalItems={customersData.length}
                                                            pageSize={pageSize}
                                                            onSelect={(page) => setActivePage(page)} />
                                                    </div>
                                                </div> : <p style={{ textAlign: "center" }}>No Records found</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

        </Fragment>
    );
}

export default DocPendingVerification;
