import React, { useRef } from "react";
// import update from 'immutability-helper'
import { useCallback, useState } from 'react'
import Card from './renderSubscription'
import {
    arrayMove,
  } from '@dnd-kit/sortable';
const style = {
  width: 400,
}
export const RenderSubTitle = (props) => {
    const [cards, setCards] = useState(props.sub_titles)
    
    const moveCard = (dragIndex, hoverIndex, title_id, save = false) => {

        if (save) {
            props.update_index_api(cards)
        } else {
            let new_items = arrayMove(cards, dragIndex, hoverIndex);
            setCards(new_items)
        }
       
    }

    return (
        <>
            {
                cards.map((row, index) => {
                    return (

                        <Card
                            key={row._id}
                            id={row._id}
                            // {...props}
                            moveCard={moveCard}
                            feature_id={row._id} sort={true}
                            index={index} row_item={row}
                            {...props}
                            
                        />
                    )
                })
            }
            
        </>
    )
}

export default RenderSubTitle
