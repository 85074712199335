import React, { Fragment, useEffect, useState } from "react";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import { Icon } from "@mdi/react";
import { mdiInformation,mdiContentCopy } from "@mdi/js";
import { Table, UncontrolledTooltip } from "reactstrap";
import { DisappearedLoading } from "react-loadingg";

const ReferralDetails = (props)=>{
  const [referrData, setReferrData] = useState([]);
  const [countries, setCountries] = useState([]);
  const [company,setCompany] = useState([]);
  const [merchants,setMerchants] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [copied, setCopied] = useState({});
  const userData = props.merchant_user
  console.log(userData, "userData")
  
  const getreferDetails = () => {
    let postData = {
      account_type: "2",
    };
    client.post(api.fetch_refer_details, postData, (error, response) => {
      if (!error) {
        if (!response.error) {
          setReferrData(response.result.data);
        }
      }
    });
  };
  useEffect(()=>{
    getreferDetails()
  },[])
  // console.log(referrData, "referrData")

  useEffect(()=>{
    let postdata = {
      admin_id: props.merchant_user._id,
      is_trashed: window.location.pathname.includes("trashed_merchant")
      
     }
    
    client.post(api.fetch_all_stores, postdata, (error, response) => {
      if (!error) {
          if (typeof response.error !== "undefined") {
              try {
                  if (!response.error) {

                      // console.log(response.result.merchants.length, "response.result.merchants.length")
                      if (response.result.merchants.length == 0) {

                      } else {
                          localStorage.setItem('@allStoreCount', response.result.merchants.length)
                          // console.log(company,"company")
                          setCompany(response.result.company);
                          setMerchants(response.result.merchants)
                      }
                  }
              } catch (e) { }
          }
      }

  });
  },[])
  // console.log(company,"company")
  // console.log(merchants, "merchants")

  useEffect(() => {
    client.post(api.countries_list, { show_all: true }, async (error, response) => {
        if (!error) {
            try {
                if (!response.error) {
                    setCountries(response.result.data)
                }
            } catch (e) { }
        } else {
            // toast.error("Internal error occured.Please contact support");
        }
    });

  }, [])

  // console.log(countries,"countries")

  let tableData = []
  if(company.length){
    // console.log("inside if")
    company.map(comp => {
      merchants.filter(merch => comp._id.trim() == merch.company_id.trim()).map(item => {
        if(userData?._id === item.adminId){
          let rowData = {
            company_id:comp._id,
            companyName:comp.companyName,
            storeName:item.business_name,
            referralCode: item.my_referral_code,
            email:item.email,
            country:item.country
          }
          tableData.push(rowData)
          // console.log(rowData,"rowData")
          // console.log(tableData,"tableData-inside")
        }else{
          // console.log(comp._id,"compId")
          // console.log(merch.company_id,"merchId")
          // console.log(item.adminId,"itemid")
          // console.log(userData.id,"userdata-id")
        }
      })
    })
  }
  // console.log(tableData,"tableData")

  const groupByCompany = (data)=>{
    const result = {};
        data.forEach((item) => {
            
            if (!result[item.company_id]) {
                result[item.company_id] = {
                    name: item.companyName,
                    country:item.country,
                    stores: []
                    
                };
            }
            result[item.company_id].stores.push(item);
        });
        return Object.values(result);
  }

  if(tableData.length && groupData.length == 0){
    let postData = tableData.map(item=>item.referralCode)
    client.post(api.referral_stores,postData,async function(error,response){
      if(!error){
        if(!response.error){
          let conversion=response.result
          let lead=response.lead
          let views_result=response.views
          tableData = tableData.map(elem => {
            let conversion_value=conversion.filter(conv=>elem.referralCode==conv.referral_code).length
            let lead_value=lead?.filter(lead=>elem.referralCode==lead.referral_code).length
            let views=views_result?.filter(view=>elem.referralCode==view.my_referral_code).map(viewelem=>viewelem?.referral_views)

            elem.conversion_value=conversion_value
            elem.lead_value=lead_value
            elem.views=views[0]

            return elem
          })
          // console.log(tableData,"tableData-1")
          setGroupData(groupByCompany(tableData))
          setLoading(false)
        }
      }
    })
  }

  // console.log(groupData,"groupData")

  const copyToClipboard = (row, type) => {
    if (type == 'code') { navigator.clipboard.writeText(row.referralCode); }
    if (type == 'link') { navigator.clipboard.writeText(window.location.origin + '/sign-up?share_code=' + row.referralCode); }
    setCopied(prev => {
        return {
            ...prev,
            [row.referralCode + '_' + type]: true
        }
    });
    setTimeout(() => setCopied(prev => {
        return {
            ...prev,
            [row.referralCode + '_' + type]: false
        }
    }), 2000);
  };

  return(
    <Fragment>
      {!loading ? 
        <div>
        <div className="m-3 p-2">
        <h4 className="mb-0" style={{fontSize:'1rem'}}>Store(s) referral code and link</h4>
        <small style={{fontSize:'0.8rem'}}>Earn commission and referral cashbacks. <a href="https://help.linky.biz/affiliate-program" target="black">Learn more</a></small>
        {
          groupData.map((group,i)=>(
            <>
            <div className="mt-4">
              <h4 className="mb-2" style={{fontSize:'.95rem'}}>
                {group?.name}
                {countries.map(country=>{
                  return(
                    <>
                    {
                      country.country_code === group?.country.code &&
                      <span className="ml-2">
                        {`(${country.country_name})`}
                      </span>
                    }
                    </>
                  )
                })}
              </h4>
            </div>
            <Table className="border">
              <thead className="border">
                <tr>
                  <th className="border" width="180px">Store(s)</th>
                  <th className="border" width="120px">Referral code</th>
                  <th className="border">Refferal link</th>
                  <th className="border">
                    <div className="d-flex">
                      <span>Clicks</span>
                      <span id="clickTitle" className="ml-1 d-inline-flex"><Icon path={mdiInformation} size={0.7} color={"black"}/></span>
                    </div>
                    <UncontrolledTooltip
                      placement="top"
                      target="clickTitle">
                      <div className="p-1 text-left">
                        {" "}When your friend clicks on the referral link{" "}
                      </div>
                    </UncontrolledTooltip>
                  </th>
                  <th className="border">
                    <div className="d-flex">
                      <span>Signup's</span>
                      <span id="signuptitle" className="ml-1 d-inline-flex"><Icon path={mdiInformation} size={0.7} color={"black"}/></span>
                    </div>
                    <UncontrolledTooltip
                      placement="top"
                      target="signuptitle">
                      <div className="p-1 text-left">
                        {" "}When your friend signup using your referral link{" "}
                      </div>
                    </UncontrolledTooltip>
                  </th>
                  <th className="border">
                    <div className="d-flex">
                      <span>Made first payment</span>
                      <span id="firstPaymentTitle" className="ml-1 d-inline-flex"><Icon path={mdiInformation} size={0.7} color={"black"}/></span>
                    </div>
                    <UncontrolledTooltip
                      placement="top"
                      target="firstPaymentTitle">
                      <div className="p-1 text-left">
                        {" "}When your friend complete their first transaction after signup{" "}
                      </div>
                    </UncontrolledTooltip>
                  </th>
                </tr>
              </thead>
              <tbody>
                {
                  group.stores.map((store,i)=>(
                    <tr>
                      <td className="border">{store.storeName}</td>
                      <td className="border">
                        {store.referralCode}
                        {copied[store.referralCode + '_code'] ?
                          <span className="text-success ml-2">Copied</span> :
                          <Icon
                              path={mdiContentCopy}
                              className="mdi_icon_style ml-2 mt-2"
                              size={.8}
                              color="#414a4c"
                              onClick={() => {
                                  copyToClipboard(store, 'code')
                              }}
                          />
                        }
                      </td>
                      <td className="border"> 
                        {window.location.origin+'/sign-up?share_code='+store.referralCode}
                        {copied[store.referralCode + '_link'] ?
                          <span className="text-success ml-2">Copied</span> :
                          <Icon
                              path={mdiContentCopy}
                              className="mdi_icon_style ml-2 mt-2"
                              size={.8}
                              color="#414a4c"
                              onClick={() => {
                                  copyToClipboard(store, 'link')
                              }}
                          />}
                      </td>
                      <td className="border">{store.views>0?store.views:'-'}</td>
                      <td className="border">{store.lead_value>0?store.lead_value:'-'}</td>
                      <td className="border">{store.conversion_value>0?store.conversion_value:'-'}</td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>
            </>
          ))
        }
      </div>
        </div>
      :
        <div className="loader-box d-flex align-item-center justify-content-center" style={{ height: "50vh", width: "100wh" }}>
          <DisappearedLoading size="medium" color="#514F4E" style={{ position: "unset" }} />
        </div>
      }
    </Fragment>
  )
}

export default ReferralDetails;