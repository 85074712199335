import React, { Fragment, useState, useEffect } from 'react';
import { DisappearedLoading } from "react-loadingg";
import useForm from "react-hook-form";
import ClipLoader from "react-spinners/ClipLoader";


const HideAndUnhideFeature = ({
    preperence,
    loading,
    loadingItem,
    setloadingItem,
    updateFeatureData
}) => {


    let { register, errors } = useForm({ defaultValues: true });

    const handleFeatureChange = (item, event) => {
        setloadingItem(item._id)
        let postdata = { _id : item._id, hide: event.target.checked == true ? 0 : 1 }
        updateFeatureData(postdata);
    }

    // console.log(preperence, "preperence")


    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card merchantSetttings" style={{ borderRadius: '3px', marginBottom: "0px" }}>
                            <div className="card-body" style={{ padding: "1rem" }}>
                                {!loading ?
                                    <div className="table-responsive custom-scrollbar" style={{ width: "50%" }}>
                                        <table className="table table-border-horizontal" >
                                            <thead>
                                                <tr style={{ background: '#f9fafe' }}>
                                                    <th scope='col' style={{ width: "10%" }}>#</th>
                                                    <th scope="col" style={{ width: "60%" }} >Category Features</th>
                                                    <th scope="col" style={{ width: "30%" }} >status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    preperence && preperence.length > 0 && preperence.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td style={{ verticalAlign: "top" }}>
                                                                    <p>{item.title}</p>
                                                                </td>
                                                                <td style={{ verticalAlign: "top" }}>
                                                                    <p>
                                                                        {loadingItem && loadingItem == item._id ?
                                                                            <ClipLoader size={20} color="#36D7B7" loading={true} />
                                                                            :
                                                                            <input
                                                                                type="checkbox"
                                                                                id={`${index}_${item._id}`}
                                                                                name={`${index}_${item._id}`}
                                                                                // ref={register}
                                                                                checked={!item.hide}
                                                                                onChange={(e) => {
                                                                                    handleFeatureChange(item, e)
                                                                                }}
                                                                            />
                                                                        }
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    :
                                    <div className="loader-box" style={{ height: "40vh", width: "100wh" }}>
                                        <div className="loader" style={{ display: "flex", alignItems: "center" }}>
                                            <DisappearedLoading size="medium" color="#514F4E" />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default HideAndUnhideFeature 