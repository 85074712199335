import React, { Fragment, useEffect, useState } from 'react'
import client from "../../Api/HTTPClient";
import "./css/profile.css"
import api from "../../constant/apilist";
import no_image from "../../assets/images/No-Image.png";
import { ToastContainer, toast } from "react-toastify";
import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink, Progress, Button, Modal, ModalHeader, ModalBody, ModalFooter, Tooltip } from "reactstrap";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from "moment-timezone";
import { Link, useHistory } from "react-router-dom";
import Icon from "@mdi/react";
import {
    mdiCogOutline,
    mdiPencil,
    mdiStar,
    mdiStarOutline,
    mdiTrashCanOutline,
    mdiInformationOutline,
    mdiCloseThick,
} from "@mdi/js";

import { HashLoader } from 'react-spinners'
import Details from './storesandcompanies/Details';

export const StoresandCompany = (props) => {

    const { merchant_info, storesList, merchant_id, setDefaultIndex, userStorelist } = props;

    const [loading, setLoading] = useState(true)
    let [activeTab, setactiveTab] = useState("1");
    const [company, setCompany] = useState([])
    const [merchants, setMerchants] = useState([])
    const [merchants_users, setMerchants_users] = useState([])
    const [timeStamp, setTimeStamp] = useState(0);
    const [showDetails, setShowDetails] = useState(false)
    const [companyDetails,setCompanyDetails] = useState([])
    let history = useHistory();
    console.log(userStorelist, "userStorelist");
    console.log(company, "company");
    console.log(merchants, "merchants");

    useEffect(() => {
        setTimeStamp(Date.now());
    }, []);

    useEffect(() => {
        if (storesList) {
            setCompany(storesList.company)
            // setMerchants_users(storesList.merchants)
            // setMerchants(storesList.merchants)
        }

        if (userStorelist) {
            let getStores = userStorelist.filter((stores) => stores.adminId == merchant_info.adminId);
            setMerchants(getStores)
        }
    }, [storesList, userStorelist])

    useEffect(() => {
        if (merchants && merchants.length > 0) {
            if (merchants.some(obj => obj.isPrimary === true)) return;
            else {
                const firstMerchant = merchants[0];
                if (firstMerchant) {
                    firstMerchant.isPrimary = true;
                }
            }
        }
    }, [merchants]);

    const completedStoreSetup = (merchant) => {
        let merchData = storeStatusData.find(data => data.merchantId == merchant._id);

        if (merchData) {
            try {
                if (merchData.emailStatus == 1 && merchData.productAdded && merchData.merchantVerfied == 1 && merchData.payOutStatus && !merchData.subscriptionStatus) {
                    // if (merchant.email_status == 1 && merchant.products_added && merchant.is_verified == 1 && (checkPayoutStatus && checkPayoutStatus.payOutStatus) && !merchant.subscription.subscription_id.subscription.is_trial) {
                    return true;
                } else {
                    return false;
                }
            } catch {
                return false;
            }
        }

    }

    const [storeStatusData, setStoreStatusData] = useState([]);

    useEffect(() => {
        // let statusArr = [];
        // setLoading(true)
        // merchants && merchants.map((val) => {
        //     let postData = {
        //         merchantId: val._id,
        //         userData: val.adminId,
        //     };
        //     client.put(api.fetctWidgetPosition, postData, (error, response) => {
        //         console.log(response, "response fetch widget")
        //         if (!error) {
        //             if (!response.error) {
        //                 let temp = { ...response.result.statusData, _id: val._id, name: val.business_name };
        //                 statusArr.push(temp);
        //             } else {
        //                 toast.error("internal error occurred");
        //             }
        //         } else {
        //         }

        //         if (statusArr.length === merchants.length) {
        //             setStoreStatusData(statusArr);
        //             setLoading(false)
        //         }
        //     });
        // });

        let arrayPostData = [];

        merchants.length > 0 && merchants.forEach(element => {
            let postData = { merchantId: element._id, userData: element.adminId };
            arrayPostData.push(postData);
        });

        if (window.location.pathname.includes("trashed_merchant")) {
            setStoreStatusData(merchants);
            setLoading(false)
            return 
        }

        if (arrayPostData.length > 0) {

            // setLoading(true)
            let postData = { filterIds: arrayPostData }

            client.post(api.fetch_all_stores_progress_status, postData, (error, response) => {
                // console.log(response, "response fetch widget")
                if (!error) {
                    if (!response.error) {

                        if (response.result.length === merchants.length) {
                            setStoreStatusData(response.result);
                            setLoading(false)
                        }
                    } else {
                        toast.error("Internal error occured.Please contact support.");
                        setLoading(false)
                    }
                } else {
                    toast.error("Internal error occured.Please contact support.");
                    setLoading(false)
                }

            });

        }else{
            setLoading(false)

        }

    }, [merchants]);


    const ShowStoreStatus = (items) => {
        let checkListStatus = storeStatusData.find((val) => val.merchantId == items._id);
        let totalCompltedStep;
        // try {
        let completed = 0;
        let percentage = 0;

        if (checkListStatus?.merchantVerfied == 1) {
            completed = completed + 1;
            percentage = percentage + 40;
        }

        if (checkListStatus?.payOutStatus) {
            completed = completed + 1;
            percentage = percentage + 30;
        }
        if (!checkListStatus?.subscriptionStatus) {
            completed = completed + 1;
            percentage = percentage + 30;
        }
        totalCompltedStep = percentage;

        // } catch { }

        return (
            <div
                className="mr-2 d-flex flex-column "
                style={{
                    cursor: "pointer",
                    // width: '72%'
                    width: "100%",
                }}
            // onClick={() => onCompleteStoreSetup(items)}
            >
                {/* <div style={{ color: "#179AFD", fontSize: "14px", textDecoration: 'underline', marginRight: "8px" }}> Complete setup </div> */}
                <div className="d-flex align-items-center">
                    <div
                        style={{
                            fontSize: "16px",
                            paddingTop: "2px",
                            fontWeight: "bold",
                            marginRight: "3px",
                        }}>
                        {totalCompltedStep}%
                    </div>
                    <div
                        className="progress-wrap progress-wrap--small"
                        style={{ width: "100px" }}>
                        <Progress color='success' value={totalCompltedStep} />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <Fragment>
            <div className="container-fluid" style={{ paddingTop: '0.5rem' }}>
                <div className="col-sm-12 col-xl-12">
                    <div className="row">

                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card mb-0">
                                    <div className="card-body">
                                        {/* <Nav tabs className="nav-pills nav-primary">
                                            <NavItem className="nav nav-tabs nav-link" id="myTab" role="tablist">
                                                <NavLink className={activeTab == "1" ? 'active nav-link' : ''} onClick={() => { setactiveTab("1") }} value='1'>
                                                    Manage Stores</NavLink>
                                            </NavItem>


                                            <NavItem className="nav nav-tabs nav-link" id="myTab" role="tablist">
                                                <NavLink className={activeTab == "2" ? 'active nav-link' : ''} onClick={() => { setactiveTab("2") }} value='2'>
                                                    Manage Companies</NavLink>
                                            </NavItem>

                                        </Nav>
                                        <TabContent activeTab={activeTab}>
                                            <TabPane tabId="1">
                                                <div className='row'>
                                                    <div className='col-md-12'>
                                                        <div className="pt-4">
                                                            {storesList ?
                                                                <>
                                                                    {storesList && storesList.merchants.length > 0 && (
                                                                        <table className="table responsive table-striped">
                                                                            <tbody>
                                                                                {storesList.merchants.map((item) => {

                                                                                    return (
                                                                                        <tr key={item._id}>
                                                                                            <td
                                                                                                style={{ display: "table-cell", verticalAlign: "center" }}
                                                                                            >
                                                                                                <div className=" desk_v  align-items-center py-2 px-2 d-flex">
                                                                                                    {item.profile_image == "" ? (
                                                                                                        <>
                                                                                                            <span
                                                                                                                className="border p-2 mr-2"
                                                                                                                style={{
                                                                                                                    fontSize: "13px",
                                                                                                                    width: 40,
                                                                                                                    height: 40,
                                                                                                                    borderRadius: 20,
                                                                                                                    display: "block",
                                                                                                                    textAlign: "center",
                                                                                                                }}
                                                                                                            >
                                                                                                                <b style={{ textTransform: "uppercase" }}>
                                                                                                                    {item.business_name
                                                                                                                        .split(/\s/)
                                                                                                                        .reduce(
                                                                                                                            (response, word) =>
                                                                                                                                (response += word.slice(0, 1)),
                                                                                                                            ""
                                                                                                                        )
                                                                                                                        .slice(0, 2)}
                                                                                                                </b>
                                                                                                            </span>
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            <img
                                                                                                                className="mr-2"
                                                                                                                style={{
                                                                                                                    width: 40,
                                                                                                                    height: 40,
                                                                                                                    borderRadius: 20,
                                                                                                                    display: "block",
                                                                                                                }}
                                                                                                                //src={Ava}
                                                                                                                //src={(auth0.user && auth0.user.picture) || user.avatar || Ava}
                                                                                                                src={item.profile_image}
                                                                                                                alt="avatar"
                                                                                                            />
                                                                                                        </>
                                                                                                    )}
                                                                                                    &nbsp;
                                                                                                    <p className="d-inline">
                                                                                                        {item.business_name[0].toUpperCase() +
                                                                                                            item.business_name.slice(1)}
                                                                                                    </p>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    );
                                                                                })}
                                                                            </tbody>
                                                                        </table>
                                                                    )}
                                                                </>
                                                                : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPane>
                                            <TabPane tabId="2">
                                                <div className='row'>
                                                    <div className='col-md-12'>
                                                        <div className="pt-4">
                                                            {storesList && storesList.company.length > 0 && (
                                                                <table className="table responsive table-striped">
                                                                    <tbody>
                                                                        {storesList.company.map((item) => {
                                                                            return (
                                                                                <tr key={item._id}>
                                                                                    <td
                                                                                        style={{
                                                                                            display: "table-cell",
                                                                                            verticalAlign: "center",
                                                                                        }}
                                                                                    >
                                                                                        <div className=" desk_v  align-items-center py-2 px-2 d-flex">
                                                                                            {item.profile_image == "" ? (
                                                                                                <>
                                                                                                    <span
                                                                                                        className="border p-2 mr-2"
                                                                                                        style={{
                                                                                                            fontSize: "13px",
                                                                                                            width: 40,
                                                                                                            height: 40,
                                                                                                            borderRadius: 20,
                                                                                                            display: "block",
                                                                                                            textAlign: "center",
                                                                                                        }}
                                                                                                    >
                                                                                                        <b style={{ textTransform: "uppercase" }}>
                                                                                                            {
                                                                                                                item.companyName ? item.companyName
                                                                                                                    .split(/\s/)
                                                                                                                    .reduce(
                                                                                                                        (response, word) =>
                                                                                                                            (response += word.slice(0, 1)),
                                                                                                                        ""
                                                                                                                    )
                                                                                                                    .slice(0, 2)
                                                                                                                    : ''
                                                                                                            }
                                                                                                        </b>
                                                                                                    </span>
                                                                                                </>
                                                                                            ) : (
                                                                                                <>
                                                                                                    {item.profile_image ?
                                                                                                        <img
                                                                                                            className="mr-2"
                                                                                                            style={{
                                                                                                                width: 40,
                                                                                                                height: 40,
                                                                                                                borderRadius: 20,
                                                                                                                display: "block",
                                                                                                            }}
                                                                                                            //src={Ava}
                                                                                                            //src={(auth0.user && auth0.user.picture) || user.avatar || Ava}
                                                                                                            // src={`${client.url(`images/company-profile/${item.profile_image}?${Date.now()}`)}`}
                                                                                                            src={item.profile_image}
                                                                                                            alt="avatar"
                                                                                                        />
                                                                                                        : <span
                                                                                                            className="border p-2 mr-2"
                                                                                                            style={{
                                                                                                                fontSize: "13px",
                                                                                                                width: 40,
                                                                                                                height: 40,
                                                                                                                borderRadius: 20,
                                                                                                                display: "block",
                                                                                                                textAlign: "center",
                                                                                                            }}
                                                                                                        >
                                                                                                            <b style={{ textTransform: "uppercase" }}>
                                                                                                                {
                                                                                                                    item.companyName ? item.companyName
                                                                                                                        .split(/\s/)
                                                                                                                        .reduce(
                                                                                                                            (response, word) =>
                                                                                                                                (response += word.slice(0, 1)),
                                                                                                                            ""
                                                                                                                        )
                                                                                                                        .slice(0, 2)
                                                                                                                        : ''
                                                                                                                }
                                                                                                            </b>
                                                                                                        </span>}
                                                                                                </>
                                                                                            )}
                                                                                            &nbsp;
                                                                                            <p className="d-inline">
                                                                                                {item.companyName ? item.companyName : ''}
                                                                                            </p>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            );
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPane>
                                        </TabContent> */}

                                        {!loading ? showDetails? <Details companyDetails={companyDetails} merchantId={merchant_id}/>: <Row className='m-0'>
                                            <Col xs='12' className='px-3'>
                                                <div className=" py-2">
                                                    {company && company.length > 0 ? (
                                                        <>
                                                            {company.filter((comp) => comp.adminId == merchant_info.adminId).map((val, ind) => {
                                                                return (
                                                                    <>
                                                                        <Row key={ind}>
                                                                            <Col xs="12">
                                                                                <div className="d-flex align-items-center">
                                                                                    <h5
                                                                                        className="text-uppercase my-2 font-weight-bold"
                                                                                        style={{ fontSize: "17px" }}>
                                                                                        {" "}
                                                                                        {val.companyName}{" "}
                                                                                    </h5>
                                                                                    <Icon path={mdiPencil} size={0.8} color={'#327ebc'} onClick={()=>{setCompanyDetails(val);setShowDetails(!showDetails)}}/>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                        {
                                                                            merchants.filter((merch) => merch.adminId == merchant_info.adminId).filter((mrctVal) => val._id.trim() == mrctVal.company_id.trim()).map((item) => {
                                                                                let completedStoreSetupStatus = completedStoreSetup(item);
                                                                                // console.log(item, "stores")
                                                                                return (
                                                                                    <Row
                                                                                        key={item._id}
                                                                                        className="align-items-center mx-1 my-4">
                                                                                        <Col
                                                                                            xs="1"
                                                                                            className="p-0 align-middle"
                                                                                            id={`show_primary_tip_${item._id}`}>
                                                                                            <Icon
                                                                                                path={
                                                                                                    item.isPrimary
                                                                                                        ? mdiStar
                                                                                                        : mdiStarOutline
                                                                                                }
                                                                                                className='starIcon'
                                                                                                size={1}
                                                                                                color={
                                                                                                    item.isPrimary
                                                                                                        ? "#FFD700"
                                                                                                        : "gray"
                                                                                                }
                                                                                                style={{
                                                                                                    cursor: !item.isPrimary
                                                                                                        ? "pointer"
                                                                                                        : "",
                                                                                                }}
                                                                                            />
                                                                                        </Col>
                                                                                        <Col
                                                                                            xs="9"
                                                                                            sm="8"
                                                                                            xl="6"
                                                                                            style={{
                                                                                                display: "table-cell",
                                                                                                verticalAlign: "middle",
                                                                                                paddingInline: "5px"
                                                                                            }}>
                                                                                            <div className=" desk_v align-items-center pr-md-2 d-flex">
                                                                                                {item.profile_image == "" ? (
                                                                                                    <>
                                                                                                        <div
                                                                                                            style={{
                                                                                                                width: 40,
                                                                                                                height: 40,
                                                                                                            }}
                                                                                                            className="mr-2">
                                                                                                            <span
                                                                                                                className="border p-2 d-flex align-items-center justify-content-center"
                                                                                                                style={{
                                                                                                                    fontSize: "13px",
                                                                                                                    width: 40,
                                                                                                                    height: 40,
                                                                                                                    borderRadius: 20,
                                                                                                                    // display: "block",
                                                                                                                    // textAlign: "center",
                                                                                                                    fontWeight: '600',
                                                                                                                    textTransform: "uppercase",
                                                                                                                }}>
                                                                                                                {/* <b
                                                style={{
                                                    textTransform:
                                                        "uppercase",
                                                }}> */}
                                                                                                                {item.business_name.slice(
                                                                                                                    0,
                                                                                                                    2
                                                                                                                )}
                                                                                                                {/* </b> */}
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <div className="mr-2">
                                                                                                        <img

                                                                                                            style={{
                                                                                                                width: 40,
                                                                                                                height: 40,
                                                                                                                borderRadius: 20,
                                                                                                                display: "block",
                                                                                                            }}
                                                                                                            src={`${item.profile_image?.includes('dropbox') ? item.profile_image.replace('?', `?${timeStamp}&`) : client.url(`images/store-logo/${item.profile_image}`)}`}
                                                                                                            alt="avatar"
                                                                                                        />
                                                                                                    </div>
                                                                                                )}
                                                                                                &nbsp;
                                                                                                <div
                                                                                                    // className="d-flex flex-md-row flex-column"
                                                                                                    className="d-flex flex-wrap justify-content-between w-100"
                                                                                                >
                                                                                                    <p
                                                                                                        className="d-inline m-0"
                                                                                                        style={{
                                                                                                            fontSize: "15px",
                                                                                                        }}>
                                                                                                        {item.business_name[0].toUpperCase() +
                                                                                                            item.business_name.slice(
                                                                                                                1
                                                                                                            )}
                                                                                                    </p>
                                                                                                    <div
                                                                                                        style={{
                                                                                                            display: "table-cell",
                                                                                                            verticalAlign: "middle",
                                                                                                            paddingInline: "5px"
                                                                                                            // widths <= 576
                                                                                                            //     ? "5px"
                                                                                                            //     : "10px",
                                                                                                        }}>
                                                                                                        <div
                                                                                                            className={`d-flex w-100 align-items-center flex-grow-1 pr-0 px-md-2 justify-content-end`}
                                                                                                            style={{
                                                                                                                width:
                                                                                                                    completedStoreSetupStatus
                                                                                                                        ? "100%"
                                                                                                                        : "",
                                                                                                            }}>
                                                                                                            {!completedStoreSetupStatus &&
                                                                                                                item &&
                                                                                                                ShowStoreStatus(item)}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </Col>
                                                                                        <Col
                                                                                            xs="2"
                                                                                            className="p-0 align-middle ">
                                                                                            <Icon
                                                                                                path={mdiCogOutline}
                                                                                                size={1.2}
                                                                                                color="gray"
                                                                                                className="mx-2 my-0 settings_Icon"
                                                                                                style={{
                                                                                                    cursor: "pointer",
                                                                                                }}
                                                                                                onClick={() => {
                                                                                                    setTimeout(() => {
                                                                                                        setDefaultIndex(1)

                                                                                                    }, 1000)
                                                                                                    history.push({
                                                                                                        pathname: `/merchants/merchantDetails/${item._id}`,
                                                                                                        state: { index: 1 }
                                                                                                    })
                                                                                                }}
                                                                                            />
                                                                                        </Col>
                                                                                    </Row>
                                                                                );
                                                                            })
                                                                        }

                                                                        {/* <tr style={{ paddingBlock: "10px" }}>
            <td
                colspan="100%"
                style={{
                    display: "table-cell",
                    verticalAlign: "middle",
                }}>
                <hr
                style={{
                    borderTop:
                        company.filter(
                            (a) =>
                                a.adminId == userData._id
                        ).length -
                            1 !=
                            ind
                            ? "1px solid #dee2e6"
                            : "none",
                }}
                />
            </td>
        </tr> */}
                                                                    </>
                                                                )
                                                            })}
                                                        </>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row> :
                                            <div className="d-flex justify-content-center mt-4" style={{ display: "flex", alignItems: "center", height: window.innerHeight / 2 }}>
                                                <HashLoader color="#36D7B7" loading={true} />
                                            </div>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
