import React, { Fragment, useState, useEffect } from "react";
import { } from "reactstrap";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import { toast } from "react-toastify"
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import FeaturePage from "./features/Features";
import HideAndUnhideFeature from "./features/HideAndUnhideFeature";
import { DisappearedLoading } from "react-loadingg";

const EnableFeatures = (props) => {

    const [defaultVaue, setDefaultValue] = useState(null);
    const [loading, setLoading] = useState(true);
    const [preperence, setpreperence] = useState([]);
    const [loadingItem, setloadingItem] = useState(null);
    // console.log(merchantFeature, "merchantFeature");

    const fetchfeatureData = () => {

        const postData = { merchant_id: props.merchant_info };

        client.post(api.app_preference_fetch, postData, (error, response) => {

            if (!error) {
                if (!response.error) {
                    setpreperence(response.result)

                    // setFeatureEnableData({
                    //     loyalty_cards: getFeatureData.loyalty_cards,
                    //     multi_user: getFeatureData.multi_user,
                    //     offers_vouchers: getFeatureData.offers_vouchers,
                    //     online_orders: getFeatureData.online_orders,
                    //     payment_links: getFeatureData.payment_links,
                    //     table_reservation: getFeatureData.table_reservation,
                    // })

                } else {
                    toast.error("Internal error occured. Please contact support");
                }
            } else {
                toast.error("Internal error occured. Please contact support");
            }
            setloadingItem(null)
            setLoading(false)

        });

    }

    useEffect(() => {
        fetchfeatureData();
    }, [])


    const updateFeatureData = (data) => {

        
        // let postData = { updateData: data, update_ID: merchantFeature._id }
        client.post(api.app_preference_update, data, (error, response) => {

            if (!error) {
                if (!response.error) {
                    fetchfeatureData()
                    // setMerchantFeature(response.result);
                    toast.success("Updated Successfully");
                    // setLoading(false);
                } else {
                    toast.error("Internal error occured. Please contact support");
                }
            } else {
                toast.error("Internal error occured. Please contact support");
            }

        });

    }


    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 col-xl-12 xl-100">
                        {!loading ?
                            <div className="tab-content-cls">
                                <div className="d-flex align-items-center" style={{
                                    fontSize: '1rem',
                                    fontWeight: '600',
                                    marginLeft: "20px",
                                    marginTop: "19px"
                                }}>
                                    App Preferences
                                </div>
                                {preperence && preperence.length > 0 &&
                                    <HideAndUnhideFeature
                                        merchant_info={props.merchant_info}
                                        preperence={preperence}
                                        // catsubcat={catsubcat}
                                        // iPayFeatures={iPayFeatures}
                                        // defaultVaue={defaultVaue}/
                                        // merchFeature={merchantFeature?.feature_data}
                                        loading={loading}
                                        loadingItem={loadingItem}
                                        setloadingItem={setloadingItem}
                                        updateFeatureData={updateFeatureData}
                                    />
                                }
                            </div>
                            :
                            <div className="loader-box" style={{ height: "100vh", width: "100wh" }}>
                                <div className="loader" style={{ display: "flex", alignItems: "center" }}>
                                    <DisappearedLoading size="medium" color="#514F4E" />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default EnableFeatures;